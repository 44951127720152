import { MenuItemProps } from '@chakra-ui/react';
import {
  HumioLoaderOverlay,
  HumioUnavailable,
} from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { LogScaleContext } from '@gamma/investigator/context';
import { useReloadHumioUI } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useGetHumioDashboards } from '@gamma/investigator/queries';
import { Board, Column, Panel } from '@gamma/layout';
import { PopUpMenuLinksProps } from '@gamma/navigation';
import { ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

const { humioLabel, dashboardsMenu } = i18n.navigation;
const { dashboards: dashboardsRoute } = ROUTES;
const { dashboards } = i18n.pages.humio;

declare module '@chakra-ui/react' {
  interface MenuItemProps {
    'data-dashboard-name'?: string;
    'data-dashboard-id'?: string;
  }
}

export const HumioDashboardOverview = () => {
  const { pathname } = useLocation();

  const {
    iframeKey,
    sessionIdTokenCorelight,
    setLogScaleDashboardsLoading,
    setLogScaleDashboardsError,
    setLogScaleDashboardsData,
    setLogScaleDashboardParams,
    logScaleDashboardsLoading,
    logScaleDashboardsError,
    logScaleDashboardsData,
    logScaleDashboardParams,
  } = useContext(LogScaleContext);

  const [dashboardMenuLinks, setDashboardMenuLinks] = useState<
    PopUpMenuLinksProps[] | undefined
  >(undefined);
  const [searchText, setSearchText] = useState<string>('');

  const { loading, error, data } = useGetHumioDashboards({
    skip: !sessionIdTokenCorelight,
    variables: {
      humioSessionToken: sessionIdTokenCorelight || '',
    },
  });

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setDashboardMenuLinks([
        { to: dashboardsRoute(), label: dashboardsMenu, isHidden: true },
        ...[...data?.getHumioDashboards?.searchDomain?.dashboards]
          ?.sort((a, b) => (a.name < b.name ? -1 : 1))
          ?.map((link) => {
            return {
              to: dashboardsRoute(),
              label: link.name,
              menuItemProps: {
                'data-dashboard-name': link.name,
                'data-dashboard-id': link.id,
              } as MenuItemProps,
            };
          }),
      ]);
    }
  }, [data]);

  useEffect(() => {
    setLogScaleDashboardsLoading?.(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLogScaleDashboardsError?.(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.getHumioDashboards) {
      setLogScaleDashboardsData?.(data);
    }
  }, [data]);

  useEffect(() => {
    if (pathname === ROUTES.networkOverview) {
      setSearchText(humioLabel.networkOverview);
    } else if (pathname === ROUTES.securityPosture) {
      setSearchText(humioLabel.securityPosture);
    } else if (pathname === ROUTES.threatHunting) {
      setSearchText(humioLabel.threatHunting);
    }
  }, [pathname]);

  useEffect(() => {
    const selectedDashboard = dashboardMenuLinks?.find((dashboardLink) =>
      dashboardLink.label.toLowerCase().includes(searchText.toLowerCase()),
    );
    if (selectedDashboard) {
      const dashboardName =
        selectedDashboard.menuItemProps?.['data-dashboard-name'] ?? '';
      const dashboardId =
        selectedDashboard.menuItemProps?.['data-dashboard-id'] ?? '';

      setLogScaleDashboardParams?.({
        name: dashboardName,
        dashboardId: dashboardId,
      });
    } else {
      setLogScaleDashboardParams?.({ name: searchText });
    }
  }, [searchText, dashboardMenuLinks]);

  const { name, dashboardId, investigatorApp, corelightDashboard } =
    logScaleDashboardParams || {};

  const dashboardDetails = {
    name,
    dashboardId,
    investigatorApp,
    corelightDashboard,
  };

  const { iframeRef, iframeSrc } = useReloadHumioUI({
    iframeType: 'dashboards',
    dashboardDetails,
  });

  localStorage.removeItem('dashboard_link');

  if (logScaleDashboardsLoading) {
    return (
      <ErrorBoundary page="Dashboards" styleClass="main">
        <Panel>
          <GraphQLReqStatus
            isBackground={true}
            loading={logScaleDashboardsLoading}
          />
        </Panel>
      </ErrorBoundary>
    );
  } else if (
    logScaleDashboardsError ||
    !logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards ||
    logScaleDashboardsData?.getHumioDashboards?.searchDomain?.dashboards
      ?.length === 0
  ) {
    return (
      <HumioUnavailable
        unavailable={dashboards.unavailable}
        techSupport={dashboards.techSupport}
      />
    );
  }

  return (
    <ErrorBoundary page="Humio Dashboards Overview" styleClass="main">
      <Board
        h="100%"
        flex="1 1 auto"
        flexDirection="column"
        data-testid="humio-dashboards-overview"
        margin={0}
        width="auto"
      >
        <Column
          mt={1}
          pb={0}
          flexGrow={1}
          display="flex"
          flexBasis="auto"
          overflow="hidden"
          flexDirection="column"
        >
          {isSafari && <HumioLoaderOverlay reloadKey={iframeKey} />}
          {iframeSrc && (
            <iframe
              title="ui"
              width="100%"
              id="humio-ui"
              data-testid="humio-ui"
              height="calc(100% + 64px)"
              style={{
                flexGrow: 1,
                display: 'flex',
                paddingBottom: 0,
                marginTop: '-52px',
              }}
              key={iframeKey}
              ref={iframeRef}
              src={iframeSrc}
            />
          )}
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
