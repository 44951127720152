import { HStack, Text } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { useDateRangeContext } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  PageHeader as GammaPageHeader,
  PageHeaderProps as GammaPageHeaderProps,
} from '@gamma/layout';
import moment from 'moment';
import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FederatedTenantSelect } from '../FederatedTenantSelect';
import { DateSelectSync } from './Components';

const { refresh, clickHereToRefresh, refreshTriagePageToolTip } =
  i18n.dateSelectSync;

const { newDetections } = i18n.pages.detections;

export interface PageHeaderProps extends GammaPageHeaderProps {
  children?: ReactNode;
  showDateSelection?: boolean;
  isLoading?: boolean;
  totalItems?: number;
}

export const PageHeader = ({
  children,
  totalItems = 0,
  isLoading,
  showDateSelection = true,
  ...rest
}: PageHeaderProps) => {
  const navigate = useNavigate();

  const { search, pathname } = useLocation();
  const { dateRange } = useDateRangeContext();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const refreshButtonOnClick = () => {
    const newEnd = String(moment().unix());
    const newStart = moment().subtract(dateRange?.[0], dateRange?.[1]).unix();
    params.set('end', newEnd);
    params.set('start', String(newStart));
    navigate({
      pathname,
      search: params.toString(),
    });
  };

  return (
    <GammaPageHeader
      {...rest}
      actions={
        <HStack>
          <FederatedTenantSelect
            params={params}
            navigate={navigate}
            pathname={pathname}
          />
          {showDateSelection && (
            <DateSelectSync
              params={params}
              navigate={navigate}
              dateSelectButtonConfig={{
                iconButtonProps: {
                  isLoading: isLoading,
                  'aria-label': refreshTriagePageToolTip,
                  colorScheme: !isLoading && totalItems > 0 ? 'blue' : 'gray',
                  borderRadius: '4px 0px 0px 4px',
                  variant: 'solid',
                  icon: <MuiIcon>refresh</MuiIcon>,
                  onClick: refreshButtonOnClick,
                },
                toolTipProps: {
                  label:
                    totalItems > 0 ? (
                      <>
                        <Text data-testid="refresh-button-total-items-text">
                          {newDetections}
                        </Text>
                        <Text data-testid="refresh-button-total-click-to-refresh-text">
                          {clickHereToRefresh}
                        </Text>
                      </>
                    ) : (
                      <Text data-testid="refresh-button-refresh-text">
                        {refresh}
                      </Text>
                    ),
                  'aria-label': refreshTriagePageToolTip,
                  placement: totalItems > 0 ? 'left' : 'top',
                },
              }}
            />
          )}
        </HStack>
      }
      variant="flushed"
    >
      {children}
    </GammaPageHeader>
  );
};
