import { Grid, Progress, Text, VStack } from '@chakra-ui/react';
import { Panel } from '@gamma/layout';
import { DetectionMethodSummaryProps } from './types';

interface DetectionMethodItemProps {
  label: string;
  enabled: number;
  total: number;
}

const DetectionMethodItem = ({
  label,
  enabled,
  total,
}: DetectionMethodItemProps) => {
  const percentage = (enabled / total) * 100;

  return (
    <VStack align="flex-start" spacing={2}>
      <Text textStyle="body-sm" fontWeight="medium">
        {label}
      </Text>
      <VStack align="stretch" spacing={1} width="100%">
        <Progress
          value={percentage}
          size="sm"
          width="100%"
          colorScheme="purple"
          borderRadius="full"
          bg="whiteAlpha.200"
        />
        <Text textStyle="body-sm" color="text.secondary">
          {enabled} of {total} enabled
        </Text>
      </VStack>
    </VStack>
  );
};

const methodLabels: Record<string, string> = {
  ml_results: 'Machine Learning',
  behavioral: 'Cloud Behavioral',
  ids: 'IDS',
  anomaly: 'Anomaly',
  yara: 'Yara',
  notice: 'Notice',
  signature: 'Signature',
  intel: 'Intel',
};

export const DetectionMethodSummary = ({
  data,
  loading,
}: DetectionMethodSummaryProps) => {
  if (loading || !data) {
    return null;
  }

  return (
    <Panel p={3}>
      <VStack align="stretch" spacing={4}>
        <Text textStyle="heading-sm">Detection Methods</Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {data.map((method) => (
            <DetectionMethodItem
              key={method.detection_method}
              label={
                methodLabels[method.detection_method] || method.detection_method
              }
              enabled={method.enabled}
              total={method.total}
            />
          ))}
        </Grid>
      </VStack>
    </Panel>
  );
};
