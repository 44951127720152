import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { IGetDetectionTimelineSummary } from './types';

export const GET_DETECTION_TIMELINE_SUMMARY = gql`
  query getDetectionTimelineSummary(
    $detection_id: String
    $detection_tenant: String
    $start: Float
    $end: Float
    $interval: Float
    $top_n: Int
    $alert_name_filter: [AlertNameFilterInput]
    $severity_filter: [DetectionAlertSummarySeverities]
  ) {
    getCurrentDetectionTimelineSummary(
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      end: $end
      interval: $interval
      start: $start
      top_n: $top_n
    ) {
      row_count
      summary {
        alert_name
        detections {
          severity
          alert_name
          entity_name
          buckets {
            alert_count
            bucket_end
            bucket_start
            score
          }
          detection_id
          latest_start_timestamp
          earliest_start_timestamp
        }
        entity_name
        severity
        tenant
      }
    }
    getDetectionDestinationTimelineSummary(
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      end: $end
      interval: $interval
      start: $start
      top_n: $top_n
      severity_filter: $severity_filter
      alert_name_filter: $alert_name_filter
    ) {
      row_count
      summary {
        alert_name
        detections {
          severity
          alert_name
          entity_name
          buckets {
            alert_count
            bucket_end
            bucket_start
            score
          }
          detection_id
          latest_start_timestamp
          earliest_start_timestamp
        }
        entity_name
        severity
        tenant
      }
    }
    getDetectionSourceTimelineSummary(
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      end: $end
      interval: $interval
      start: $start
      top_n: $top_n
      severity_filter: $severity_filter
      alert_name_filter: $alert_name_filter
    ) {
      row_count
      summary {
        alert_name
        detections {
          severity
          alert_name
          entity_name
          buckets {
            alert_count
            bucket_end
            bucket_start
            score
          }
          detection_id
          latest_start_timestamp
          earliest_start_timestamp
        }
        entity_name
        severity
        tenant
      }
    }
    getDetectionSourceDestinationTimelineSummary(
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      end: $end
      interval: $interval
      start: $start
      top_n: $top_n
      severity_filter: $severity_filter
      alert_name_filter: $alert_name_filter
    ) {
      row_count
      summary {
        alert_name
        detections {
          severity
          alert_name
          entity_name
          buckets {
            alert_count
            bucket_end
            bucket_start
            score
          }
          detection_id
          latest_start_timestamp
          earliest_start_timestamp
        }
        entity_name
        severity
        tenant
      }
    }
  }
`;

export const useGetDetectionTimelineSummary = (
  options: QueryHookOptions<IGetDetectionTimelineSummary, OperationVariables>,
) => {
  return useQuery<IGetDetectionTimelineSummary>(
    GET_DETECTION_TIMELINE_SUMMARY,
    options,
  );
};
