import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AuthContext, UserPrivileges } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Column } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { useContext, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
  AvailabilityTag,
  IntegrationsConfigInfo,
} from './IntegrationsConfigCard';
import {
  emptyIntegrationsData,
  IntegrationsConstants,
  IntegrationsData,
} from './IntegrationsConstants';

const { integrations } = i18n.pages;
const { learnMore, configure, overview } = integrations;
export interface OutletContextType {
  userPrivileges: Record<string, UserPrivileges>;
  configsLoading: boolean;
}
export const IntegrationsDetailPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { integration } = useParams();
  const { userPrivileges, configsLoading } =
    useOutletContext<OutletContextType>();
  const { userLicense, userRole } = useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};

  const integrationData: IntegrationsData = integration
    ? IntegrationsConstants[integration]
    : emptyIntegrationsData;

  const {
    title,
    description,
    pathName,
    privilegeName,
    settingsModal,
    learnMoreLink,
    overviewContent,
    tags,
    fallbackText,
  } = integrationData;

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (userPrivileges?.[tenant]) {
      setIsEnabled(userPrivileges[tenant]?.[privilegeName]);
    }
  }, [userPrivileges, privilegeName, tenant]);
  const bgColor = useColorModeValue('gray.100', 'gray.800');
  return (
    <>
      <Column w="1200px" margin="auto">
        <VStack spacing={4} alignItems="start">
          {settingsModal &&
            settingsModal({
              title,
              onOpen,
              isOpen,
              onClose,
              isEnableChanged: isEnabled,
              setIsEnableChanged: setIsEnabled,
            })}
          <HStack>
            <Box width="400px" alignItems="flex-start">
              <IntegrationsConfigInfo
                availabilityTag={
                  <AvailabilityTag
                    isEnabled={isEnabled}
                    fallbackText={fallbackText}
                  />
                }
                description={description}
                title={title}
                pathName={pathName}
                tags={tags}
                isLoading={configsLoading}
                footer={
                  <ButtonGroup width="100%">
                    {settingsModal && (
                      <Button
                        variant="solid"
                        colorScheme="blue"
                        height="48px"
                        width="120px"
                        onClick={onOpen}
                        isDisabled={
                          userRole === 'viewer' || userRole === 'analyst'
                        }
                      >
                        {configure}
                      </Button>
                    )}
                    {learnMoreLink && (
                      <Button
                        as={RouterLink}
                        target="_blank"
                        variant="solid"
                        colorScheme="gray"
                        height="48px"
                        width="120px"
                        to={`${learnMoreLink}`}
                      >
                        {learnMore}
                      </Button>
                    )}
                  </ButtonGroup>
                }
              />
            </Box>
            <Box
              bgImage={`/assets/images/integrations/${pathName}-image.jpg`}
              bgSize="cover"
              h="394px"
              w="700px"
            ></Box>
          </HStack>
        </VStack>
      </Column>
      <Column
        w="100vw"
        bg={bgColor}
        marginLeft="-3"
        maxW="100vw"
        justifyItems="center"
      >
        <VStack w="1248px" spacing={2} alignItems="start" margin="auto">
          {overviewContent && (
            <Box maxW="1100px">
              <Text textStyle="h4">{overview}</Text>
              <Text>{overviewContent}</Text>
            </Box>
          )}
        </VStack>
      </Column>
    </>
  );
};
