import { Context, useContext } from 'react';
import { DataTableContext, DataTableContextValue } from '../../context';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const useDataTableContext = <DataType extends {}>() => {
  const context = useContext<DataTableContextValue<DataType>>(
    DataTableContext as unknown as Context<DataTableContextValue<DataType>>,
  );

  return context;
};
