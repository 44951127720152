import { SpinnerProps, Text } from '@chakra-ui/react';
import { ErrorCode, gammaContext } from '@gamma/theme';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinner } from '../LoadingSpinner';

export type ErrorMessage = {
  message: string;
};

export type ExtendedType = {
  loginRoute: string;
  notFoundRoute: string;
  serverErrorRoute: string;
};

export interface GraphQLReqStatusProps {
  loading: boolean;
  spinnerSize?: SpinnerProps['size'];
  error?: ErrorMessage;
  isBackground?: boolean;
  extended?: ExtendedType;
  signOut?: () => Promise<void>;
}

export const GraphQLReqStatus = ({
  loading,
  spinnerSize,
  error,
  isBackground = false,
  extended,
  signOut,
}: GraphQLReqStatusProps) => {
  const { i18n } = useContext(gammaContext);
  const navigate = useNavigate();

  const authError =
    error?.message?.includes(`${ErrorCode.UNAUTHORIZED}`) ||
    error?.message?.includes(`${ErrorCode.ACCESS_DENIED}`);

  const notFoundError = error?.message?.includes(`${ErrorCode.NOT_FOUND}`);
  const serverSideError = error?.message?.includes(`${ErrorCode.SERVER_ERROR}`);

  useEffect(() => {
    if (extended) {
      if (authError && signOut) {
        signOut().then(() => {
          window.location.assign(extended.loginRoute);
        });
      }
      if (notFoundError) {
        navigate(extended.notFoundRoute, { replace: true });
      }
      if (serverSideError) {
        navigate(extended.serverErrorRoute, { replace: true });
      }
    }
  }, [error]);

  if (loading && !error && !isBackground) {
    return (
      <div data-testid="graphql-req-loading">
        <LoadingSpinner size={spinnerSize} />
      </div>
    );
  }

  if (error) {
    return (
      <Text data-testid="graphql-req-error">{`${i18n.errors.errorLocated}! ${error.message}`}</Text>
    );
  }

  return null;
};
