import { createIcon } from '@chakra-ui/react';

export const PaloAltoLogoSmall = createIcon({
  displayName: 'PaloAltoLogo',
  viewBox: '0 0 24 24',
  path: [
    <path
      d="M10.6211 14.4543L17.4956 7.55479L14.7531 4.80273L12.0107 7.55479L13.3758 8.92436L6.50439 15.8251L9.24377 18.579L11.9862 15.8251L10.6211 14.4543Z"
      fill="#F4F4F4"
    />,
    <path
      d="M9.27358 4.80071L2.3999 11.7031L5.14072 14.4554L12.0144 7.55298L9.27358 4.80071Z"
      fill="#F4F4F4"
    />,
    <path
      d="M18.8585 8.9257L11.9849 15.8281L14.7257 18.5804L21.5994 11.678L18.8585 8.9257Z"
      fill="#F4F4F4"
    />,
  ],
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
