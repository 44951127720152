import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Spinner,
  Tag,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { MsDefenderLogoSmall, MuiIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import {
  AuthContext,
  EntityInformationContext,
  EntityInstance,
} from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { EntityPanelRow } from '@gamma/investigator/pages/detections';
import {
  MicrosoftDefenderEndpointDevice,
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
  useGetEDREndpointDevices,
  useGetEntityEdrStatus,
  usePullEntityEdrStatus,
} from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import { Alert } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useContext, useMemo } from 'react';

const { integrations } = i18n.pages;
const { learnMore, additionalEdrIntegration } = integrations;

const { entityEDR } = i18n.pages.detections;
const { edrAction } = entityEDR;

const { DEFENDER_DOCS } = URLS;

interface MSDefenderModalSectionProps {
  activeEntity?: EntityInstance;
}

export const MSDefenderModalSection = ({
  activeEntity,
}: MSDefenderModalSectionProps) => {
  const { userRole, userLicense } = useContext(AuthContext);

  const { setActiveEntity, handleOpenIsolation } = useContext(
    EntityInformationContext,
  );

  const {
    isLoading,
    entityName,
    entityType,
    tenantEntity,
    tenantInfo,
    lastSeen,
    defenderEdrPayload,
    defenderEdrConfigData,
    entityDefenderEdrStatus,
    entityDefenderIsIsolated,
  } = activeEntity || {};

  const { tenant_id } = tenantInfo || {};

  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const { loading: edrConfigLoading, error: edrConfigError } =
    getEDRConfigQuery({
      skip: !tenant_id && !userLicense?.tenant,
      variables: {
        tenant: tenant_id || userLicense?.tenant,
        provider_name: 'microsoft_defender',
      },
      onCompleted: ({ getEDRConfiguration }) => {
        setActiveEntity?.((prevState) => ({
          ...prevState,
          defenderEdrConfigData: getEDRConfiguration,
        }));
      },
    });

  const { loading, error } = useGetEDREndpointDevices({
    skip:
      !lastSeen ||
      !tenant_id ||
      !entityName ||
      !entityType ||
      !defenderEdrConfigData?.enabled,
    variables: {
      edr_endpoint_device_inputs: [
        {
          as_of: lastSeen,
          tenant: tenant_id,
          alert_entity_info: {
            entity_name: entityName,
            entity_type: entityType,
          },
        },
      ],
    },
    onCompleted: ({ getEdrEndpointDevices }) => {
      const defenderEdrPayload =
        getEdrEndpointDevices?.edr_endpoint_devices?.filter(
          (device) => device.data_source === 'microsoft_defender',
        )?.[0];
      setActiveEntity?.((prevState) => ({
        ...prevState,
        defenderEdrPayload,
      }));
    },
  });

  const { id, machine, entity_id, last_updated_timestamp } =
    (defenderEdrPayload as MicrosoftDefenderEndpointDevice) || {};

  const {
    riskScore,
    osPlatform,
    version,
    osProcessor,
    ipAddresses,
    healthStatus,
    managedBy,
    managedByStatus,
    computerDnsName,
    firstSeen: edrFirstSeen,
    lastSeen: edrLastSeen,
    lastExternalIpAddress,
  } = machine || {};

  const mac_address = ipAddresses?.find(
    (ip) => ip.ipAddress === entityName,
  )?.macAddress;

  const { entity_edr_status } = entityDefenderEdrStatus || {};

  const edrStatusAlert = useMemo(() => {
    if (entity_edr_status) {
      switch (entity_edr_status) {
        case 'normal':
          return {
            colorScheme: 'green',
            statusText: edrAction.normal,
          };
        case 'isolated':
          return {
            colorScheme: 'red',
            statusText: edrAction.isolated,
          };
        case 'isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingIsolation,
          };
        case 'lift_isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingLift,
          };
      }
    }
    return undefined;
  }, [entity_edr_status]);

  const { loading: getEntityEdrStatusLoading } = useGetEntityEdrStatus({
    fetchPolicy: 'network-only',
    skip: (!tenant_id && !userLicense?.tenant) || !entity_id,
    variables: {
      tenant: tenant_id || userLicense?.tenant,
      entity_id,
    },
    onCompleted: ({ getEntityEdrStatus }) => {
      const { entity_edr_status, tenant, updated_timestamp } =
        getEntityEdrStatus;
      setActiveEntity?.((prevState) => ({
        ...prevState,
        entityDefenderEdrStatus: {
          tenant,
          entity_edr_status,
          updated_timestamp,
        },
        entityDefenderIsIsolated:
          entity_edr_status === 'isolated' ||
          entity_edr_status === 'isolation_pending',
      }));
    },
  });

  const [pullEntityEdrStatus, { loading: pullEntityEdrStatusLoading }] =
    usePullEntityEdrStatus({
      fetchPolicy: 'network-only',
      variables: {
        entity_id,
        tenant: tenant_id || userLicense?.tenant,
      },
      onCompleted: ({ pullEntityEdrStatus }) => {
        const { entity_edr_status, tenant, updated_timestamp } =
          pullEntityEdrStatus || {};
        if (tenant && updated_timestamp && entity_edr_status) {
          setActiveEntity?.((prevState) => ({
            ...prevState,
            entityDefenderEdrStatus: {
              tenant,
              updated_timestamp,
              entity_edr_status,
            },
            entityDefenderIsIsolated:
              entity_edr_status === 'isolated' ||
              entity_edr_status === 'isolation_pending',
          }));
        }
      },
    });

  return (
    <VStack w="100%" alignItems="start">
      <HStack>
        <MsDefenderLogoSmall />
        <Text textStyle="h5">Microsoft Defender</Text>
      </HStack>
      {loading || isLoading || error || edrConfigLoading || edrConfigError ? (
        <Center w="100%">
          <GraphQLReqStatus
            error={error || edrConfigError}
            loading={loading || !!isLoading || edrConfigLoading}
          />
        </Center>
      ) : !defenderEdrConfigData?.enabled ? (
        <Alert status="info" variant="subtle" isClosable={false}>
          <VStack alignItems="start">
            <Text>{additionalEdrIntegration}</Text>
            <Button
              as={RouterLink}
              target="_blank"
              variant="solid"
              colorScheme="blue"
              to={DEFENDER_DOCS}
            >
              {learnMore}
            </Button>
          </VStack>
        </Alert>
      ) : (
        <HStack w="100%" spacing={8} alignItems="start">
          <VStack w="354px">
            <EntityPanelRow heading={edrAction.entityStatus}>
              <HStack>
                {edrStatusAlert ? (
                  <HStack spacing={0}>
                    <Tag
                      size="md"
                      variant="solid"
                      textAlign="center"
                      colorScheme={edrStatusAlert?.colorScheme}
                    >
                      {edrStatusAlert?.statusText}
                    </Tag>
                    <IconButton
                      size="sm"
                      aria-label={edrAction.refreshEntityStatus}
                      onClick={() => {
                        if (tenantInfo && tenantEntity) {
                          pullEntityEdrStatus();
                        }
                      }}
                      isDisabled={
                        getEntityEdrStatusLoading ||
                        pullEntityEdrStatusLoading ||
                        userRole === 'viewer'
                      }
                      icon={
                        getEntityEdrStatusLoading ||
                        pullEntityEdrStatusLoading ? (
                          <Box w="20px" h="20px">
                            <Spinner size="xs" color="white" />
                          </Box>
                        ) : (
                          <MuiIcon>refresh</MuiIcon>
                        )
                      }
                    />
                    <Tooltip
                      w="120px"
                      placement="top"
                      label={
                        userRole !== 'admin'
                          ? edrAction.requestPermissions
                          : undefined
                      }
                    >
                      <Button
                        size="xs"
                        variant="solid"
                        colorScheme="gray"
                        onClick={() =>
                          handleOpenIsolation?.('microsoft_defender')
                        }
                        isDisabled={
                          userRole !== 'admin' ||
                          getEntityEdrStatusLoading ||
                          pullEntityEdrStatusLoading ||
                          !defenderEdrConfigData?.edr_action_enabled ||
                          entity_edr_status === 'isolation_pending' ||
                          entity_edr_status === 'lift_isolation_pending'
                        }
                      >
                        {entityDefenderIsIsolated
                          ? edrAction.liftIsolation
                          : edrAction.isolateEntity}
                      </Button>
                    </Tooltip>
                  </HStack>
                ) : getEntityEdrStatusLoading ? (
                  <Box w="20px" h="20px">
                    <Spinner size="xs" color="white" />
                  </Box>
                ) : (
                  <Text>---</Text>
                )}
              </HStack>
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.timestamp}>
              {last_updated_timestamp
                ? moment
                    .unix(last_updated_timestamp)
                    .format('MMMM D, YYYY h:mma')
                : undefined}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.macAddress}>
              {mac_address}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.dnsName}>
              {computerDnsName}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.platform}>
              {osPlatform}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.osVersion}>
              {version}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.osProcessor}>
              {osProcessor}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.externalIp}>
              {lastExternalIpAddress}
            </EntityPanelRow>
            <EntityPanelRow disableBorders heading={entityEDR.deviceId}>
              {id}
            </EntityPanelRow>
          </VStack>
          <VStack>
            <EntityPanelRow heading={entityEDR.firstSeen}>
              {edrFirstSeen
                ? moment.unix(edrFirstSeen).format('MMMM D, YYYY h:mma')
                : undefined}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.lastSeen}>
              {edrLastSeen
                ? moment.unix(edrLastSeen).format('MMMM D, YYYY h:mma')
                : undefined}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.healthStatus}>
              {healthStatus}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.riskScore}>
              {riskScore}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.managedBy}>
              {managedBy}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.managedByStatus}>
              {managedByStatus}
            </EntityPanelRow>
          </VStack>
        </HStack>
      )}
    </VStack>
  );
};
