import {
  gql,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';

import { IPullEntityEdrStatus } from './types';

export const PULL_ENTITY_EDR_STATUS = gql`
  mutation pullEntityEdrStatus($entity_id: String!, $tenant: String) {
    pullEntityEdrStatus(entity_id: $entity_id, tenant: $tenant) {
      tenant
      entity_edr_status
      updated_timestamp
    }
  }
`;

export const usePullEntityEdrStatus = (
  options: MutationHookOptions<IPullEntityEdrStatus, OperationVariables>,
) => useMutation(PULL_ENTITY_EDR_STATUS, options);
