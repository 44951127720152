import { ApolloError } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  HStack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertAndDetectionTenantInfo,
  AlertEntity,
  DetectionSummary,
  EDRConfiguration,
  EdrEndpointDevice,
  EdrPayload,
  EntityEdrStatusWithTimestampExt,
  ExcludedEntity,
  QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
  useAddExcludedEntitiesToAlertMetadata,
  usePerformEdrAction,
  useRemoveExcludedEntitiesFromAlertMetadata,
} from '@gamma/investigator/queries';
import { Alert, Modal } from '@gamma/overlay';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { EntityInformationContext } from './EntityInformationContext';

import { EntityInformationModal } from '@gamma/investigator/components';
import moment from 'moment';

const { entityEDR } = i18n.pages.detections;
const { edrAction } = entityEDR;

const { excludeEntity } = i18n.pages.entityDetails;
const {
  cancel,
  suppressMsg,
  unSuppressMsg,
  suppress,
  unSuppress,
  willReceiveAlerts,
  willNotReceiveAlerts,
} = excludeEntity;

const {
  success: successToast,
  error: errorToast,
  addEntitySuccessBody,
  removeEntitySuccessBody,
  addEntityErrorBody,
  removeEntityErrorBody,
} = i18n.pages.detections.toasts;

interface EntityInformationProviderProps {
  children?: ReactNode;
}

export interface DetectionAlertItem {
  alert_name: string;
  severity: number;
  is_custom_severity: boolean;
}

export interface EntityLogscaleData {
  resp_cc?: string;
  'resp_geo.country'?: string;
  'resp_geo.city'?: string;
  service?: string;
  'id.resp_h.asn'?: string;
  'id.resp_h.org'?: string;
  'ioc[0].published_date'?: number;
  'ioc[0].malicious_confidence'?: 'high' | 'medium' | 'low';
  local_resp?: string;
}

export interface EntityInstance {
  end?: number;
  start?: number;
  lastSeen?: number;
  timeoutId?: number;
  entityEdrStatus?: {
    tenant: string;
    entity_edr_status: string;
    updated_timestamp: number;
  };
  entityDefenderEdrStatus?: {
    tenant: string;
    entity_edr_status: string;
    updated_timestamp: number;
  };
  entityId?: string;
  entityName?: string;
  tenantEntity?: string;
  entityType?: string;
  entityCategory?: string;
  showIsolateEntity?: boolean;
  entityIsIsolated?: boolean;
  entityDefenderIsIsolated?: boolean;
  showSuppressEntity?: boolean;
  entityIsSuppressed?: boolean;
  detectionData?: DetectionSummary;
  edrPayload?: EdrPayload;
  edrConfigData?: EDRConfiguration;
  defenderEdrPayload?: EdrEndpointDevice;
  defenderEdrConfigData?: EDRConfiguration;
  tenantInfo?: AlertAndDetectionTenantInfo;
  entityInfo?: AlertEntity & { entity_category: string };
  excludedEntitiesVariables?:
    | {
        query: string;
        offset: number;
        size: number;
        sort: { sort_by: string; sort_dir: string }[];
      }
    | undefined;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  detectionDataList?: DetectionAlertItem[];
  isLoading?: boolean;
  isSimpleSearchLoading?: boolean;
  logscaleOrigData?: EntityLogscaleData;
  logscaleRespData?: EntityLogscaleData;
  logscaleMetaData?: EntityLogscaleData;
}

export const EntityInformationProvider = ({
  children,
}: EntityInformationProviderProps) => {
  const {
    isOpen: isEntityModalOpen,
    onClose: onEntityModalClose,
    onOpen: onEntityModalOpen,
  } = useDisclosure();
  const {
    isOpen: isolateOpen,
    onClose: onIsolateClose,
    onOpen: onIsolateOpen,
  } = useDisclosure();
  const {
    isOpen: suppressOpen,
    onClose: onSuppressClose,
    onOpen: onSuppressOpen,
  } = useDisclosure();
  const [hoveredElement, setHoveredElement] = useState<
    HTMLDivElement | undefined
  >();
  const [activeEntity, setActiveEntity] = useState<EntityInstance>();
  const [suppressedMessage, setSuppressedMessage] = useState<string>('');

  const [isolationType, setIsolationType] = useState<
    'crowdstrike' | 'microsoft_defender'
  >();

  const handleOpenIsolation = useCallback(
    (isolationType: 'crowdstrike' | 'microsoft_defender') => {
      setIsolationType(isolationType);
      console.log('isolationType', isolationType);
      onIsolateOpen();
    },
    [onIsolateOpen],
  );

  const entityInformationContext = useMemo(() => {
    return {
      activeEntity,
      setActiveEntity,
      hoveredElement,
      setHoveredElement,
      onSuppressOpen,
      onEntityModalOpen,
      isEntityModalOpen,
      handleOpenIsolation,
    };
  }, [
    activeEntity,
    setActiveEntity,
    hoveredElement,
    setHoveredElement,
    onSuppressOpen,
    onEntityModalOpen,
    isEntityModalOpen,
    handleOpenIsolation,
  ]);

  const toast = useToast();

  const entityEdrIsolated = useMemo(() => {
    return (
      activeEntity?.entityEdrStatus?.entity_edr_status === 'isolated' ||
      activeEntity?.entityEdrStatus?.entity_edr_status === 'isolation_pending'
    );
  }, [activeEntity?.entityEdrStatus?.entity_edr_status]);

  const entityDefenderEdrIsolated = useMemo(() => {
    return (
      activeEntity?.entityDefenderEdrStatus?.entity_edr_status === 'isolated' ||
      activeEntity?.entityDefenderEdrStatus?.entity_edr_status ===
        'isolation_pending'
    );
  }, [activeEntity?.entityDefenderEdrStatus?.entity_edr_status]);

  const entityVariables = useMemo(() => {
    const { entityType, entityName, tenantInfo, detectionData } =
      activeEntity || {};
    const { tenant_id } = tenantInfo || {};
    const { content_id } = detectionData || {};
    return {
      query:
        entityType === 'DOMAIN'
          ? `{"query": {"bool": {"must": [{"match": {"content_id": "${content_id}"}}, {"term": {"entity_name.keyword": "${entityName}"}}, {"term": {"entity_type": "${entityType}"}}${
              tenant_id ? `,{"terms":{"tenant":["${tenant_id}"]}}` : ''
            }]}}}`
          : `{"query": {"bool": {"must": [{"match": {"content_id": "${content_id}"}}, {"term": {"entity_type": "${entityType}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entityName}"}}, {"range": {"entity_ip_range": {"from": "${entityName}", "to": "${entityName}"}}}]}}${
              tenant_id ? `,{"terms":{"tenant":["${tenant_id}"]}}` : ''
            }]}}}`,
      offset: 0,
      size: 10,
      sort: [{ sort_by: 'entity_name.keyword', sort_dir: 'desc' }],
    };
  }, [activeEntity]);

  const input = useMemo(() => {
    const { entityName, entityType, detectionData, tenantInfo } =
      activeEntity || {};
    const { content_id, alert_name, alert_type } = detectionData || {};
    return {
      entity_name: entityName,
      entity_type: entityType,
      tenant: tenantInfo?.tenant_id,
      content_id,
      alert_name,
      alert_type,
    };
  }, [activeEntity]);

  const [addEntity, { loading: addLoading }] =
    useAddExcludedEntitiesToAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables: entityVariables,
        },
      ],
      onCompleted: () => {
        toast({
          status: 'success',
          title: successToast,
          description: i18n.formatString(
            addEntitySuccessBody,
            input?.entity_name || '',
          ),
          isClosable: true,
          position: 'bottom-right',
        });
        updateEntityState();
      },
      onError: () => {
        toast({
          status: 'error',
          title: errorToast,
          description: i18n.formatString(
            addEntityErrorBody,
            input?.entity_name || '',
          ),
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [removeEntity, { loading: removeLoading }] =
    useRemoveExcludedEntitiesFromAlertMetadata({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_EXCLUDED_ENTITIES_PAGINATED,
          variables: entityVariables,
        },
      ],
      onCompleted: () => {
        toast({
          status: 'success',
          title: successToast,
          description: i18n.formatString(
            removeEntitySuccessBody,
            input?.entity_name || '',
          ),
          isClosable: true,
          position: 'bottom-right',
        });
        updateEntityState();
      },
      onError: (err: ApolloError) => {
        console.error('Error removing entity:', err);
        toast({
          status: 'error',
          title: errorToast,
          description: i18n.formatString(
            removeEntityErrorBody,
            input?.entity_name || '',
          ),
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const updateEntityState = () => {
    setActiveEntity?.((prevState) => ({
      ...prevState,
      entityIsSuppressed: !prevState?.entityIsSuppressed,
    }));

    onSuppressClose();
  };

  const handleAddSubmit = () => addEntity({ variables: { items: [input] } });

  const handleRemoveSubmit = () =>
    removeEntity({ variables: { items: [input] } });

  useEffect(() => {
    const entity = activeEntity || {};
    const { entityName, entityIsSuppressed, detectionData } = entity || {};
    const message = entityIsSuppressed
      ? i18n.formatString(
          unSuppressMsg,
          <Text as="strong">{entityName}</Text>,
          <Text as="strong">{detectionData?.alert_name}</Text>,
        )
      : i18n.formatString(
          suppressMsg,
          <Text as="strong">{entityName}</Text>,
          <Text as="strong">{detectionData?.alert_name}</Text>,
        );

    setSuppressedMessage(message as string);
  }, [activeEntity]);

  const entityEdrAction =
    isolationType === 'crowdstrike'
      ? !entityEdrIsolated
        ? 'isolate'
        : 'lift_isolation'
      : isolationType === 'microsoft_defender'
        ? !entityDefenderEdrIsolated
          ? 'isolate'
          : 'lift_isolation'
        : undefined;

  const entityEdrId =
    isolationType === 'crowdstrike'
      ? activeEntity?.edrPayload?.entity_id
      : isolationType === 'microsoft_defender'
        ? activeEntity?.defenderEdrPayload?.entity_id
        : undefined;

  const [performEdrAction, { loading: performEdrActionLoading }] =
    usePerformEdrAction({
      variables: {
        tenant: activeEntity?.tenantInfo?.tenant_id,
        action: entityEdrAction,
        edr_device_input: {
          entity_id: entityEdrId,
          // detection_id: activeEntity?.detectionData?.detection_id,
        },
      },
      onCompleted: ({ performEdrAction }) => {
        updateEntityStateAfterEdrAction(performEdrAction);
        toast({
          status: 'success',
          title: edrAction.toasts.success,
          description: edrAction.toasts.requestSubmitted,
          isClosable: true,
          position: 'bottom-right',
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: edrAction.toasts.error,
          description: edrAction.toasts.requestFailed,
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const updateEntityStateAfterEdrAction = (
    performEdrAction: EntityEdrStatusWithTimestampExt,
  ) => {
    if (isolationType === 'crowdstrike') {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        entityIsIsolated: !prevState?.entityIsIsolated,
        entityEdrStatus: {
          tenant: performEdrAction?.tenant,
          entity_edr_status: performEdrAction?.entity_edr_status,
          updated_timestamp: performEdrAction?.updated_timestamp,
        },
      }));
    } else if (isolationType === 'microsoft_defender') {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        entityDefenderIsIsolated: !prevState?.entityDefenderIsIsolated,
        entityDefenderEdrStatus: {
          tenant: performEdrAction?.tenant,
          entity_edr_status: performEdrAction?.entity_edr_status,
          updated_timestamp: performEdrAction?.updated_timestamp,
        },
      }));
    }
    onIsolateClose();
  };

  const handleEdrActionEvent = () => input && performEdrAction();

  const entityIsolated =
    isolationType === 'crowdstrike'
      ? entityEdrIsolated
      : isolationType === 'microsoft_defender'
        ? entityDefenderEdrIsolated
        : undefined;

  // apply timeoutId on a setTimeout
  // triggers delayed networking for popovers on hover
  const [timeoutId, setTimeoutId] = useState<number>();
  const [delayHandler, setDelayHandler] = useState<number>();

  useEffect(() => {
    if (isEntityModalOpen) {
      setTimeoutId(moment().unix());
    }
  }, [isEntityModalOpen]);

  useEffect(() => {
    if (!delayHandler && hoveredElement) {
      setDelayHandler(
        window.setTimeout(() => {
          setTimeoutId(moment().unix());
        }, 800),
      );
    }
    if (delayHandler && !hoveredElement && !isEntityModalOpen) {
      setTimeoutId(undefined);
      clearTimeout(delayHandler);
      setDelayHandler(undefined);
    }
  }, [delayHandler, hoveredElement, isEntityModalOpen]);

  useEffect(() => {
    setActiveEntity?.((prevState) => ({
      ...prevState,
      timeoutId,
    }));
  }, [timeoutId]);

  return (
    <EntityInformationContext.Provider value={{ ...entityInformationContext }}>
      {children}
      <EntityInformationModal
        isOpen={isEntityModalOpen}
        onClose={onEntityModalClose}
        activeEntity={activeEntity}
      />
      <Modal
        size="lg"
        isOpen={isolateOpen}
        onClose={onIsolateClose}
        title={`${
          entityIsolated
            ? edrAction.liftEntityIsolation
            : edrAction.isolateEntity
        } in ${
          isolationType === 'crowdstrike'
            ? 'Crowdstrike'
            : isolationType === 'microsoft_defender'
              ? 'Microsoft Defender'
              : ''
        }`}
        body={
          entityIsolated ? (
            <Text>
              <Text as="span" textStyle="body-md-bold">
                {activeEntity?.entityName}
              </Text>{' '}
              {edrAction.willBeAdded}
            </Text>
          ) : (
            <Alert variant="subtle" status="warning">
              <Text>
                <Text as="span" textStyle="body-md-bold">
                  {activeEntity?.entityName}
                </Text>{' '}
                {edrAction.willBeIsolated}
              </Text>
            </Alert>
          )
        }
        footer={
          <HStack>
            <Button variant="solid" colorScheme="gray" onClick={onIsolateClose}>
              {edrAction.cancel}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={handleEdrActionEvent}
              isLoading={performEdrActionLoading}
              isDisabled={performEdrActionLoading}
            >
              {entityIsolated
                ? edrAction.liftIsolation
                : edrAction.isolateEntity}
            </Button>
          </HStack>
        }
      />
      <Modal
        size="lg"
        isOpen={suppressOpen}
        onClose={onSuppressClose}
        title={activeEntity?.entityIsSuppressed ? unSuppress : suppress}
        body={
          <>
            <Text mb={2}>{suppressedMessage}</Text>
            <Alert variant="subtle" status="warning">
              {activeEntity?.entityIsSuppressed
                ? willReceiveAlerts
                : willNotReceiveAlerts}
            </Alert>
          </>
        }
        footer={
          <ButtonGroup>
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={onSuppressClose}
            >
              {cancel}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              isLoading={addLoading || removeLoading}
              data-testid="suppress-entity-submit"
              onClick={() => {
                if (activeEntity?.entityIsSuppressed) {
                  handleRemoveSubmit?.();
                } else {
                  handleAddSubmit?.();
                }
              }}
            >
              {activeEntity?.entityIsSuppressed ? unSuppress : suppress}
            </Button>
          </ButtonGroup>
        }
      />
    </EntityInformationContext.Provider>
  );
};
