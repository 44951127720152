import { gql } from '@apollo/client';

export const QUERY_ALERTS_YARA_PAGINATED = gql`
  query queryAlertsYaraPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on YaraAlert {
          alert_id
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_keys {
            tenant_alert
            tenant_entity
            tenant_alert_entity
          }
          alert_timestamp {
            observed
          }
          source_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          destination_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          path
          system_name
          uid
          orig_h
          orig_p
          resp_h
          resp_p
          fuid
          file_name
          source
          mime_type
          md5
          sha1
          sha256
          source_ip
          destination_ip
          tenant
          __typename
        }
        __typename
      }
      offset
      size
      total_items
      __typename
    }
  }
`;

export const QUERY_ALERTS_YARA_SUMMARY = gql`
  query queryAlertsYaraSummary(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
    $log_search_client_session_id: String!
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on YaraAlert {
          alert_id
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_timestamp {
            observed
          }
          path
          system_name
          uid
          orig_h
          orig_p
          resp_h
          resp_p
          fuid
          file_name
          source
          mime_type
          md5
          sha1
          sha256
          source_ip
          destination_ip
          session_summary(
            log_search_client_session_id: $log_search_client_session_id
          ) {
            ioc
            alert_summary
            alert_beacons
            unusual_findings
            attack_tactics
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const GET_YARA_ALERT_METADATA = gql`
  query GetYaraAlertMetadata($tenant: String!, $content_id: String!) {
    getAlertMetadata(items: [{ content_id: $content_id, tenant: $tenant }]) {
      metadata {
        id
        author
        date
        title
        match_meta
      }
    }
  }
`;
