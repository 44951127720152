import { gql } from '@apollo/client';

export const QUERY_ALERTS_ANOMALY_PAGINATED = gql`
  query queryAlertsAnomalyPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on AnomalyAlert {
          alert_id
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_keys {
            tenant_alert
            tenant_alert_entity
            tenant_entity
          }
          alert_timestamp {
            observed
          }
          source_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          destination_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          path
          system_name
          uid
          orig_h
          source_ip
          usecase
          usecase_description
          anomaly_type
          tenant
          __typename
        }
        __typename
      }
      offset
      size
      total_items
      __typename
    }
  }
`;

export const QUERY_ALERTS_ANOMALY_SUMMARY = gql`
  query queryAlertsAnomalySummary(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
    $log_search_client_session_id: String!
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on AnomalyAlert {
          alert_id
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_timestamp {
            observed
          }
          path
          system_name
          uid
          orig_h
          source_ip
          usecase
          usecase_description
          anomaly_type
          session_summary(
            log_search_client_session_id: $log_search_client_session_id
          ) {
            ioc
            alert_summary
            alert_beacons
            unusual_findings
            attack_tactics
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const GET_ANOMALY_ALERT_METADATA = gql`
  query GetAnomalyAlertMetadata($tenant: String!, $content_id: String!) {
    getAlertMetadata(items: [{ content_id: $content_id, tenant: $tenant }]) {
      metadata {
        id
        author
        date
        title
        match_meta
      }
    }
  }
`;
