import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { IGetChartTimelineFilters } from './types';

export const GET_CHART_TIMELINE_FILTERS = gql`
  query getChartTimelineFilters(
    $alert_name_filter: [AlertNameFilterInput]
    $detection_id: String
    $detection_tenant: String
    $start: Float
    $end: Float
    $interval: Float
    $severity_filter: [DetectionAlertSummarySeverities]
    $entity_filter: EntityFilterInput
    $size: Int
    $source_offset: Int
    $dest_offset: Int
    $source_dest_pair_offset: Int
  ) {
    getChartTimelineFilters(
      alert_name_filter: $alert_name_filter
      detection_id: $detection_id
      detection_tenant: $detection_tenant
      start: $start
      end: $end
      interval: $interval
      severity_filter: $severity_filter
      entity_filter: $entity_filter
      size: $size
      source_offset: $source_offset
      dest_offset: $dest_offset
      source_dest_pair_offset: $source_dest_pair_offset
    ) {
      alert_name_filter {
        alert_name
        detection_ids
      }
      entity_filter {
        source {
          source_entities
          dest_entities
          offset
          size
          total_items
        }
        dest {
          source_entities
          dest_entities
          offset
          size
          total_items
        }
        source_dest {
          source_entities
          dest_entities
          offset
          size
          total_items
        }
      }
      severity_filter
    }
  }
`;

export const useGetChartTimelineFilters = (
  options: QueryHookOptions<IGetChartTimelineFilters, OperationVariables>,
) => {
  return useQuery<IGetChartTimelineFilters>(
    GET_CHART_TIMELINE_FILTERS,
    options,
  );
};
