import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Spinner,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { PaloAltoLogoSmall } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { AuthContext, EntityInstance } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { EntityPanelRow } from '@gamma/investigator/pages/detections';
import {
  GET_ENTITY_EDR_STATUS,
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
  useGetEntityEdrStatus,
  usePerformEdrAction,
} from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import { Alert, Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useMemo } from 'react';

const { integrations } = i18n.pages;
const { learnMore, additionalEdrIntegration, paloAlto } = integrations;
const {
  unblockIPInPaloAlto,
  blockIPInPaloAlto,
  blockIP,
  unblockIP,
  unblockActionExplanation,
  blockActionExplanation,
} = paloAlto;

const { entityEDR } = i18n.pages.detections;
const { edrAction } = entityEDR;

const { PALO_ALTO_DOCS } = URLS;

interface PaloAltoModalSectionProps {
  activeEntity?: EntityInstance;
}

export const PaloAltoModalSection = ({
  activeEntity,
}: PaloAltoModalSectionProps) => {
  const { userRole, userLicense } = useContext(AuthContext);

  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isLoading, entityName, tenantInfo } = activeEntity || {};

  const { tenant_id } = tenantInfo || {};

  const entityId = `${tenant_id}-palo_alto_networks-endpoint_device-${entityName}`;
  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const {
    data: configuration,
    loading: edrConfigLoading,
    error: edrConfigError,
  } = getEDRConfigQuery({
    skip: !tenant_id && !userLicense?.tenant,
    variables: {
      tenant: tenant_id || userLicense?.tenant,
      provider_name: 'palo_alto_networks',
    },
  });
  const {
    data,
    loading: getEntityEdrStatusLoading,
    error: getEntityEdrStatusError,
  } = useGetEntityEdrStatus({
    fetchPolicy: 'network-only',
    skip: (!tenant_id && !userLicense?.tenant) || !entityName,
    variables: {
      entity_id: `${tenant_id}-palo_alto_networks-endpoint_device-${entityName}`,
      tenant: tenant_id || userLicense?.tenant,
    },
  });

  const [performEdrAction, { loading: performEdrActionLoading }] =
    usePerformEdrAction({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_ENTITY_EDR_STATUS,
          variables: {
            entity_id: `${tenant_id}-palo_alto_networks-endpoint_device-${entityName}`,
            tenant: tenant_id || userLicense?.tenant,
          },
        },
      ],
      variables: {
        tenant: tenant_id || userLicense?.tenant,
        action:
          data?.getEntityEdrStatus.entity_edr_status === 'isolation_requested'
            ? 'lift_isolation'
            : 'isolate',
        edr_device_input: {
          entity_id: entityId,
        },
        provider_name: 'palo_alto_networks',
      },
      onCompleted: () => {
        toast({
          status: 'success',
          title: edrAction.toasts.success,
          description: edrAction.toasts.statusUpdateSubmitted,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
      onError: () => {
        toast({
          status: 'error',
          title: edrAction.toasts.error,
          description: edrAction.toasts.statusUpdateFailed,
          isClosable: true,
          position: 'bottom-right',
        });
        onClose();
      },
    });

  const { entity_edr_status } = data?.getEntityEdrStatus || {};
  const edrStatusAlert = useMemo(() => {
    if (entity_edr_status) {
      switch (entity_edr_status) {
        case 'normal':
          return {
            colorScheme: 'green',
            statusText: edrAction.normal,
          };
        case 'isolated':
          return {
            colorScheme: 'red',
            statusText: edrAction.blocked,
          };
        case 'isolation_requested':
        case 'isolation_pending':
          return {
            colorScheme: 'orange',
            statusText: edrAction.pendingBlock,
          };
        case 'lift_isolation_pending':
        case 'lift_isolation_requested':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingUnblock,
          };
      }
    }
    return undefined;
  }, [entity_edr_status]);

  return (
    <VStack w="100%" alignItems="start">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
          entity_edr_status === 'isolation_requested'
            ? unblockIPInPaloAlto
            : blockIPInPaloAlto
        }
        body={
          <VStack>
            <EntityPanelRow heading={edrAction.entityStatus}>
              <Tag
                size="md"
                variant="solid"
                textAlign="left"
                colorScheme={edrStatusAlert?.colorScheme}
              >
                {edrStatusAlert?.statusText}
              </Tag>
            </EntityPanelRow>
            <EntityPanelRow heading={edrAction.entity}>
              {entityName}
            </EntityPanelRow>
            <Alert variant="subtle" status="warning">
              {entity_edr_status === 'isolation_requested'
                ? i18n.formatString(unblockActionExplanation, entityName || '')
                : i18n.formatString(blockActionExplanation, entityName || '')}
            </Alert>
          </VStack>
        }
        footer={
          <ButtonGroup>
            <Button variant="solid" colorScheme="gray" onClick={onClose}>
              {edrAction.cancel}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              isLoading={performEdrActionLoading}
              data-testid="suppress-entity-submit"
              onClick={() => {
                performEdrAction();
              }}
            >
              {entity_edr_status === 'isolation_requested'
                ? unblockIP
                : blockIP}
            </Button>
          </ButtonGroup>
        }
      ></Modal>
      <HStack>
        <PaloAltoLogoSmall />
        <Text textStyle="h5">Palo Alto</Text>
      </HStack>
      {edrConfigLoading ||
      isLoading ||
      edrConfigError ||
      getEntityEdrStatusLoading ||
      getEntityEdrStatusError ? (
        <Center w="100%">
          <GraphQLReqStatus
            error={getEntityEdrStatusError || edrConfigError}
            loading={
              !!isLoading || edrConfigLoading || getEntityEdrStatusLoading
            }
          />
        </Center>
      ) : configuration?.getEDRConfiguration === null ||
        configuration?.getEDRConfiguration.enabled === false ? (
        <Alert status="info" variant="subtle" isClosable={false}>
          <VStack alignItems="start">
            <Text>{additionalEdrIntegration}</Text>
            <Button
              as={RouterLink}
              target="_blank"
              variant="solid"
              colorScheme="blue"
              to={PALO_ALTO_DOCS}
            >
              {learnMore}
            </Button>
          </VStack>
        </Alert>
      ) : (
        <HStack w="100%" spacing={8} alignItems="start">
          <EntityPanelRow heading={edrAction.entityStatus}>
            <HStack>
              {edrStatusAlert ? (
                <HStack spacing={0}>
                  <Tag
                    size="md"
                    variant="solid"
                    textAlign="center"
                    colorScheme={edrStatusAlert?.colorScheme}
                  >
                    {edrStatusAlert?.statusText}
                  </Tag>
                  <Tooltip
                    w="120px"
                    placement="top"
                    label={
                      userRole !== 'admin'
                        ? edrAction.requestPermissions
                        : undefined
                    }
                  >
                    <Button
                      marginLeft={1}
                      size="xs"
                      variant="solid"
                      colorScheme="gray"
                      onClick={onOpen}
                      isDisabled={
                        userRole !== 'admin' || getEntityEdrStatusLoading
                      }
                    >
                      {entity_edr_status === 'isolation_requested'
                        ? unblockIP
                        : blockIP}
                    </Button>
                  </Tooltip>
                </HStack>
              ) : getEntityEdrStatusLoading ? (
                <Box w="20px" h="20px">
                  <Spinner size="xs" color="white" />
                </Box>
              ) : (
                <Text>---</Text>
              )}
            </HStack>
          </EntityPanelRow>
        </HStack>
      )}
    </VStack>
  );
};
