import { ApolloError } from '@apollo/client';
import { Box, PlacementWithLogical } from '@chakra-ui/react';
import { ColumnFilterPopover, DataTable } from '@gamma/data-table';
import {
  EntityInformationTrigger,
  SeverityScore,
  TopDetectionEntitiesTablePopper,
} from '@gamma/investigator/components';
import { ROUTES, graphqlErrorRedirects } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useIsNewSearch,
  useManualPagination,
  useUserSession,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionSummary,
  useGetTopDetectionEntitiesPaginated,
} from '@gamma/investigator/queries';
import { escapeSpecialChars } from '@gamma/investigator/utilities';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Accessor, Column } from 'react-table';

import validator from 'validator';
import { TopAlertsTableFilters } from '../AlertCategoriesDetectionsTable';

interface HighRiskEntitiesDetectionsTableProps {
  hiddenCols?: Accessor<DetectionSummary>[] | any[];
  rowPopperPlacement?: PlacementWithLogical;
  onDataLoad?: (data: {
    summaries: {
      severity: number;
      entity_name: string;
      detection_count: number;
      entity_category: string;
      entity_type: string;
      total_detections: number;
      is_custom_severity: boolean;
    }[];
    total_items: number;
  }) => void;
}

const dataTableColumns: Column<DetectionSummary>[] = [
  {
    width: 100,
    Header: i18n.tables.score,
    accessor: 'severity',
    Cell: (props) => {
      return (
        <SeverityScore
          score={props.value}
          isCustom={props?.data?.[props?.row?.index]?.is_custom_severity}
        />
      );
    },
  },
  {
    width: 200,
    Header: i18n.tables.entity,
    accessor: 'entity_name',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) =>
      !validator.isIP(props.value) ? (
        <Box ml={2}>{props.value}</Box>
      ) : (
        <EntityInformationTrigger
          conditionallySuppress={true}
          detectionSummary={props.row.original}
        />
      ),
  },
  {
    Header: i18n.tables.detectionsHeading,
    accessor: 'detection_count',
  },
];

const mustCondition = (
  filters: { id: string; value: string }[],
  orgTenantsQueryParam?: string[],
) => {
  const formattedQuery = escapeSpecialChars({
    chars: filters?.[0]?.value,
  });

  return [
    `{"regexp":{"alert_entity.entity_name.keyword":{"value":".*${formattedQuery}.*","case_insensitive":true}}}`,
    `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
    '{"term": {"detection_status": "open"}}',
  ];
};

export const HighRiskEntitiesDetectionsTableFooter = ({
  loading,
  error,
  data,
  signOut,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  data: DetectionSummary[];
  signOut: () => Promise<void>;
}) => {
  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          signOut={signOut}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }
  if (!data.length) {
    return <Panel>{i18n.pages.alerts.na}</Panel>;
  } else {
    return null;
  }
};

export const HighRiskEntitiesDetectionsTable = (
  props: HighRiskEntitiesDetectionsTableProps,
) => {
  const { signOut } = useUserSession();
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const { hiddenCols = [], rowPopperPlacement = 'right-start' } = props;
  const navigate = useNavigate();

  const { start, end } = useDateRangeQueryString();
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [topEntitiesTableData, setTopEntitiesTableData] = useState<
    DetectionSummary[]
  >([]);
  const [filters, setFilters] = useState<
    { id: string; value: string }[] | null
  >(null);

  const {
    sortBy,
    offset,
    pageSize,
    pageIndex,
    pageCount,
    onFetchData,
    getPageCount,
    getSortsForAPI,
  } = useManualPagination<DetectionSummary>({
    initialFetch,
    sortBy: [{ id: 'severity', desc: true }],
  });

  useEffect(() => {
    setInitialFetch(true);
  }, [start, end]);

  const filteredDataTableColumns = useMemo(
    () =>
      dataTableColumns.filter(
        (col) => !hiddenCols.includes(col.accessor) && col,
      ),
    [hiddenCols],
  );

  const { isNewSearch } = useIsNewSearch({
    filters,
  });

  const noFilterVariables = {
    start,
    end,
    offset: isNewSearch ? 0 : offset,
    size: pageSize,
    sort: getSortsForAPI(sortBy),
    must_conds: [
      `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
      '{"term": {"detection_status": "open"}}',
    ],
  };

  const variables = filters?.length
    ? {
        ...noFilterVariables,
        must_conds: mustCondition(filters, orgTenantsQueryParam),
      }
    : noFilterVariables;

  const { data, loading, error, refetch } = useGetTopDetectionEntitiesPaginated(
    {
      notifyOnNetworkStatusChange: true,
      skip: !start || !end || !orgTenantsQueryParam?.length,
      variables,
      onCompleted: ({ getTopDetectionEntitiesPaginated }) => {
        setInitialFetch(false);
        getPageCount(getTopDetectionEntitiesPaginated);
        setTopEntitiesTableData(getTopDetectionEntitiesPaginated.summaries);
      },
    },
  );

  useEffect(() => {
    if (
      data?.getTopDetectionEntitiesPaginated &&
      !loading &&
      props.onDataLoad
    ) {
      props.onDataLoad({
        summaries: data.getTopDetectionEntitiesPaginated.summaries.map(
          (summary: any) => ({
            severity: summary.severity,
            entity_name: summary.entity_name,
            detection_count: summary.detection_count,
            entity_category: summary.entity_category,
            entity_type: summary.entity_type,
            total_detections: summary.total_detections,
            is_custom_severity: summary.is_custom_severity,
          }),
        ),
        total_items: data.getTopDetectionEntitiesPaginated.total_items,
      });
    }
  }, [data, loading, props.onDataLoad]);

  const handleColFilter = (filters: TopAlertsTableFilters) => {
    setFilters(filters ?? null);
    if (filters.length) {
      refetch({
        ...noFilterVariables,
        offset: 0,
        must_conds: mustCondition(filters, orgTenantsQueryParam),
      });
    }
  };

  const tableData =
    !loading && !error && topEntitiesTableData.length
      ? topEntitiesTableData
      : [];

  return (
    <Panel>
      <FlushPanelContent>
        <div data-testid="top-entities-table">
          <DataTable
            isMemod={true}
            hideColumnHeaders={loading}
            isPaginable={Boolean(!loading && !error && tableData.length)}
            isFilterManual={true}
            onColumnFilter={handleColFilter}
            data={tableData}
            pageSize={pageSize}
            pageCount={pageCount}
            autoResetPage={false}
            autoResetSortBy={false}
            isPaginationManual={true}
            columns={filteredDataTableColumns}
            onFetchData={onFetchData}
            // @ts-ignore
            rowWrapper={TopDetectionEntitiesTablePopper(rowPopperPlacement)}
            itemCount={data?.getTopDetectionEntitiesPaginated?.total_items || 0}
            onRowClick={({ original }, event) => {
              if (
                (event.target as HTMLElement)?.className?.includes &&
                ![
                  'icon',
                  'link',
                  'menu',
                  'button',
                  'switch',
                  'text',
                  'image',
                  'stack',
                ].some((classNameString) =>
                  (event.target as HTMLElement).className.includes(
                    classNameString,
                  ),
                )
              ) {
                navigate({
                  pathname: ROUTES.detections,
                  search: `start=${start}&end=${end}&${
                    original.entity_category ?? 'source'
                  }_name=${original.entity_name}`,
                });
              }
            }}
            initialState={{
              pageIndex,
              sortBy,
            }}
            footer={HighRiskEntitiesDetectionsTableFooter({
              loading,
              error,
              data: topEntitiesTableData,
              signOut,
            })}
          />
        </div>
      </FlushPanelContent>
    </Panel>
  );
};
