import {
  Box,
  Flex,
  HStack,
  Tag,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { SkeletonLoader } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';

export interface IntegrationsConfigInfoProps {
  availabilityTag: React.ReactNode;
  cardView?: boolean;
  description: string;
  footer?: React.ReactNode;
  pathName: string;
  isLoading: boolean;
  title: string;
  tags: string[];
  onClick?: () => void;
}

export const AvailabilityTag = ({
  isEnabled,
  isLoading,
  fallbackText,
}: {
  isEnabled: boolean;
  isLoading?: boolean;
  fallbackText?: string;
}) => {
  const { integrations } = i18n.pages;
  const { available, enabled } = integrations;
  const unavailableText = fallbackText || available;
  const showCheck = unavailableText === available;
  const greenBg = useColorModeValue('green.100', 'green.600');
  const grayBg = useColorModeValue('gray.100', 'gray.600');
  return isLoading ? (
    <LoadingSpinner size="sm" />
  ) : (
    <Tag
      data-testid="configuration-status"
      alignItems="center"
      justifyContent="center"
      bg={isEnabled ? greenBg : grayBg}
      variant="unstyled"
      maxH={2}
    >
      {isEnabled ? (
        <MuiIcon mr={2}>check_circle</MuiIcon>
      ) : (
        showCheck && <MuiIcon mr={2}>add_circle</MuiIcon>
      )}
      <Text textStyle="body-sm">{isEnabled ? enabled : unavailableText}</Text>
    </Tag>
  );
};

export const IntegrationsConfigInfo = ({
  availabilityTag,
  cardView = false,
  description,
  footer,
  pathName,
  isLoading,
  title,
  tags,
  onClick,
}: IntegrationsConfigInfoProps) => {
  const cardStyles = cardView
    ? {}
    : { border: 'none', backgroundColor: 'transparent' };

  return isLoading ? (
    <SkeletonLoader rowCount={5} />
  ) : (
    <Panel
      w="306px"
      h={cardView ? '260px' : '400px'}
      onClick={onClick ?? undefined}
      style={cardStyles}
      layerStyle="first"
      _hover={{ bgColor: 'blue.alpha500.10', cursor: '' }}
      padding={3}
    >
      <VStack spacing={cardView ? 2 : 4}>
        {/* Logo and Text */}
        <Flex
          w="100%"
          flexDir={cardView ? 'row' : 'column-reverse'}
          mt={1}
          justifyContent="space-between"
        >
          <Box
            maxW="300px"
            mt={1}
            display="flex"
            alignItems="left"
            flexDir={cardView ? 'column' : 'row'}
            alignContent="center"
          >
            <Box
              h="48px"
              w="48px"
              backgroundRepeat="no-repeat"
              bgSize="cover"
              bgImage={`/assets/images/integrations/${pathName}-icon.svg`}
            ></Box>
            <Box alignContent={cardView ? 'start' : 'center'}>
              <Text textStyle={cardView ? 'h5' : 'h4'}>{title}</Text>
            </Box>
          </Box>
          <Box>{availabilityTag}</Box>
        </Flex>
        <Text textAlign="left" textStyle="body-md" width="100%">
          {description}
        </Text>
        <HStack w="100%" alignItems="start">
          {tags.map((name) => (
            <Tag key={name} border="1px solid gray">
              {name}
            </Tag>
          ))}
        </HStack>
        {footer && footer}
      </VStack>
    </Panel>
  );
};
