import {
  Box,
  Circle,
  Flex,
  FlexProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Children, createContext, ReactNode, useContext } from 'react';

interface IDottedProgressBarContext {
  activeStep: number;
}

const dottedProgressBarContext = createContext<IDottedProgressBarContext>({
  activeStep: 1,
});

export interface DottedProgressBarProps
  extends Required<IDottedProgressBarContext>,
    Omit<FlexProps, 'width'> {
  children?: ReactNode;
  width?: number;
  'data-testid'?: string;
}

export const DottedProgressBar = ({
  activeStep = 1,
  children,
  width = 320,
  'data-testid': dataTestId = 'dotted-progress-bar',
  ...rest
}: DottedProgressBarProps) => {
  const totalSteps = Children.count(children);
  const progressWidth = ((activeStep - 1) / (totalSteps - 1)) * 98;

  return (
    <Flex
      data-testid={dataTestId}
      justifyContent={'space-between'}
      marginBottom={'30px'}
      maxWidth={'100%'}
      width={width}
      position={'relative'}
      _before={{
        content: '""',
        position: 'absolute',
        background: 'blue.400',
        opacity: 0.2,
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        height: '8px',
        width: '100%',
      }}
      {...rest}
    >
      <Box
        w={`${progressWidth}%`}
        left={'1px'}
        background={'blue.400'}
        position={'absolute'}
        top={'50%'}
        transform={'translateY(-50%)'}
        height={'8px'}
        transition={'0.4s ease;'}
      >
        {' '}
      </Box>
      <dottedProgressBarContext.Provider value={{ activeStep }}>
        {children}
      </dottedProgressBarContext.Provider>
    </Flex>
  );
};

export interface DottedProgressBarItemProps {
  stepNumber: number;
  children?: ReactNode;
  'data-testid'?: string;
}

export const DottedProgressBarItem = ({
  stepNumber,
  children,
  'data-testid': dataTestId = 'dotted-progress-bar-item',
}: DottedProgressBarItemProps) => {
  const { activeStep } = useContext(dottedProgressBarContext);
  const borderColor = stepNumber <= activeStep ? '#128EE3' : '#23435A';
  const textColor = stepNumber <= activeStep ? 'blue.400' : 'blue.300';
  const textStyle = stepNumber === activeStep ? 'body-md-bold' : 'body-md';
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <Circle
      data-testid={dataTestId}
      size={4}
      position={'relative'}
      bg={bgColor}
      border={`3px solid ${borderColor}`}
    >
      {typeof children === 'string' ? (
        <Text
          position={'absolute'}
          top={'20px'}
          textStyle={textStyle}
          color={textColor}
          whiteSpace={'nowrap'}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </Circle>
  );
};
