import { createContext, ReactNode } from 'react';

export interface SplitLayoutContext {
  isDrawer?: boolean;
}

export const defaultSplitLayoutContext: SplitLayoutContext = {
  isDrawer: false,
};
export const SplitLayoutContext = createContext(defaultSplitLayoutContext); // eslint-disable-line no-redeclare

export interface SplitLayoutProviderProps extends SplitLayoutContext {
  children: ReactNode;
  storageKey?: string;
}

export const SplitLayoutProvider = ({
  children,
  storageKey: _storageKey,
  isDrawer,
  ...methods
}: SplitLayoutProviderProps) => {
  return (
    <SplitLayoutContext.Provider
      value={{
        ...defaultSplitLayoutContext,
        ...methods,
        isDrawer,
      }}
    >
      {children}
    </SplitLayoutContext.Provider>
  );
};
