import { ApolloError, useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  useOutsideClick,
  usePrevious,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';
import { AsyncCombobox, Combobox, NumberInput } from '@gamma/form-fields';
import { MuiIcon, RefreshWithCircleIcon } from '@gamma/icons';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import {
  useDateRangeContext,
  useDateRangeQueryString,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryDetectionsPaginated,
  QUERY_DETECTIONS_ENTITIES_SUGGESTIONS,
  QUERY_DETECTIONS_FIELD_SUGGESTIONS,
  useListUsers,
  useQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { isEqual, uniqWith } from 'lodash';
import moment from 'moment';
import {
  createRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SortingRule } from 'react-table';

interface SelectOption {
  label: string;
  value: string;
}

interface Suggestionlist {
  label: string;
  options: SelectOption[];
}

interface SeverityRange {
  low: number;
  high: number;
}

type EntityType = 'source' | 'destination';

interface DetectionsFormData {
  assignee: SelectOption[];
  alertCategory: SelectOption[];
  alertType: SelectOption[];
  source: SelectOption[];
  destination: SelectOption[];
  status: SelectOption[];
  severityRange: null | string;
}

const { controls, detection, reset } = i18n.pages.detections;

const {
  assignedToMe,
  alertCategory,
  searchCategory,
  searchAssignee,
  alertType,
  source: sourceText,
  destination: destinationText,
  searchSource,
  searchDestination,
  noResults,
  adjustSearch,
  severityScore,
  min,
  closed,
  max,
  open,
  clearAll,
  cancel,
  apply,
  machineLearning,
  notice,
  searchBased,
  suricata,
  yara,
  anomaly,
} = controls;

const { unassigned, status: statusLabel, assignee: assigneeLabel } = detection;

const { detections } = ROUTES;

const defaultFilterValues = {
  status: [{ label: 'open', value: 'open' }],
  assignee: [],
  alertCategory: [],
  alertType: [],
  source: [],
  destination: [],
  entity: [],
  severityRange: null,
};

interface DetectionsFiltersProps {
  size: number;
  offset: number;
  getPageCount: any;
  getSortsForAPI: (
    sortBy: SortingRule<Detection>[],
  ) => { sort_by: string; sort_dir: string }[];
  formatSort: (value: string) => { id: string; desc: boolean }[];
  setQueryVariables: React.Dispatch<React.SetStateAction<any>>;
  setDetectionsData: React.Dispatch<
    React.SetStateAction<IQueryDetectionsPaginated | undefined>
  >;
  setDetectionsError: React.Dispatch<
    React.SetStateAction<ApolloError | undefined>
  >;
  setDetectionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DetectionsFilters = memo(
  ({
    size,
    offset,
    formatSort,
    getPageCount,
    getSortsForAPI,
    setQueryVariables,
    setDetectionsData,
    setDetectionsError,
    setDetectionsLoading,
  }: DetectionsFiltersProps) => {
    const client = useApolloClient();
    const { start, end } = useDateRangeQueryString();

    const { user } = useContext(AuthContext);

    const { orgTenantsQueryParam } = useContext(OrgTenantsContext);
    const hasOrgTenants =
      orgTenantsQueryParam && orgTenantsQueryParam?.length > 0;

    const { search } = useLocation();
    const navigate = useNavigate();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const [searchParams, setSearchParams] = useSearchParams();

    const { dateRange } = useDateRangeContext();
    const contextEnd = moment().unix();
    const contextStart = dateRange
      ? moment().subtract(dateRange[0], dateRange[1]).unix()
      : undefined;

    const alertTypesMap: { [key: string]: string } = {
      ml: machineLearning,
      notice: notice,
      suricata_corelight: suricata,
      custom_search_rule: searchBased,
      yara_corelight: yara,
      anomaly: anomaly,
    };

    const alert_category = params.get('alert_category');
    const alert_type = params.get('alert_type');
    const source = params.get('source_name');
    const destination = params.get('destination_name');
    const status = params.get('status');
    const assignee = params.get('assignee');
    const sort_by = params.get('sort_by');
    const date_start = params.get('start');
    const date_end = params.get('end');
    const severity_range = params.get('severity_range');
    const refetch_main = params.get('refetch_main');

    const [categorySuggestions, setCategorySuggestions] = useState<
      Suggestionlist[]
    >(JSON.parse(localStorage.getItem('category_suggestions') || '[]'));
    const [sourceSuggestions, setSourceSuggestions] = useState<
      Suggestionlist[]
    >(JSON.parse(localStorage.getItem('source_suggestions') || '[]'));
    const [destinationSuggestions, setDestinationSuggestions] = useState<
      Suggestionlist[]
    >(JSON.parse(localStorage.getItem('destination_suggestions') || '[]'));

    const { control, setValue, watch } = useForm<DetectionsFormData>({
      mode: 'onSubmit',
      defaultValues: defaultFilterValues,
    });

    const formData = watch();

    const { loading: listUsersLoading, data: { listUsers = [] } = {} } =
      useListUsers({
        skip: !user?.attributes['custom:tenant_id'],
        variables: {
          tenants: [`${user?.attributes['custom:tenant_id']}`],
          groups: ['admin', 'analyst'],
        },
      });

    useEffect(() => {
      if (status) {
        setValue(
          'status',
          status
            ? status?.split('&status=')?.map((status: string) => ({
                label: status,
                value: status,
              }))
            : [],
        );
      }
      if (assignee) {
        setValue(
          'assignee',
          assignee
            ? assignee?.split('&assignee=')?.map((assignee: string) => ({
                label: assignee,
                value: assignee,
              }))
            : [],
        );
      }
    }, []);

    useEffect(() => {
      if (alert_category) {
        setValue(
          'alertCategory',
          alert_category
            ? alert_category
                ?.split('&alert_category=')
                ?.map((category: string) => ({
                  label: category,
                  value: category,
                }))
            : [],
        );
      }
      if (alert_type) {
        setValue(
          'alertType',
          alert_type
            ? alert_type.split('&alert_type=')?.map((type: string) => ({
                label: alertTypesMap[type],
                value: type,
              }))
            : [],
        );
      }
      if (source) {
        setValue(
          'source',
          source
            ? source.split('&source_name=')?.map((entity: string) => ({
                label: entity,
                value: entity,
              }))
            : [],
        );
      }
      if (destination) {
        setValue(
          'destination',
          destination
            ? destination
                .split('&destination_name=')
                ?.map((entity: string) => ({
                  label: entity,
                  value: entity,
                }))
            : [],
        );
      }
      if (severity_range) {
        setValue('severityRange', severity_range);
      }
    }, [
      sort_by,
      alert_category,
      alert_type,
      source,
      destination,
      status,
      setValue,
      severity_range,
    ]);

    useEffect(() => {
      if (!date_end || !date_start) {
        params.set('start', String(contextStart));
        params.set('end', String(contextEnd));
        navigate(
          {
            pathname: detections,
            search: params.toString(),
          },
          {
            replace: true,
          },
        );
      }
    }, [contextEnd, contextStart, date_end, date_start, navigate]);

    useEffect(() => {
      const categories = formData?.alertCategory
        ?.map((category) => category.value)
        .join('&alert_category=');
      if (categories) {
        params.set('alert_category', categories);
      } else {
        params.delete('alert_category');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.alertCategory, navigate]);

    useEffect(() => {
      const types = formData?.alertType
        ?.map((type) => type.value)
        .join('&alert_type=');
      if (types) {
        params.set('alert_type', types);
      } else {
        params.delete('alert_type');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.alertType, navigate]);

    useEffect(() => {
      const entities = formData?.source
        ?.map((entity) => entity.value)
        .join('&source_name=');
      if (entities) {
        params.set('source_name', entities);
      } else {
        params.delete('source_name');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.source, navigate]);

    useEffect(() => {
      const entities = formData?.destination
        ?.map((entity) => entity.value)
        .join('&destination_name=');
      if (entities) {
        params.set('destination_name', entities);
      } else {
        params.delete('destination_name');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.destination, navigate]);

    useEffect(() => {
      const assignees = formData?.assignee
        ?.map((assignee) => assignee.value)
        .join('&assignee=');
      if (assignees) {
        params.set('assignee', assignees);
      } else {
        if (!listUsersLoading) {
          params.delete('assignee');
        }
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.assignee, navigate]);

    useEffect(() => {
      const statuses = formData?.status
        .map((status) => status.value)
        .join('&status=');
      if (statuses) {
        params.set('status', statuses);
      } else {
        params.delete('status');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.status, navigate]);

    useEffect(() => {
      const severityRange = formData?.severityRange;
      if (severityRange) {
        params.set('severity_range', severityRange);
      } else {
        params.delete('severity_range');
      }
      navigate(
        {
          pathname: detections,
          search: params.toString(),
        },
        {
          replace: true,
        },
      );
    }, [formData?.severityRange, navigate]);

    const onFilterClear = () => {
      setValue('status', defaultFilterValues.status);
      setValue('alertCategory', defaultFilterValues.alertCategory);
      setValue('alertType', defaultFilterValues.alertType);
      setValue('source', defaultFilterValues.source);
      setValue('destination', defaultFilterValues.destination);
      setValue('assignee', defaultFilterValues.assignee);
      setValue('severityRange', defaultFilterValues.severityRange);
      setFormSeverityRange(null);
    };

    const filtersChanged = () => {
      return !isEqual({ ...formData }, { ...defaultFilterValues });
    };

    const [formSeverityRange, setFormSeverityRange] =
      useState<SeverityRange | null>(null);

    const [severityRangeIsOpen, setSeverityRangeIsOpen] =
      useState<boolean>(false);

    useEffect(() => {
      if (severity_range) {
        const range = JSON.parse(severity_range);
        setFormSeverityRange({
          low: range[0],
          high: range[1],
        });
      }
    }, []);

    const wrapQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}]}}}`;

    const wrapSuggestionsQuery = (conditions: string) =>
      `{"query":{"bool":{"must":[${conditions}]}}}`;

    const assigneeList = formData.assignee.map((assignee) => assignee?.value);

    const hasUnassigned =
      [...assigneeList]?.filter((assignee) => assignee === 'unassigned')
        ?.length > 0;

    const assignees = [...assigneeList].filter(
      (assignee) => assignee !== 'unassigned',
    );

    const severityRange = formData.severityRange
      ? JSON.parse(formData.severityRange)
      : [];

    const statusQueryFilters = formData.status.map(
      (status) => `{"detection_status":{"value":"${status.value}"}}`,
    );

    const statusQueryFiltersData =
      statusQueryFilters.length > 0
        ? `{"bool":{"should":[${statusQueryFilters
            .map((status) => `{"term":${status}}`)
            .join(',')}]}}`
        : '';

    const typeQueryFilters = formData.alertType.map(
      (type) => `{"alert_info.alert_type":{"value":"${type.value}"}}`,
    );
    const typeQueryFiltersData =
      typeQueryFilters.length > 0
        ? `{"bool":{"should":[${typeQueryFilters
            .map((type) => `{"term":${type}}`)
            .join(',')}]}}`
        : '';

    const severityRangeData =
      severityRange.length > 0
        ? `{"bool":{"should":[{"range":{"rank.severity":{"gte":${severityRange[0]},"lte":${severityRange[1]}}}}]}}`
        : '';

    const assigneesData =
      hasUnassigned || assignees.length > 0
        ? `{"bool":{"should":[${assignees.map(
            (assignee) =>
              `{"term":{"assignment_info.assigned_to_username.keyword":{"value":"${assignee}"}}}`,
          )}${hasUnassigned && assignees.length > 0 ? ',' : ''}${
            hasUnassigned
              ? '{"bool":{"must_not":[{"exists":{"field":"assignment_info.assigned_to_username"}}]}}'
              : ''
          }]}}`
        : '';

    const hasOrgTenantsData = hasOrgTenants
      ? `{"terms":{"tenant":${JSON.stringify(orgTenantsQueryParam)}}}`
      : '';

    const sharedConditionsArray = [
      `{"range":{"total_alert_count":{"gt":0}}}`,
      `{"range":{"earliest_start_timestamp":{"lte":${end}}}}`,
      `{"range":{"latest_start_timestamp":{"gte":${start}}}}`,
      statusQueryFiltersData,
      typeQueryFiltersData,
      severityRangeData,
      assigneesData,
      hasOrgTenantsData,
    ];

    const sharedConditions = `{"range":{"total_alert_count":{"gt":0}}},{"range":{"earliest_start_timestamp":{"lte":${end}}}},{"range":{"latest_start_timestamp":{"gte":${start}}}}${
      statusQueryFilters.length > 0 || severityRange.length > 0 ? ',' : ''
    }${statusQueryFiltersData}${
      typeQueryFilters.length > 0 ? ',' : ''
    }${typeQueryFiltersData}${
      statusQueryFilters.length > 0 && severityRange.length > 0 ? ',' : ''
    }${severityRangeData}${
      hasUnassigned || assignees.length > 0 ? ',' : ''
    }${assigneesData}${hasOrgTenants ? ',' : ''}${hasOrgTenantsData}`;

    const alertCategoryData =
      formData.alertCategory.length > 0
        ? `{"bool":{"should":[${formData.alertCategory
            ?.map((category) =>
              category?.value !== ''
                ? `{"term":{"alert_info.alert_name.keyword":{"value":"${category?.value}"}}}`
                : '',
            )
            .join(',')}]}}`
        : '';

    const sourceData =
      formData.source.length > 0
        ? `{"terms":{"_search_keys.source_entities.keyword":${JSON.stringify(
            formData.source.map((entity) => entity.value),
          )}}}`
        : '';

    const destinationData =
      formData.destination.length > 0
        ? `{"terms":{"_search_keys.destination_entities.keyword":${JSON.stringify(
            formData.destination.map((entity) => entity.value),
          )}}}`
        : '';

    const conditions = `${sharedConditions}${
      alertCategoryData && ','
    }${alertCategoryData}${sourceData && ','}${sourceData}${
      destinationData && ','
    }${destinationData}`;

    const [query, setQuery] = useState<string>('');

    useEffect(() => {
      if (hasOrgTenants) {
        setQuery(wrapQuery(conditions));
      }
    }, [formData, hasOrgTenants]);

    const variables = useMemo(() => {
      if (hasOrgTenants) {
        setQueryVariables({
          query,
          size,
          offset,
          sortBy: sort_by ?? '',
        });
      }
      return {
        query,
        size,
        offset,
        sortBy: sort_by ?? '',
      };
    }, [query, size, offset, sort_by, hasOrgTenants, setQueryVariables]);

    const [fetchData, { loading, data, error, refetch }] =
      useQueryDetectionsPaginated({
        variables: {
          query: variables.query,
          size: variables.size,
          offset: variables.offset,
          sort: getSortsForAPI(formatSort(variables.sortBy)),
        },
        onCompleted: (data) => {
          setDetectionsData(data);
          getPageCount(data?.queryDetectionsPaginated);
        },
      });

    useEffect(() => {
      if (end && start && hasOrgTenants && variables?.query) {
        fetchData();
      }
    }, [end, start, variables, fetchData, hasOrgTenants]);

    useEffect(() => {
      setDetectionsError(error);
      setDetectionsLoading(loading);
    }, [error, loading, setDetectionsError, setDetectionsLoading]);

    const getEntitiesSuggestions = async (
      inputValue: string,
      entityCategory: EntityType = 'source',
    ) => {
      const additionalConditions =
        entityCategory === 'source' ? destinationData : sourceData;
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: QUERY_DETECTIONS_ENTITIES_SUGGESTIONS,
        variables: {
          entity_name_prefix: inputValue,
          entity_categories: [entityCategory],
          must_conds: [
            ...sharedConditionsArray,
            additionalConditions,
            alertCategoryData,
          ].filter((condition) => condition),
          size: 10,
        },
      });
      const suggestions = data?.queryDetectionsEntitiesSuggestions[
        `${entityCategory}_entities`
      ].map((suggestion: string) => {
        return {
          label: suggestion,
          value: suggestion,
        };
      });
      return suggestions;
    };

    const getSuggestions = async (inputValue: string, matchValue: string) => {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: QUERY_DETECTIONS_FIELD_SUGGESTIONS,
        variables: {
          query: wrapSuggestionsQuery(
            `${sharedConditions},{"match_phrase":{"${matchValue}":{"query":"${inputValue}","analyzer":"standard"}}}`,
          ),
          collapse_field: `${matchValue}.keyword`,
          size: 10,
          sort: [
            {
              sort_by: `${matchValue}.keyword`,
              sort_dir: 'asc',
            },
          ],
        },
      });
      const suggestions = data?.queryDetectionsFieldSuggestions?.map(
        (suggestion: string) => {
          return {
            label: suggestion,
            value: suggestion,
          };
        },
      );
      return suggestions;
    };

    useEffect(() => {
      if (refetch_main) {
        if (start && end && variables?.query) {
          refetch();
        }
        setSearchParams((params) => {
          params.delete('refetch_main');
          return params;
        });
      }
    }, [refetch, refetch_main, setSearchParams, start, end, variables]);

    const prevData = usePrevious(data);
    useEffect(() => {
      if (data !== prevData) {
        setDetectionsData(data);
      }
    }, [data, prevData, setDetectionsData]);

    const theme = useTheme();
    const ref = createRef<HTMLDivElement>();

    useOutsideClick({
      ref: ref,
      handler: () => setSeverityRangeIsOpen(false),
    });

    const currentUser = listUsers.filter(
      (investUser) => user?.username === investUser?.username,
    )?.[0];

    const updatedListUsers = useMemo(
      () =>
        [...listUsers]
          .filter(
            (user) =>
              user.status !== 'invited' &&
              currentUser?.username !== user?.username,
          )
          ?.sort((a, b) => a.alias.localeCompare(b.alias))
          .map((user) => {
            return {
              label: user.alias,
              value: user.username,
            };
          }),
      [listUsers, currentUser],
    );

    const usersList = [
      { label: unassigned, value: 'unassigned' },
      { label: assignedToMe, value: currentUser?.username },
      ...updatedListUsers,
    ];

    const filteredCategory = categorySuggestions?.map((category) => {
      return {
        label: category.label,
        options: category.options?.filter((option) => {
          return !formData?.alertCategory.some((category) => {
            return (
              option.label === category.label && option.value === category.value
            );
          });
        }),
      };
    });

    const filteredSource = sourceSuggestions?.map((source) => {
      return {
        label: source.label,
        options: source.options?.filter((option) => {
          return !formData?.source.some((source) => {
            return (
              option.label === source.label && option.value === source.value
            );
          });
        }),
      };
    });

    const filteredDestination = destinationSuggestions?.map((destination) => {
      return {
        label: destination.label,
        options: destination.options?.filter((option) => {
          return !formData?.destination.some((destination) => {
            return (
              option.label === destination.label &&
              option.value === destination.value
            );
          });
        }),
      };
    });

    return (
      <VStack pb={4} as="form" alignItems="start">
        <HStack px={2} spacing={2} paddingLeft={0} flexWrap="wrap">
          <Box ref={ref}>
            <VStack alignItems="start">
              <Controller
                name="severityRange"
                control={control}
                render={({ field: { name, value, onChange } }) => {
                  const [low, high] = formSeverityRange
                    ? [formSeverityRange.low, formSeverityRange.high]
                    : [1, 10];
                  const displayRange = `${low} - ${high}`;
                  return (
                    <Popover
                      placement="bottom-start"
                      isOpen={severityRangeIsOpen}
                      onClose={() => onChange(`[${low}, ${high}]`)}
                    >
                      <PopoverTrigger>
                        <Box
                          tabIndex={0}
                          w="fit-content"
                          border="solid 1px grey"
                          borderRadius="base"
                          cursor="pointer"
                          bg={
                            value === null
                              ? 'layer.0.active'
                              : transparentize(
                                  getColor(theme, 'blue.500'),
                                  0.1,
                                )(theme)
                          }
                          borderColor={'border.1'}
                          py={1}
                          px={2}
                          position="relative"
                          role="combobox"
                          aria-label={severityScore}
                          data-testid={`detection-severity-range-trigger`}
                          onClick={(e) => {
                            e.preventDefault();
                            setSeverityRangeIsOpen(!severityRangeIsOpen);
                          }}
                        >
                          <HStack spacing={1}>
                            <span data-testid={`detection-severity-range`}>
                              {severityScore}
                              {formSeverityRange && `: ${displayRange}`}
                            </span>
                            {formSeverityRange ? (
                              <CloseButton
                                cursor="inherit"
                                boxSize={5}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setValue(
                                    'severityRange',
                                    defaultFilterValues.severityRange,
                                  );
                                  setFormSeverityRange(null);
                                }}
                                data-testid={`detection-severity-range-clear`}
                              >
                                <MuiIcon size="sm">close</MuiIcon>
                              </CloseButton>
                            ) : (
                              <MuiIcon>keyboard_arrow_down</MuiIcon>
                            )}
                          </HStack>
                        </Box>
                      </PopoverTrigger>
                      {
                        <PopoverContent>
                          <VStack spacing={2} paddingTop={2}>
                            <Box minWidth="100%" paddingX={2}>
                              <Flex w="100%" justifyContent="space-between">
                                <SeverityScore
                                  score={1}
                                  size="sm"
                                  isButton={false}
                                  customStyles={{
                                    padding: 0,
                                    width: '30px',
                                    height: '30px',
                                  }}
                                >
                                  1
                                </SeverityScore>
                                <SeverityScore
                                  score={10}
                                  size="sm"
                                  isButton={false}
                                  customStyles={{
                                    padding: 0,
                                    width: '30px',
                                    height: '30px',
                                  }}
                                >
                                  10
                                </SeverityScore>
                              </Flex>
                              <Flex w="100%" padding={2}>
                                <RangeSlider
                                  min={1}
                                  max={10}
                                  step={1}
                                  name={name}
                                  value={[low, high]}
                                  onChange={(selectedValues) => {
                                    setFormSeverityRange({
                                      low: selectedValues[0],
                                      high: selectedValues[1],
                                    });
                                  }}
                                >
                                  <RangeSliderTrack bg="text.secondary">
                                    <RangeSliderFilledTrack bg="blue.500" />
                                  </RangeSliderTrack>
                                  <RangeSliderThumb
                                    bg="blue.500"
                                    boxSize={3.5}
                                    index={0}
                                  />
                                  <RangeSliderThumb
                                    bg="blue.500"
                                    boxSize={3.5}
                                    index={1}
                                  />
                                </RangeSlider>
                              </Flex>
                            </Box>
                            <HStack padding={2}>
                              <NumberInput
                                min={1}
                                max={
                                  formSeverityRange?.high
                                    ? formSeverityRange?.high - 1
                                    : 9
                                }
                                data-testid="detection-severity-range-min-input"
                                value={low}
                                name="severityRange"
                                label={min}
                                onChange={(value) => {
                                  let setNumber = Number(value);
                                  if (Number.isNaN(setNumber)) {
                                    setNumber = 1;
                                  }
                                  setFormSeverityRange({
                                    low: setNumber,
                                    high,
                                  });
                                }}
                              />
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                paddingTop={6}
                              >
                                <Text>-</Text>
                              </Flex>
                              <NumberInput
                                min={
                                  formSeverityRange?.low
                                    ? formSeverityRange?.low + 1
                                    : 2
                                }
                                max={10}
                                data-testid="detection-severity-range-max-input"
                                name="severityRange"
                                label={max}
                                value={high}
                                onChange={(value) => {
                                  let setNumber = Number(value);
                                  if (Number.isNaN(setNumber)) {
                                    setNumber = 10;
                                  }
                                  setFormSeverityRange({
                                    low,
                                    high: setNumber,
                                  });
                                }}
                              />
                            </HStack>
                          </VStack>
                          <PopoverFooter>
                            <Flex justifyContent="space-between">
                              <Button
                                variant="link"
                                size="xs"
                                onClick={() => {
                                  setFormSeverityRange(null);
                                  onChange(null);
                                }}
                              >
                                {clearAll}
                              </Button>
                              <ButtonGroup variant="solid" size="xs">
                                <Button
                                  colorScheme="gray"
                                  onClick={() => {
                                    setSeverityRangeIsOpen(
                                      !severityRangeIsOpen,
                                    );
                                  }}
                                >
                                  {cancel}
                                </Button>
                                <Button
                                  colorScheme="blue"
                                  onClick={() => {
                                    onChange(`[${low}, ${high}]`);
                                    setSeverityRangeIsOpen(
                                      !severityRangeIsOpen,
                                    );
                                  }}
                                >
                                  {apply}
                                </Button>
                              </ButtonGroup>
                            </Flex>
                          </PopoverFooter>
                        </PopoverContent>
                      }
                    </Popover>
                  );
                }}
              />
            </VStack>
          </Box>
          <Box>
            <Controller
              name="status"
              control={control}
              render={({ field: { value } }) => {
                return (
                  <Combobox
                    category={statusLabel}
                    data-testid="detection-status"
                    value={value}
                    isSearchable={false}
                    options={[
                      { label: open, value: 'open' },
                      { label: closed, value: 'closed' },
                    ]}
                    onChange={(selectedValue) => {
                      setValue('status', selectedValue);
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              name={'alertCategory'}
              control={control}
              render={({ field: { value } }) => {
                return (
                  <AsyncCombobox
                    data-testid="detection-alert-category-async"
                    category={alertCategory}
                    value={value}
                    // @ts-ignore
                    onChange={(selectedValue: SelectOption[]) => {
                      setValue('alertCategory', selectedValue);
                      const suggestions =
                        categorySuggestions?.[0]?.options?.length > 0
                          ? uniqWith(
                              [
                                ...selectedValue,
                                ...categorySuggestions[0]?.options,
                              ],
                              isEqual,
                            )
                          : selectedValue;
                      if (suggestions.length > 10) {
                        suggestions.pop();
                      }
                      setCategorySuggestions([
                        {
                          label: 'Recent Searches',
                          options: suggestions,
                        },
                      ]);
                      localStorage.setItem(
                        'category_suggestions',
                        JSON.stringify([
                          {
                            label: 'Recent Searches',
                            options: suggestions,
                          },
                        ]),
                      );
                    }}
                    options={[]}
                    placeholder={searchCategory}
                    loadOptions={async (event) => {
                      const loadedOptions = await getSuggestions(
                        event.length > 20 ? event.slice(0, 20) : event,
                        'alert_info.alert_name',
                      );
                      return loadedOptions;
                    }}
                    // @ts-ignore
                    defaultOptions={filteredCategory}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              name="alertType"
              control={control}
              render={({ field: { value } }) => {
                return (
                  <Combobox
                    category={alertType}
                    data-testid="detection-type"
                    value={value}
                    isSearchable={false}
                    options={[
                      { label: stringifyAlertType('ml'), value: 'ml' },
                      { label: stringifyAlertType('notice'), value: 'notice' },
                      {
                        label: stringifyAlertType('custom_search_rule'),
                        value: 'custom_search_rule',
                      },
                      {
                        label: stringifyAlertType('suricata_corelight'),
                        value: 'suricata_corelight',
                      },
                      {
                        label: stringifyAlertType('yara_corelight'),
                        value: 'yara_corelight',
                      },
                      {
                        label: stringifyAlertType('anomaly'),
                        value: 'anomaly',
                      },
                    ]}
                    onChange={(selectedValue) => {
                      setValue('alertType', selectedValue);
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              name={'source'}
              control={control}
              render={({ field: { value } }) => {
                return (
                  <AsyncCombobox
                    category={sourceText}
                    data-testid="detection-alert-entity-source-async"
                    value={value}
                    // @ts-ignore
                    onChange={(selectedValue: SelectOption[]) => {
                      setValue('source', selectedValue);
                      const suggestions =
                        sourceSuggestions[0]?.options?.length > 0
                          ? uniqWith(
                              [
                                ...selectedValue,
                                ...sourceSuggestions[0]?.options,
                              ],
                              isEqual,
                            )
                          : selectedValue;
                      if (suggestions.length > 10) {
                        suggestions.pop();
                      }
                      setSourceSuggestions([
                        {
                          label: 'Recent Searches',
                          options: suggestions,
                        },
                      ]);
                      localStorage.setItem(
                        'source_suggestions',
                        JSON.stringify([
                          {
                            label: 'Recent Searches',
                            options: suggestions,
                          },
                        ]),
                      );
                    }}
                    label={sourceText}
                    placeholder={searchSource}
                    options={[]}
                    loadOptions={async (event) => {
                      const loadedOptions = await getEntitiesSuggestions(
                        event.length > 20 ? event.slice(0, 20) : event,
                        'source',
                      );
                      return loadedOptions;
                    }}
                    // @ts-ignore
                    defaultOptions={filteredSource}
                    noOptionsMessage={
                      <Box px={2.5} textAlign="left">
                        <Text mb={2.5} textStyle="body-md" fontWeight={600}>
                          {noResults}
                        </Text>
                        <Text textStyle="body-md">{adjustSearch}</Text>
                      </Box>
                    }
                    components={{
                      DropdownIndicator: null,
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              name={'destination'}
              control={control}
              render={({ field: { value } }) => {
                return (
                  <AsyncCombobox
                    category={destinationText}
                    data-testid="detection-alert-entity-destination-async"
                    value={value}
                    // @ts-ignore
                    onChange={(selectedValue: SelectOption[]) => {
                      setValue('destination', selectedValue);
                      const suggestions =
                        destinationSuggestions[0]?.options?.length > 0
                          ? uniqWith(
                              [
                                ...selectedValue,
                                ...destinationSuggestions[0]?.options,
                              ],
                              isEqual,
                            )
                          : selectedValue;
                      if (suggestions.length > 10) {
                        suggestions.pop();
                      }
                      setDestinationSuggestions([
                        {
                          label: 'Recent Searches',
                          options: suggestions,
                        },
                      ]);
                      localStorage.setItem(
                        'destination_suggestions',
                        JSON.stringify([
                          {
                            label: 'Recent Searches',
                            options: suggestions,
                          },
                        ]),
                      );
                    }}
                    label={destinationText}
                    placeholder={searchDestination}
                    options={[]}
                    loadOptions={async (event) => {
                      const loadedOptions = await getEntitiesSuggestions(
                        event.length > 20 ? event.slice(0, 20) : event,
                        'destination',
                      );
                      return loadedOptions;
                    }}
                    // @ts-ignore
                    defaultOptions={filteredDestination}
                    noOptionsMessage={
                      <Box px={2.5} textAlign="left">
                        <Text mb={2.5} textStyle="body-md" fontWeight={600}>
                          {noResults}
                        </Text>
                        <Text textStyle="body-md">{adjustSearch}</Text>
                      </Box>
                    }
                    components={{
                      DropdownIndicator: null,
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box>
            <Controller
              name={'assignee'}
              control={control}
              render={({ field: { value } }) => {
                return (
                  <Combobox
                    data-testid="detection-assignee"
                    value={value}
                    options={usersList as SelectOption[]}
                    category={assigneeLabel}
                    onChange={(selectedValue) => {
                      setValue('assignee', selectedValue);
                    }}
                    placeholder={searchAssignee}
                  />
                );
              }}
            />
          </Box>
          {filtersChanged() && (
            <Button
              variant="ghost"
              colorScheme="gray"
              leftIcon={<RefreshWithCircleIcon boxSize={4} />}
              size="sm"
              my={-1}
              onClick={onFilterClear}
            >
              {reset}
            </Button>
          )}
        </HStack>
      </VStack>
    );
  },
);
