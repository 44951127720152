import {
  Box,
  Center,
  chakra,
  Flex,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  useDisclosure,
  useMultiStyleConfig,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import {
  MouseEvent,
  ReactNode,
  useContext,
  useId,
  useMemo,
  useRef,
} from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { SidebarContext } from '../../Sidebar';

export interface PopUpMenuLinksProps {
  /** The prop that sets the path to redirect a user to when they click on a popover menu item */
  to: string;
  /** The prop that sets the text in the popover menu item */
  label: string;
  /** The prop that sets the icon in the popover menu item */
  icon?: ReactNode;
  /** The prop that is used to mark the current active path by checking if it is included in the current URL. */
  rootPath?: string;
  /** The prop that sets a divider above a menu item */
  hasDivider?: boolean;
  /** The prop that sets chakra styling (i.e isDisabled) on the menu item */
  menuItemProps?: MenuItemProps;
  /** The prop that sets whether the menu item should be displayed */
  isHidden?: boolean;
  /** Renders element after label text */
  afterLabel?: ReactNode;
}

export interface SidebarListProps {
  /** The prop that sets the label text which is seen when the menu is expanded. */
  label: string;
  /** The prop that sets the icon to be shown (regardless of the the menu's expansion state) to represent the sidebar list */
  icon: ReactNode;
  /** The prop that sets the disabled state of the sidebar list */
  isDisabled?: boolean;
  /** The prop that sets the base value of the data-testid attributes set on the various elements that make up the sidebar list component */
  ['data-testid']?: string;
  /** The prop that sets the links to be shown in the popover menu when the sidebar list is hovered over */
  links: PopUpMenuLinksProps[];
  /** The prop that sets the width of the sidebar list */
  width?: string;
  /** The prop that sets the height of the sidebar list */
  height?: string;
  /** The prop that sets the sidebar header to be shown above the list */
  header?: ReactNode;
  /** The prop that sets the loading state of the sidebar list */
  isLoading?: boolean;
  /** The prop that sets the component to be shown if the list is empty */
  emptyList?: ReactNode;
  /** The prop that custom handles the clicked link in-app */
  handleLinkOnClick?: (e: string) => void;
  /** The prop that that custom handles the clicked link in new tab */
  onContextMenu?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const StyledLink = chakra(Link);

export const SidebarList = ({
  icon,
  label,
  links,
  header,
  emptyList,
  width = '220px',
  height = 'auto',
  isLoading = false,
  isDisabled = false,
  handleLinkOnClick,
  onContextMenu,
  'data-testid': dataTestId,
  ...rest
}: SidebarListProps) => {
  const {
    isOpen: isSidebarOpen,
    autoCloseSidebar,
    setIsOpen,
  } = useContext(SidebarContext);

  const { pathname } = useLocation();

  const someLinkPathMatchCurrentPath = useMemo(
    () =>
      links?.some(({ to, rootPath }) =>
        matchPath((rootPath && `${rootPath}/*`) ?? to, pathname),
      ),
    [pathname, links],
  );

  const styles = useMultiStyleConfig('SidebarItem', {
    expanded: isSidebarOpen,
    match: someLinkPathMatchCurrentPath,
    isDisabled,
  });

  const {
    isOpen: isPopUpMenuOpen,
    onOpen: onOpenPopUpMenu,
    onClose: onClosePopUpMenu,
  } = useDisclosure();

  const opener = useRef<HTMLButtonElement | null>(null);

  const id = useId();

  return (
    <li>
      <Popover
        isOpen={isPopUpMenuOpen}
        closeOnBlur
        placement="right-start"
        onOpen={onOpenPopUpMenu}
        onClose={onClosePopUpMenu}
      >
        <PopoverTrigger>
          <Box
            __css={styles.container}
            data-testid={dataTestId}
            as="button"
            aria-haspopup="menu"
            display="block"
            width="100%"
            ref={opener}
            onClick={() => {
              onOpenPopUpMenu();
            }}
            onFocus={() => {
              onClosePopUpMenu();
            }}
          >
            <Box {...rest} __css={styles.link} display="flex" gap={2}>
              {icon ? (
                <Center data-testid={`${dataTestId}-icon`} __css={styles.icon}>
                  {icon}
                </Center>
              ) : undefined}
              {((children) =>
                isSidebarOpen ? (
                  children
                ) : (
                  <VisuallyHidden>{children}</VisuallyHidden>
                ))(
                <chakra.span w="100%" overflow="hidden">
                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text
                      textStyle="body-md"
                      data-testid={`${dataTestId}-label`}
                      id={id}
                    >
                      {label}
                    </Text>
                    <MuiIcon>keyboard_arrow_Right</MuiIcon>
                  </Flex>
                </chakra.span>,
              )}
            </Box>
          </Box>
        </PopoverTrigger>
        {!isDisabled && isPopUpMenuOpen && (
          <PopoverContent
            ref={(el) => {
              if (el) {
                el.setAttribute('role', 'menu'); // since PopoverContent doesn't respect its role prop
                el.setAttribute('aria-labelledby', id); // ...or aria-labelledby
                const firstNavItem = el.querySelector('a');
                if (firstNavItem) {
                  firstNavItem.focus();
                }
              }
            }}
          >
            <Menu isOpen={isPopUpMenuOpen}>
              <VStack
                w={width}
                h={height}
                spacing={0}
                overflowY="auto"
                alignItems="stretch"
                _last={{ pb: 1 }}
                _first={{ pt: !header ? 1 : 0 }}
                data-testid={`${dataTestId}-links-container`}
              >
                {header && header}
                {isLoading ? (
                  <Center>
                    <Box py={4}>
                      <Spinner />
                    </Box>
                  </Center>
                ) : links?.length > 0 ? (
                  links?.map(
                    (
                      {
                        to,
                        icon,
                        label,
                        rootPath,
                        menuItemProps,
                        isHidden = false,
                        hasDivider = false,
                        afterLabel,
                      },
                      index,
                    ) => {
                      const currentPathIncludesRootPath =
                        rootPath && pathname?.includes(rootPath);

                      const currentPathMatchesToPath = matchPath(to, pathname);

                      const isPathActive =
                        currentPathMatchesToPath || currentPathIncludesRootPath;

                      return (
                        <StyledLink
                          role="menuitem"
                          borderRadius={5}
                          onClick={(e) => {
                            onClosePopUpMenu();
                            if (handleLinkOnClick) {
                              e.preventDefault();
                              handleLinkOnClick(e.currentTarget.pathname);
                            } else {
                              autoCloseSidebar && setIsOpen(false);
                            }
                          }}
                          onContextMenu={(e) => {
                            if (onContextMenu) {
                              onContextMenu(e);
                            }
                          }}
                          key={index}
                          to={to}
                          data-testid={`${dataTestId}-link-${index}`}
                        >
                          {hasDivider && <MenuDivider />}
                          <Box
                            __css={{
                              ...(isPathActive && {
                                ...styles?.container,
                              }),
                            }}
                          >
                            <MenuItem
                              as="div"
                              ref={(el) => {
                                el?.removeAttribute('role');
                              }}
                              color={isPathActive ? 'text.link' : 'inherit'}
                              {...menuItemProps}
                              css={{
                                '&&': { backgroundColor: 'transparent' },
                                ...(isPathActive
                                  ? undefined
                                  : {
                                      '&&:hover': {
                                        backgroundColor:
                                          'var(--chakra-colors-layer-2)',
                                      },
                                    }),
                              }}
                              icon={
                                icon ? (
                                  <div
                                    style={{ display: 'contents' }}
                                    aria-hidden
                                  >
                                    {icon}
                                  </div>
                                ) : undefined
                              }
                              display={isHidden ? 'none' : 'flex'}
                            >
                              <Flex gap={1}>
                                <Text data-testid={`${dataTestId}-link-label`}>
                                  {label}
                                </Text>
                                {afterLabel}
                              </Flex>
                            </MenuItem>
                          </Box>
                        </StyledLink>
                      );
                    },
                  )
                ) : (
                  emptyList && emptyList
                )}
              </VStack>
            </Menu>
          </PopoverContent>
        )}
      </Popover>
    </li>
  );
};
