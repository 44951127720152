import { Box } from '@chakra-ui/react';
import { CTAButton } from '@gamma/buttons';
import { IPasswordResetForm, PasswordResetForm } from '@gamma/forms';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall, useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  useAcceptEulaTC,
  useActivateUserAccount,
} from '@gamma/investigator/queries';
import { ErrorBoundary } from '@gamma/overlay';
import {
  CardPageBody,
  CardPageCTA,
  CardPageHeading,
  CardPageIllustration,
  CardPageSubtitle,
} from '@gamma/pages';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { IRegistrationContext } from '../Registration';

export const SetPassword = () => {
  const { signOut } = useUserSession();

  const navigate = useNavigate();
  const { user: userContext, setRouteAccessAllowed } =
    useOutletContext<IRegistrationContext>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const logOperationCall = useLogOperationCall();
  const { setUser } = useContext(AuthContext);
  const [acceptEulaTC] = useAcceptEulaTC();

  const [
    activateUserAccount,
    { loading: activateUserLoading, error: activateUserError },
  ] = useActivateUserAccount();

  const handlePasswordResetSubmission = async ({
    newPassword,
  }: IPasswordResetForm) => {
    try {
      setIsLoading(true);
      const user = await Auth.signIn(
        userContext.username,
        userContext.password,
      );

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, newPassword);
        logOperationCall('NEW_PASSWORD_UPDATED', 'USER');
        acceptEulaTC().catch((err) => {
          console.log(err);
        });
        logOperationCall('PRIVACY_POLICY_ACKNOWLEDGED', 'USER');
        activateUserAccount();
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser?.(currentUser);
        if (currentUser?.attributes['custom:allow_no_mfa']) {
          navigate('../../');
        } else {
          setRouteAccessAllowed(true);
          navigate('../mfa', { replace: true });
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error('Password reset error: ', e);
    }
  };
  const { title, subtitle, cta } = i18n.pages.registration.setPassword;

  return (
    <ErrorBoundary page="Registration Set Password Page">
      <Box data-testid="set-password-card">
        <CardPageHeading>{title}</CardPageHeading>
        <CardPageIllustration illustration="LockedShield" />
        <CardPageSubtitle>{subtitle}</CardPageSubtitle>
        <CardPageBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PasswordResetForm
              onSubmit={[handlePasswordResetSubmission]}
              newPasswordContent={{
                placeholder: i18n.forms.labels.newPassword,
                label: i18n.forms.labels.newPassword,
              }}
              confirmPasswordContent={{
                placeholder: i18n.forms.labels.confirmPassword,
                label: i18n.forms.labels.confirmPassword,
              }}
              autoFocusField="newPassword"
            />
          )}
        </CardPageBody>
      </Box>
      <CardPageCTA>
        <CTAButton type="submit" form="passwordResetForm" isLoading={isLoading}>
          {cta}
        </CTAButton>
      </CardPageCTA>
      {(activateUserLoading || activateUserError) && (
        <GraphQLReqStatus
          loading={activateUserLoading}
          error={activateUserError}
          isBackground={true}
          signOut={signOut}
          extended={graphqlErrorRedirects}
        />
      )}
    </ErrorBoundary>
  );
};
