import { useQuery } from '@apollo/client';
import {
  Box,
  Center,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';

import {
  IQueryAlertsPaginated,
  QUERY_ALERTS_ANOMALY_SUMMARY,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { SanitizedAnomalyAlert } from '../..';
import { InvestigateLogsTableModal } from '../InvestigateLogsTableModal';
import { LogscaleDetails } from '../LogscaleDetails';
import { LogscaleMenuButton } from '../LogscaleMenuButton';
import { SessionSummary } from '../SessionSummary';
const { alerts, detection } = i18n.pages.detections;

interface AnomalyButtonsColProps {
  item: SanitizedAnomalyAlert;
  logScaleQuery?: string;
  showContent?: boolean;
  loading?: boolean;
}

export const AnomalyButtonsCol = ({
  item,
  logScaleQuery,
  showContent,
}: AnomalyButtonsColProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formattedTime = moment.unix(item.alert_timestamp?.observed).format();
  const start = moment(formattedTime).subtract(1, 'hours').unix();
  const end = moment(formattedTime).add(24, 'hours').unix();

  const searchQueryString = `?query=${
    logScaleQuery
      ? encodeURIComponent(logScaleQuery)
      : `uid%3D${item.uid}%20OR%20(%23path%3Danomaly%20AND%20alert.name%3D%22${encodeURIComponent(
          item.alert_info?.alert_name,
        )}%22)`
  }&live=false&humioStart=${start * 1000}&humioEnd=${
    end * 1000
  }&fullscreen=false&widgetType=table-view&newestAtBottom=true&showOnlyFirstLine=false&start=${start}&end=${end}`;

  return (
    <Flex flexGrow={1}>
      <LogscaleMenuButton
        searchQueryString={searchQueryString}
        investigateModalOnOpen={onOpen}
        showContent={showContent}
      />
      <InvestigateLogsTableModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        alertsName={item.alert_info?.alert_name}
        alertsUid={item.uid}
        alertsTimestamp={formattedTime}
        alertsTenant={item.tenant}
        logScaleQuery={logScaleQuery}
      />
    </Flex>
  );
};

interface AlertRowAnomalyProps {
  item: SanitizedAnomalyAlert;
  logScaleQuery?: string;
  showContent?: boolean;
}

export const AlertRowAnomaly = ({
  item,
  logScaleQuery,
  showContent,
}: AlertRowAnomalyProps) => {
  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
        <HStack color="text.200">
          <Text textStyle="body-md">{item?.alert_info?.alert_name}</Text>
          {item?.alert_entity ? (
            <>
              <Text textStyle="body-md">|</Text>
              <Text textStyle="body-md">{item?.alert_entity?.entity_name}</Text>
            </>
          ) : null}
        </HStack>
      </VStack>
      <AnomalyButtonsCol
        item={item}
        logScaleQuery={logScaleQuery}
        showContent={showContent}
      />
    </HStack>
  );
};

interface AlertRowSplitAnomalyProps {
  item: SanitizedAnomalyAlert;
  loading: boolean | undefined;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
  getAppConfigurationsData: string | null;
  detectionData: any;
  isGPTPrivateDataEnabled: boolean;
  logScaleQuery?: string;
}

export const AlertRowSplitAnomaly = ({
  item,
  loading,
  setDownloadUrl,
  getAppConfigurationsData,
  detectionData,
  isGPTPrivateDataEnabled,
  logScaleQuery,
}: AlertRowSplitAnomalyProps) => {
  const [sessionSummaryData, setSessionSummaryData] = useState<any>(null);

  const time = moment.unix(item.alert_timestamp?.observed);
  const formattedTime = time.format();

  const isLLMSummary = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags?.llm_summary
    : false;

  const query = QUERY_ALERTS_ANOMALY_SUMMARY;

  if (loading) {
    return (
      <Center w="100%">
        <GraphQLReqStatus loading={loading} />
      </Center>
    );
  }

  const noFilterVariables = {
    query: `{"query": {"bool": {"must": [
      {"term": {"alert_info.content_id": "${detectionData.alert_info?.content_id}"}},
      {"term": {"alert_entity.entity_id": "${detectionData.alert_entity?.entity_id}"}},
      {"range": {"alert_timestamp.end": {"gte": ${item.alert_timestamp.observed}}}},
      {"range": {"alert_timestamp.start": {"lte": ${item.alert_timestamp.observed}}}}
      ${detectionData.tenant_info?.tenant_id ? ',' : ''}${
        detectionData.tenant_info?.tenant_id
          ? `{"terms":{"tenant":["${detectionData.tenant_info?.tenant_id}"]}}`
          : ''
      }
    ]}}}`,
    log_search_client_session_id:
      localStorage.getItem('session_id_token') || '',
  };

  const { loading: sessionSummaryLoading } = useQuery<IQueryAlertsPaginated>(
    query,
    {
      skip:
        !detectionData ||
        !detectionData?.tenant_info?.tenant_id ||
        !detectionData?.alert_entity?.entity_id,
      variables: noFilterVariables,
      onCompleted: (data) => {
        const sessionSummary =
          data?.queryAlertsPaginated?.alerts?.[0]?.session_summary;
        setSessionSummaryData(sessionSummary);
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );

  return (
    <VStack mb={2} w="100%" alignItems="start">
      <Box w="100%">
        <AccordionPanel
          title={detection.alertDetails}
          data-testid="anomaly-alerts-wrapper"
        >
          <DetailsGrid>
            <DetailsGridRow title={alerts.name}>
              <Text overflowWrap="anywhere">
                {item?.alert_info?.alert_name}
              </Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.timestamp}>
              <Text>{formattedTime}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.uid}>
              <Text>{item?.uid}</Text>
            </DetailsGridRow>
            <DetailsGridRow title="Usecase">
              <Text overflowWrap="anywhere">{item?.usecase}</Text>
            </DetailsGridRow>
            <DetailsGridRow title="Anomaly Type">
              <Text overflowWrap="anywhere">{item?.anomaly_type}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.sourceIp}>
              <VStack alignItems="start" spacing={1}>
                {item?.source_entities?.map((source, idx) => (
                  <Text key={idx}>{source?.entity_name}</Text>
                ))}
              </VStack>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.destinationIp}>
              <VStack alignItems="start" spacing={1}>
                {item?.destination_entities?.map((dest, idx) => (
                  <Text key={idx}>{dest?.entity_name}</Text>
                ))}
              </VStack>
            </DetailsGridRow>
            <DetailsGridRow title="Alert Type">
              <Text>{item?.alert_info?.alert_type}</Text>
            </DetailsGridRow>
          </DetailsGrid>

          {isLLMSummary && isGPTPrivateDataEnabled && (
            <SessionSummary
              sessionSummaryData={sessionSummaryData}
              loading={sessionSummaryLoading}
            />
          )}
        </AccordionPanel>
      </Box>
      <LogscaleDetails
        alertsUid={item?.uid}
        alertsName={item?.alert_info?.alert_name}
        alertsTimestamp={formattedTime}
        setDownloadUrl={setDownloadUrl}
        alertsTenant={item?.tenant}
        logScaleQuery={logScaleQuery}
      />
    </VStack>
  );
};
