import { VStack } from '@chakra-ui/react';
import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { ErrorBoundary } from '@gamma/overlay';
import { NavTab, NavTabs } from '@gamma/tabs';
import { Outlet, useLocation } from 'react-router-dom';
import { MoreDashboards } from './HumioDashboardOverview';

const navigation = i18n.navigation;

export const Overview = () => {
  const { pathname } = useLocation();

  const tabs: NavTab[] = [
    {
      title: navigation.securityOverview,
      to: ROUTES.home,
      isDisabled: false,
      'data-testid': 'security-overview-tab',
    },
    {
      title: 'Threat Hunting',
      to: ROUTES.threatHunting,
      isDisabled: false,
      onClick: () => {
        const iframe = document.getElementById('humio-ui');
        if (iframe instanceof HTMLIFrameElement) {
          iframe.src = iframe.src; // eslint-disable-line no-self-assign
        }
      },
      'data-testid': 'threat-hunting-tab',
    },
    {
      title: navigation.networkOverview,
      to: ROUTES.networkOverview,
      isDisabled: false,
      onClick: () => {
        const iframe = document.getElementById('humio-ui');
        if (iframe instanceof HTMLIFrameElement) {
          iframe.src = iframe.src; // eslint-disable-line no-self-assign
        }
      },
      'data-testid': 'network-overview-tab',
    },
    {
      title: navigation.securityPosture,
      to: ROUTES.securityPosture,
      isDisabled: false,
      onClick: () => {
        const iframe = document.getElementById('humio-ui');
        if (iframe instanceof HTMLIFrameElement) {
          iframe.src = iframe.src; // eslint-disable-line no-self-assign
        }
      },
      'data-testid': 'security-posture-tab',
    },
    {
      title: <MoreDashboards />,
      to: ROUTES.moreOverview,
      isDisabled: true,
      'data-testid': 'more-dashboards-tab',
    },
  ];
  return (
    <ErrorBoundary page="Overview" styleClass="main">
      <Board h="100%" alignContent="start" data-testid="overview">
        <Column w="100%" data-testid="overview-header">
          <PageHeader
            showDateSelection={pathname.includes(ROUTES.home) ? true : false}
          >
            {i18n.navigation.dashboardOverview}
          </PageHeader>
          <VStack>
            <NavTabs tabs={tabs} isFitted={false} variant="line" basePath="/" />
          </VStack>
        </Column>
        <Column p={0} height="calc(100% - 110px)">
          <Outlet />
        </Column>
      </Board>
    </ErrorBoundary>
  );
};
