import { ApolloError } from '@apollo/client';
import {
  Box,
  Circle,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import {
  useDateRangeQueryString,
  useUserSession,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { IGetDetectionMitreSummary } from '@gamma/investigator/queries';
import { Panel, PanelHeader, PanelHeading } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';

import { DetectionsByCategoryPopoverContent } from './DetectionsByCategoryPopoverContent';

interface DetectionsByCategoryPanelProps {
  loading: boolean;
  error?: ApolloError;
  data?: IGetDetectionMitreSummary;
}

export function DetectionsByCategoryPanel({
  loading,
  error,
  data,
}: DetectionsByCategoryPanelProps) {
  const { signOut } = useUserSession();

  const { start, end } = useDateRangeQueryString();

  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          signOut={signOut}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }

  const alertMitreSummary = data?.getDetectionMitreSummary || [];

  const colors = [
    '#2C7A7B',
    '#1F7F89',
    '#118497',
    '#0E83A2',
    '#1B78A9',
    '#286EAF',
    '#3F60B5',
    '#5752BC',
    '#7345BD',
    '#9C43A9',
    '#C54094',
    '#D93F7A',
    '#DF3E5C',
    '#C53030',
  ];

  const { includesOpen } = i18n.pages.securityOverview;
  const { heading } = i18n.pages.securityOverview.panels.detectionsByCategory;

  const tacticSize = alertMitreSummary.length > 6 && { fontSize: '13px' };

  return (
    <Panel>
      <PanelHeader divider>
        <PanelHeading>
          <HStack>
            <Text>{heading}</Text>
            <Tooltip label={includesOpen} shouldWrapChildren>
              <MuiIcon
                color="text.secondary"
                data-testid="mitre-attack-map-info-label"
              >
                info
              </MuiIcon>
            </Tooltip>
          </HStack>
        </PanelHeading>
      </PanelHeader>
      <Flex mb={5} wrap="wrap">
        {alertMitreSummary.length
          ? alertMitreSummary.map((tactic, index: number) => (
              <Flex alignItems="center" mb={0.5} key={index}>
                <Circle
                  mr={3}
                  size={2}
                  data-testid="mitre-category-key-item"
                  bg={colors[tactic.mitre_attack_tactic_priority]}
                />
                <Text mr={3} textStyle="body-md">
                  {tactic.mitre_attack_tactic}
                </Text>
              </Flex>
            ))
          : i18n.pages.securityOverview.na}
      </Flex>
      <Flex alignItems={'flex-start'}>
        {alertMitreSummary.map((tactic, colIndex: number) => {
          return (
            <Flex
              flex={1}
              gap={0.5}
              key={colIndex}
              overflow="hidden"
              direction="column"
              data-testid="mitre-tactic"
              maxW={`${100 / alertMitreSummary.length}%`}
            >
              {tactic.mitre_attack_techniques.map(
                (technique, index: number) => (
                  <Popover
                    isLazy
                    key={index}
                    trigger="hover"
                    openDelay={300}
                    placement={'right'}
                  >
                    <PopoverTrigger>
                      <Flex
                        flex="auto"
                        key={index}
                        color="gray.50"
                        borderRadius="sm"
                        direction="column"
                        overflow="hidden"
                        position="relative"
                        justifyContent="space-between"
                        data-testid="mitre-technique"
                        border="1px solid rgba(255,255,255, 0.2)"
                        bg={colors[tactic.mitre_attack_tactic_priority]}
                        mr={colIndex + 1 !== alertMitreSummary.length ? 2 : 0}
                        h={
                          technique.entity_count > 5
                            ? `${
                                62 +
                                (technique.entity_count <= 100
                                  ? technique.entity_count
                                  : 100) *
                                  1.5
                              }px`
                            : '62px'
                        }
                      >
                        <Flex
                          p={2}
                          flex="auto"
                          direction="column"
                          justifyContent="space-between"
                          bg={colors[tactic.mitre_attack_tactic_priority]}
                        >
                          <Text
                            noOfLines={2}
                            textStyle="body-md"
                            paddingRight={4}
                            position="absolute"
                            w="calc(100% - 16px)"
                            data-testid="technique-entity-name"
                            {...tacticSize}
                          >
                            {technique.mitre_attack_technique}
                          </Text>
                          <Flex>
                            <Box
                              px={0.5}
                              py={1}
                              right={2}
                              bottom={2}
                              minW={'15px'}
                              textAlign="center"
                              borderRadius="base"
                              position="absolute"
                              bg="rgba(255, 255, 255, 0.2)"
                            >
                              <Text
                                data-testid="technique_entity_count"
                                textStyle="body-md"
                              >
                                {technique.entity_count}
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </PopoverTrigger>
                    <PopoverContent
                      w="450px"
                      data-testid="mitre-technique-popover-content"
                    >
                      <DetectionsByCategoryPopoverContent
                        start={start}
                        end={end}
                        tactic={tactic}
                        technique={technique}
                      />
                    </PopoverContent>
                  </Popover>
                ),
              )}
            </Flex>
          );
        })}
      </Flex>
    </Panel>
  );
}
