import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/client';

import {
  EDRConfiguration,
  GetEDRConfigurationResponsePublic,
  GetEDRConfigurationsRequest,
  GetEDRConfigurationsResponse,
  GetEDRConfigurationsResponsePublic,
  IVerifyEDRConfiguration,
} from './types';
import { IStoreEDRConfiguration } from './types';
import { IDeleteEDRConfiguration } from './types';

import {
  GetEDRConfigurationRequest,
  GetEDRConfigurationResponse,
} from './types';

export const DELETE_EDR_CONFIGURATION = gql`
  mutation deleteEDRConfiguration($provider_name: EDRConfigurationType!) {
    deleteEDRConfiguration(provider_name: $provider_name) {
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        client_id
        client_secret
        base_url
      }
    }
  }
`;

export const useDeleteEDRConfiguration = (
  options: MutationHookOptions<IDeleteEDRConfiguration, OperationVariables>,
) => useMutation(DELETE_EDR_CONFIGURATION, options);

export const GET_EDR_CONFIGURATION = gql`
  query getEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $tenant: String
  ) {
    getEDRConfiguration(provider_name: $provider_name, tenant: $tenant) {
      edr_action_enabled
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        client_id
        client_secret
        base_url
      }
      ... on MicrosoftDefenderConfiguration {
        app_id
        app_secret
        defender_tenant_id
      }
      ... on PaloAltoNetworksConfiguration {
        access_key
        bucket
        prefix
        secret_key
      }
    }
  }
`;

export const useGetEDRConfiguration = (
  options: QueryHookOptions<
    GetEDRConfigurationResponse,
    GetEDRConfigurationRequest
  >,
) =>
  useQuery<GetEDRConfigurationResponse, GetEDRConfigurationRequest>(
    GET_EDR_CONFIGURATION,
    options,
  );

export const GET_EDR_CONFIGURATION_PUBLIC = gql`
  query getEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $tenant: String
  ) {
    getEDRConfiguration(provider_name: $provider_name, tenant: $tenant) {
      edr_action_enabled
      provider_name
      enabled
      polling_seconds
    }
  }
`;

export const useGetEDRConfigurationPublic = (
  options: QueryHookOptions<
    GetEDRConfigurationResponsePublic,
    GetEDRConfigurationRequest
  >,
) =>
  useQuery<GetEDRConfigurationResponsePublic, GetEDRConfigurationRequest>(
    GET_EDR_CONFIGURATION_PUBLIC,
    options,
  );

export const GET_EDR_CONFIGURATIONS = gql`
  query getEDRConfigurations($tenant: String!, $sort: [SortParameterInput]) {
    getEDRConfiguration(tenant: $tenant, sort: $sort) {
      edr_configurations {
        edr_action_enabled
        provider_name
        enabled
        polling_seconds
        ... on CrowdStrikeConfiguration {
          base_url
          client_id
          client_secret
        }
        ... on MicrosoftDefenderConfiguration {
          app_id
          app_secret
          defender_tenant_id
        }
        ... on PaloAltoNetworkConfiguration {
          access_key
          bucket
          prefix
          secret_key
        }
      }
    }
  }
`;

export const useGetEDRConfigurations = (
  options: QueryHookOptions<
    GetEDRConfigurationsResponse,
    GetEDRConfigurationsRequest
  >,
) =>
  useQuery<GetEDRConfigurationsResponse, GetEDRConfigurationsRequest>(
    GET_EDR_CONFIGURATIONS,
    options,
  );

export const GET_EDR_CONFIGURATIONS_PUBLIC = gql`
  query getEDRConfiguration($tenant: String!, $sort: [SortParameterInput]) {
    getEDRConfiguration(tenant: $tenant, sort: $sort) {
      edr_configurations {
        edr_action_enabled
        provider_name
        enabled
        polling_seconds
      }
    }
  }
`;

export const useGetEDRConfigurationsPublic = (
  options: QueryHookOptions<
    GetEDRConfigurationsResponsePublic,
    GetEDRConfigurationsRequest
  >,
) =>
  useQuery<GetEDRConfigurationsResponsePublic>(
    GET_EDR_CONFIGURATIONS_PUBLIC,
    options,
  );

export const STORE_EDR_CONFIGURATION = gql`
  mutation storeEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $config: EDRConfigurationInput!
  ) {
    storeEDRConfiguration(provider_name: $provider_name, config: $config) {
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        client_id
        client_secret
        base_url
      }
    }
  }
`;

export const useStoreEDRConfiguration = (
  options: MutationHookOptions<IStoreEDRConfiguration, OperationVariables>,
) => useMutation(STORE_EDR_CONFIGURATION, options);

export const VERIFY_EDR_CONFIGURATION = gql`
  query verifyEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $config: EDRConfigurationInput!
  ) {
    verifyEDRConfiguration(provider_name: $provider_name, config: $config) {
      verified
    }
  }
`;

export const useVerifyEDRConfiguration = (
  options: LazyQueryHookOptions<IVerifyEDRConfiguration, OperationVariables>,
) => useLazyQuery<IVerifyEDRConfiguration>(VERIFY_EDR_CONFIGURATION, options);

export const LAZY_ANALYST_GET_EDR_CONFIGURATION = gql`
  query getEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $tenant: String
  ) {
    getEDRConfiguration(provider_name: $provider_name, tenant: $tenant) {
      edr_action_enabled
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        edr_action_enabled
      }
    }
  }
`;

export const useLazyAnalystGetEDRConfiguration = (
  options: LazyQueryHookOptions<
    GetEDRConfigurationResponsePublic,
    GetEDRConfigurationRequest
  >,
) =>
  useLazyQuery<GetEDRConfigurationResponsePublic, GetEDRConfigurationRequest>(
    LAZY_ANALYST_GET_EDR_CONFIGURATION,
    options,
  );
