import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import {
  AlertCategoriesDetectionsTable,
  HighRiskEntitiesDetectionsTable,
} from '@gamma/investigator/components';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_DETECTION_MITRE_SUMMARY,
  GET_DETECTION_PERIOD_SUMMARY,
  IGetDetectionMitreSummary,
  IGetDetectionPeriodSummary,
} from '@gamma/investigator/queries';
import {
  Board,
  Column,
  FlushPanelContent,
  Panel,
  PanelHeader,
  PanelHeading,
} from '@gamma/layout';
import { Tab, Tabs } from '@gamma/tabs';
import { BlobProvider } from '@react-pdf/renderer';
import { useContext, useMemo, useState } from 'react';

import { SecurityOverviewPDF } from './PDFComponents';

import {
  CorelightBlogsPanel,
  DetectionsByCategoryPanel,
  EntitiesWithDetectionsPanel,
  TotalDetectionsPanel,
} from './Panels';

const { highestRiskDetections, includesOpen, panels } =
  i18n.pages.securityOverview;

export const SecurityOverview = () => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);
  const { start, end } = useDateRangeQueryString();

  // Query for detection period summary
  const {
    loading: summaryLoading,
    error: summaryError,
    data: summaryData,
  } = useQuery<IGetDetectionPeriodSummary>(GET_DETECTION_PERIOD_SUMMARY, {
    skip: !start || !end || !orgTenantsQueryParam?.length,
    variables: {
      start,
      end,
      prev_start: start - (end - start),
      prev_end: start,
      must_conds: [
        `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
      ],
    },
  });

  // Query for MITRE data
  const {
    data: mitreData,
    loading: mitreLoading,
    error: mitreError,
  } = useQuery<IGetDetectionMitreSummary>(GET_DETECTION_MITRE_SUMMARY, {
    skip: !start || !end || !orgTenantsQueryParam?.length,
    variables: {
      start,
      end,
      must_conds: [
        `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
        '{"term": {"detection_status": "open"}}',
      ],
    },
  });

  // Transform MITRE data only for PDF
  const pdfMitreData = useMemo(() => {
    return (mitreData?.getDetectionMitreSummary || []).map((tactic) => ({
      name: tactic.mitre_attack_tactic,
      techniques: tactic.mitre_attack_techniques.map((technique) => ({
        name: technique.mitre_attack_technique,
        count: technique.entity_count,
      })),
    }));
  }, [mitreData]);

  const [tabIndex, setTabIndex] = useState(
    Number(localStorage.getItem('security-overview-active-table') ?? 0),
  );

  const [highRiskData, setHighRiskData] = useState<{
    summaries: {
      severity: number;
      entity_name: string;
      detection_count: number;
      entity_category: string;
      entity_type: string;
      total_detections: number;
      is_custom_severity: boolean;
    }[];
    total_items: number;
  } | null>(null);

  const transformedHighRiskData = useMemo(() => {
    if (!highRiskData) return null;
    return {
      getTopDetectionEntitiesPaginated: {
        summaries: highRiskData.summaries.map((summary) => ({
          ...summary,
          entity_id: summary.entity_name,
          tenant: '',
          tenant_entity: '',
          tenant_info: {
            tenant_id: '',
            tenant_display_name: '',
            __typename: 'TenantInfo',
          },
          first_seen: 0,
          last_seen: 0,
          __typename: 'DetectionSummary',
        })),
        total_items: highRiskData.total_items,
        offset: 0,
        size: 10,
        __typename: 'PaginatedDetectionSummaries',
      },
    };
  }, [highRiskData]);

  // Add back the state
  const [alertCategoriesData, setAlertCategoriesData] = useState<{
    categories: { severity: number; name: string; detectionCount: number }[];
    totalItems: number;
  } | null>(null);

  const tabs = useMemo(
    () => [
      {
        title: panels.highestRiskEntities.heading,
        content: (
          <div data-testid="highest-risk-entities">
            <HighRiskEntitiesDetectionsTableWithData
              onDataLoad={setHighRiskData}
            />
          </div>
        ),
      },
      {
        title: panels.alertCategories,
        content: (
          <div data-testid="alert-categories">
            <AlertCategoriesDetectionsTable
              onDataLoad={setAlertCategoriesData}
            />
          </div>
        ),
      },
    ],
    [setHighRiskData, setAlertCategoriesData],
  );

  return (
    <Board h="100%" flex="1 1 auto" height="inherit" margin={0} width="auto">
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton
            position="fixed"
            bottom={4}
            right={4}
            zIndex="overlay"
            aria-label="Download PDF"
            icon={<MuiIcon>download</MuiIcon>}
            colorScheme="blue"
            rounded="full"
            size="lg"
            boxShadow="lg"
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="auto">
            <PopoverBody>
              {summaryLoading || mitreLoading ? (
                <Button isLoading width="full">
                  Loading Data...
                </Button>
              ) : summaryError || mitreError ? (
                <Button disabled colorScheme="red" width="full">
                  Error
                </Button>
              ) : summaryData?.getDetectionPeriodSummary &&
                alertCategoriesData &&
                highRiskData &&
                start &&
                end ? (
                <BlobProvider
                  document={
                    <SecurityOverviewPDF
                      data={summaryData}
                      start={start}
                      end={end}
                      highRiskData={transformedHighRiskData}
                      alertCategories={alertCategoriesData.categories}
                      alertCategoriesTotal={alertCategoriesData.totalItems}
                      mitreData={pdfMitreData}
                    />
                  }
                >
                  {({ url, loading: pdfLoading, error: pdfError }) => {
                    if (pdfError) {
                      console.error('PDF generation error:', pdfError);
                      return (
                        <Button
                          disabled
                          colorScheme="red"
                          width="full"
                          title={pdfError.message}
                        >
                          Error generating PDF
                        </Button>
                      );
                    }
                    return (
                      <Button
                        as="a"
                        href={url || '#'}
                        download="security-overview.pdf"
                        isLoading={pdfLoading}
                        disabled={pdfLoading}
                        width="full"
                      >
                        Download Security Overview
                      </Button>
                    );
                  }}
                </BlobProvider>
              ) : (
                <Button disabled width="full">
                  Waiting for data...
                </Button>
              )}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>

      <Column display="flex" alignItems="start" col={{ base: 12, lg: 3.5 }}>
        <Board>
          <Column>
            <Panel>
              <PanelHeader>
                <PanelHeading>
                  <HStack>
                    <Text>{highestRiskDetections}</Text>
                    <Tooltip label={includesOpen} shouldWrapChildren>
                      <MuiIcon
                        color="text.secondary"
                        data-testid="highest-risk-detections-info-label"
                      >
                        info
                      </MuiIcon>
                    </Tooltip>
                  </HStack>
                </PanelHeading>
              </PanelHeader>
              <FlushPanelContent>
                <div data-testid="top-entities-table">
                  <Tabs
                    variant="line"
                    defaultIndex={tabIndex}
                    onChange={(index) => {
                      setTabIndex(index);
                      localStorage.setItem(
                        'security-overview-active-table',
                        String(index),
                      );
                    }}
                  >
                    {tabs.map((tab) => (
                      <Tab key={tab.title} title={tab.title}>
                        <Box pt={2.5}>{tab.content}</Box>
                      </Tab>
                    ))}
                  </Tabs>
                </div>
              </FlushPanelContent>
            </Panel>
          </Column>
          <Column data-testid="corelight-blogs">
            <CorelightBlogsPanel />
          </Column>
        </Board>
      </Column>
      <Column display="flex" alignItems="start" col={{ base: 12, lg: 8.5 }}>
        <Board w="100%" flexDir="column" flex="1 1 auto">
          <Flex w="100%" flexWrap="wrap">
            <Column
              col={{ base: 12, lg: 6 }}
              data-testid="entities-with-alerts-list"
            >
              <EntitiesWithDetectionsPanel
                loading={summaryLoading}
                error={summaryError}
                data={summaryData}
              />
            </Column>
            <Column
              col={{ base: 12, lg: 6 }}
              data-testid="total-alert-categories"
            >
              <TotalDetectionsPanel
                loading={summaryLoading}
                error={summaryError}
                data={summaryData}
              />
            </Column>
          </Flex>
          <Flex flex="1 1 auto">
            <Column col={{ base: 12 }} data-testid="mitre-attack-map">
              <DetectionsByCategoryPanel
                loading={mitreLoading}
                error={mitreError}
                data={mitreData}
              />
            </Column>
          </Flex>
        </Board>
      </Column>
    </Board>
  );
};

function HighRiskEntitiesDetectionsTableWithData(
  props: React.ComponentProps<typeof HighRiskEntitiesDetectionsTable>,
) {
  return <HighRiskEntitiesDetectionsTable {...props} />;
}
