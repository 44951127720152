import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ConfigData } from './useGetAlertAndDetectionTenantConfigs';

export interface UpdateAlertAndDetectionTenantConfigsResponse {
  setAlertAndDetectionTenantConfigs: {
    tenant: string;
    category: string;
    configs: ConfigData[];
  };
}

export interface UpdateAlertAndDetectionTenantConfigsVariables {
  tenant: string;
  value: boolean;
}

export const UPDATE_ALERT_AND_DETECTION_TENANT_CONFIGS = gql`
  mutation setAlertAndDetectionTenantConfigs($tenant: String, $value: Boolean) {
    setAlertAndDetectionTenantConfigs(tenant: $tenant, value: $value) {
      tenant
      category
      configs {
        config_key
        config_value
      }
    }
  }
`;

export const useUpdateAlertAndDetectionTenantConfigs = (
  options: MutationHookOptions<
    UpdateAlertAndDetectionTenantConfigsResponse,
    UpdateAlertAndDetectionTenantConfigsVariables
  >,
) => {
  return useMutation<
    UpdateAlertAndDetectionTenantConfigsResponse,
    UpdateAlertAndDetectionTenantConfigsVariables
  >(UPDATE_ALERT_AND_DETECTION_TENANT_CONFIGS, options);
};
