import { useApolloClient } from '@apollo/client';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface UseUserSessionParams {
  cookiesAccepted?: boolean | null;
}

export const SIGN_OUT_HUMIO_SESSION = () => {
  return `
      mutation {
        logoutOfSession
      }
    `;
};

export const useUserSession = () => {
  const { user, isPOVTenant, setAuthState, setUser, setUserLicense } =
    useContext(AuthContext);
  const { setSelectedOrgTenants } = useContext(OrgTenantsContext);
  const location = useLocation();
  const navigate = useNavigate();
  const client = useApolloClient();

  const signOutOfHumioSession = async () => {
    await axios({
      method: 'post',
      url: isPOVTenant
        ? (process.env.NX_PUBLIC_POV_HUMIO_BASE_HREF ??
          '/search-internal/graphql')
        : (process.env.NX_PUBLIC_HUMIO_BASE_HREF ?? '/search/graphql'),
      data: JSON.stringify({
        query: SIGN_OUT_HUMIO_SESSION(),
      }),
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem('session_id_token') || ''
        }`,
        'Content-Type': 'application/json',
      },
    })
      .then((result) => {
        // functionality can be added later
      })
      .catch((error) => console.log('error', error));
  };

  const signOut = async () => {
    try {
      if (localStorage.getItem('session_id_token')) {
        await signOutOfHumioSession();
      }
      if (user) {
        await Auth.signOut();
      }
      setUser?.(undefined);
      setAuthState?.(undefined);
      setUserLicense?.(undefined);
      setSelectedOrgTenants?.(undefined);
      localStorage.removeItem('tenant');
      localStorage.removeItem('docs_id_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('is_sensor_configured');
      localStorage.removeItem('accept_privacy_policy');
      localStorage.removeItem('session_id_token');
      sessionStorage.removeItem('isSSOLogin');
      window?.pendo?.stopGuides();
      client?.clearStore();
    } catch (e) {
      console.error('logout error: ', e);
    }
  };

  const currentUserSession = async () => {
    try {
      return await Auth.currentSession();
    } catch (err) {
      await signOut();
      console.error(err);
    }
  };

  const deregisterUserMFA = async () => {
    // signout after deregistering user MFA
    await Auth.setPreferredMFA(user, 'NOMFA');
    await signOut();
  };

  const setUserSessionState = (authData: any) => {
    if (setUser) {
      const currentIdToken = user?.signInUserSession?.idToken?.jwtToken;
      const newIdToken = authData?.getIdToken()?.getJwtToken();
      if (currentIdToken !== newIdToken) {
        const userDupe = cloneDeep(user);
        Object.assign(userDupe?.signInUserSession, authData);
        setUser(userDupe);
      }
    }
  };

  const checkUserMFA = () => {
    if (
      user?.preferredMFA === 'NOMFA' &&
      !user?.attributes['custom:allow_no_mfa']
    ) {
      navigate(`/registration/mfa`, {
        replace: true,
        state: { routeAccessAllowed: true },
      });
    }
  };

  useEffect(() => {
    async function checkUserSession() {
      try {
        if (user) {
          const userSession = await currentUserSession().catch(async (err) => {
            await signOut();
          });
          setUserSessionState(userSession);
        }
      } catch (e) {
        console.error('nav change error: ', e);
        await signOut();
      }
    }

    checkUserSession();
  }, [location]);

  return {
    signOut,
    currentUserSession,
    setUserSessionState,
    checkUserMFA,
    deregisterUserMFA,
  };
};
