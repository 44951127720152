import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { useMemo } from 'react';
import { IdType } from 'react-table';

import { useDataTableContext } from '../../hooks';

export interface DataTableColumnVisibilityMenuProps {
  visibilityTogglableColumns: IdType<unknown>[];
}

export const DataTableColumnVisibilityMenu = ({
  visibilityTogglableColumns,
}: DataTableColumnVisibilityMenuProps) => {
  const {
    columns: [columns],
  } = useDataTableContext<{}>(); // eslint-disable-line @typescript-eslint/no-empty-object-type

  const togglableColumns = useMemo(() => {
    return columns.filter((column) => {
      const hasHeader = Boolean(column.Header);
      const isTogglable =
        visibilityTogglableColumns.length === 0 || // if unspecified, all columns are togglable
        visibilityTogglableColumns.includes(column.id);
      return hasHeader && isTogglable;
    });
  }, [columns, visibilityTogglableColumns]);

  const columnsCount = columns.length;
  const visibleColumnsCount = columns.filter(
    ({ isVisible }) => isVisible,
  ).length;

  const isHideDisabled = visibleColumnsCount <= 1;
  const isActive = visibleColumnsCount < columnsCount;

  return (
    <Menu closeOnSelect={false}>
      <Tooltip label="Customize columns">
        <MenuButton
          as={IconButton}
          mt={-1}
          aria-label="Toggle column visibility"
          variant="ghost"
          size="xs"
          colorScheme={isActive ? 'blue' : 'gray'}
          icon={<MuiIcon>settings</MuiIcon>}
        />
      </Tooltip>
      <MenuList zIndex="popover" data-testid="toggle-column-menu">
        <MenuGroup textStyle="h6" title="Customize columns">
          <MenuDivider />
          {togglableColumns.map((column) => {
            const checkboxProps = column.getToggleHiddenProps();

            return (
              <MenuItem
                key={column.id}
                as={Box}
                w="full"
                justifyContent="start"
              >
                <Checkbox
                  {...checkboxProps}
                  w="full"
                  defaultChecked={column.isVisible}
                  isDisabled={column.isVisible && isHideDisabled}
                  label={column.Header}
                  justifyContent="start"
                >
                  {column.Header}
                </Checkbox>
              </MenuItem>
            );
          })}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
