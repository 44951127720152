import {
  Code,
  Flex,
  HStack,
  IconButton,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { MuiIcon } from '@gamma/icons';
import { graphqlErrorRedirects } from '@gamma/investigator/constants';
import { useUserSession } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GetKinesisConnectionDetails,
  useGetKinesisConnectionDetails,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';

const { stepThree, stepFour } = i18n.pages.sensorMonitoring.modal;
const { accessKey, secretKey, region, streamName } = i18n.forms.labels;

export const ConfigureDetailsBody = () => {
  const { signOut } = useUserSession();

  const { data, loading, error } = useGetKinesisConnectionDetails({
    fetchPolicy: 'network-only',
  });

  const {
    access_key = '',
    secret_key = '',
    aws_region = '',
    stream_name = '',
  } = data?.getKinesisConnectionDetails || ({} as GetKinesisConnectionDetails);

  const { onCopy: copyAccess } = useClipboard(access_key ?? '');
  const { onCopy: copySecret } = useClipboard(secret_key ?? '');
  const { onCopy: copyRegion } = useClipboard(aws_region ?? '');
  const { onCopy: copyStream } = useClipboard(stream_name ?? '');
  const { onCopy: copyCode } = useClipboard(stepThree.code);

  if (loading || error) {
    return (
      <GraphQLReqStatus
        loading={loading}
        error={error}
        signOut={signOut}
        extended={graphqlErrorRedirects}
      />
    );
  }

  return (
    <VStack data-testid="configure-details" spacing={4}>
      <Text alignSelf="flex-start">{stepThree.text}</Text>
      <HStack w="89%">
        <UnorderedList>
          <ListItem>{stepThree.one}</ListItem>
          <ListItem>{stepThree.two}</ListItem>
          <Flex p={2} w="100%" borderRadius="4px">
            <VStack alignItems="start">
              <Code variant="ghost">{stepThree.code}</Code>
            </VStack>
            <Spacer />
            <IconButton
              aria-label={'Copy to Clipboard'}
              icon={<MuiIcon color="blue.300">content_copy</MuiIcon>}
              onClick={copyCode}
            />
          </Flex>
        </UnorderedList>
      </HStack>
      <Text alignSelf="flex-start">{stepFour}</Text>
      <VStack w="89%" p={4} spacing={4} borderRadius="4px">
        <Input
          data-testid="access-key"
          isReadOnly
          name="access_key"
          label={accessKey}
          value={access_key}
          type="password"
          rightElement={
            <IconButton
              data-testid="copy-access-key"
              icon={<MuiIcon color="blue.300">content_copy</MuiIcon>}
              aria-label={'Clipboard Icon'}
              onClick={copyAccess}
            />
          }
        />
        <Input
          data-testid="secret-key"
          name="secret_key"
          label={secretKey}
          value={secret_key}
          type="password"
          isReadOnly
          rightElement={
            <IconButton
              data-testid="copy-secret-key"
              icon={<MuiIcon color="blue.300">content_copy</MuiIcon>}
              aria-label={'Clipboard Icon'}
              onClick={copySecret}
            />
          }
        />
        <HStack>
          <Input
            data-testid="region"
            name="region"
            label={region}
            value={aws_region}
            isReadOnly
            rightElement={
              <IconButton
                data-testid="copy-region"
                icon={<MuiIcon color="blue.300">content_copy</MuiIcon>}
                aria-label={'Clipboard Icon'}
                onClick={copyRegion}
              />
            }
          />
          <Input
            data-testid="stream-name"
            name="stream_name"
            label={streamName}
            value={stream_name}
            isReadOnly
            rightElement={
              <IconButton
                data-testid="copy-stream-name"
                icon={<MuiIcon color="blue.300">content_copy</MuiIcon>}
                aria-label={'Clipboard Icon'}
                onClick={copyStream}
              />
            }
          />
        </HStack>
      </VStack>
    </VStack>
  );
};
