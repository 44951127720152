import { Box } from '@chakra-ui/react';
import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { i18n } from '@gamma/investigator/localization';
import { Board, Column } from '@gamma/layout';
import { Outlet, useLocation } from 'react-router-dom';

const {
  alertCatalog,
  alertExport,
  generalSettings,
  securityAudit,
  sensorMonitoring,
} = i18n.navigation.system;

const {
  systemSettingsAlertCatalog,
  systemSettingsSensorMonitoring,
  systemSettingsIntegrationsAlertExports,
  systemSettingsSecurityAudit,
  systemSettingsGeneral,
  systemSettingsIntegrations,
} = ROUTES;

export const System = () => {
  const { pathname } = useLocation();

  const pageTitle = {
    [systemSettingsAlertCatalog()]: '',
    [systemSettingsSensorMonitoring]: sensorMonitoring,
    [systemSettingsIntegrationsAlertExports]: alertExport,
    [systemSettingsSecurityAudit()]: securityAudit,
    [systemSettingsGeneral]: generalSettings,
  }[pathname];

  return (
    <Board flexGrow={1} flexWrap="inherit" flexDirection="column">
      <Column
        flexGrow={1}
        display="flex"
        flexBasis="auto"
        flexDirection="column"
      >
        {!pathname.includes(`${systemSettingsAlertCatalog()}/`) &&
          !pathname.includes(`${systemSettingsIntegrations}/`) && (
            <PageHeader showDateSelection={false}>{pageTitle}</PageHeader>
          )}
        <Outlet />
      </Column>
    </Board>
  );
};
