import { EntityInformationContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import {
  EntityDetectionTimelineItem,
  useGetEDRConfigurationPublic,
  useGetEDRPayload,
  useGetEntityDetectionTimelinePaginated,
  useGetEntityEdrStatus,
  useQueryAlertMetadataExcludedEntitiesPaginated,
  useSimpleSearchQuery,
} from '@gamma/investigator/queries';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';

interface useEntityInformationQueriesProps {
  entityId: string;
  tenantId: string;
  entityName: string;
  hoveredElement: any;
  isHoveredEntity: any;
}

export const useEntityInformationQueries = ({
  entityId,
  tenantId,
  entityName,
  hoveredElement,
  isHoveredEntity,
}: useEntityInformationQueriesProps) => {
  const { activeEntity, setActiveEntity, isEntityModalOpen } = useContext(
    EntityInformationContext,
  );

  const { start, end } = useDateRangeQueryString();
  const responseIntervalStart = moment(end * 1000)
    .subtract(1, 'minutes')
    .unix();
  const sixHourIntervalStart = moment(end * 1000)
    .subtract(6, 'hours')
    .unix();
  const { loading: getEntityDetectionTimelinePaginatedLoading } =
    useGetEntityDetectionTimelinePaginated({
      skip:
        !activeEntity?.timeoutId ||
        !(
          (hoveredElement && isHoveredEntity) ||
          (isEntityModalOpen && activeEntity?.entityId === entityId)
        ),
      variables: {
        size: 3,
        offset: 0,
        start,
        end,
        tenant_id: tenantId,
        entity_id: entityId,
        must_conds: [
          `{"terms": {"tenant": ["${tenantId}"]}}`,
          '{"term": {"detection_status": "open"}}',
        ],
      },
      onCompleted: ({ getEntityDetectionTimelinePaginated }) => {
        const { total_items } = getEntityDetectionTimelinePaginated;
        const {
          alert_name,
          alert_type,
          content_id,
          entity_id,
          entity_name,
          entity_type,
          tenant_entity,
          first_seen,
          last_seen,
          severity,
          tenant_info,
          tenant_detection: detection_id,
          total_detections,
        } = getEntityDetectionTimelinePaginated?.data?.[0] || {};
        const detectionData = {
          alert_name,
          alert_type,
          content_id,
          entity_id,
          entity_name,
          entity_type,
          tenant_entity,
          detection_id,
          total_items,
          first_seen,
          last_seen,
          severity,
          tenant_info,
          total_detections,
          detection_count: total_detections,
        };
        const detectionDataList =
          getEntityDetectionTimelinePaginated?.data?.map(
            (item: EntityDetectionTimelineItem) => {
              return {
                alert_name: item?.alert_name,
                severity: item?.severity,
                is_custom_severity: item?.is_custom_severity,
              };
            },
          );
        setActiveEntity?.((prevState) => ({
          ...prevState,
          detectionData,
          detectionDataList,
        }));
      },
    });

  const { loading: queryAlertMetadataExcludedEntitiesPaginatedLoading } =
    useQueryAlertMetadataExcludedEntitiesPaginated({
      skip:
        !activeEntity?.timeoutId ||
        !activeEntity?.excludedEntitiesVariables ||
        !(
          (hoveredElement && isHoveredEntity) ||
          (isEntityModalOpen && activeEntity?.entityId === entityId)
        ),
      variables: activeEntity?.excludedEntitiesVariables,
      onCompleted: ({ queryAlertMetadataExcludedEntitiesPaginated }) => {
        const excludedEntities =
          queryAlertMetadataExcludedEntitiesPaginated?.excluded_entities || [];
        const entityIsSuppressed = excludedEntities.length > 0;
        setActiveEntity?.((prevState) => ({
          ...prevState,
          excludeEntitiesData: excludedEntities,
          entityIsSuppressed,
        }));
      },
      onError: ({ extraInfo }) => {
        setActiveEntity?.((prevState) => ({
          ...prevState,
          excludeEntitiesError: extraInfo,
        }));
      },
    });

  const { loading: getEDRConfigurationLoading } = useGetEDRConfigurationPublic({
    skip:
      !activeEntity?.timeoutId ||
      !(
        (hoveredElement && isHoveredEntity) ||
        (isEntityModalOpen && activeEntity?.entityId === entityId)
      ),
    variables: { tenant: tenantId, provider_name: 'crowdstrike' },
    onCompleted: ({ getEDRConfiguration }) => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        edrConfigData: getEDRConfiguration,
      }));
    },
  });

  const { loading: getEDRPayloadLoading } = useGetEDRPayload({
    skip:
      !activeEntity?.timeoutId ||
      !activeEntity?.entityName ||
      !activeEntity?.entityType ||
      !activeEntity?.lastSeen ||
      !(
        (hoveredElement && isHoveredEntity) ||
        (isEntityModalOpen && activeEntity?.entityId === entityId)
      ),
    variables: {
      tenant: tenantId,
      alert_entity_info: {
        entity_name: activeEntity?.entityName,
        entity_type: activeEntity?.entityType,
      },
      as_of: activeEntity?.lastSeen,
    },
    onCompleted: ({ getEdrPayload }) => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        edrPayload: getEdrPayload,
      }));
    },
  });

  const { loading: getEntityEdrStatusLoading } = useGetEntityEdrStatus({
    fetchPolicy: 'network-only',
    skip:
      !activeEntity?.timeoutId ||
      !activeEntity?.edrPayload?.entity_id ||
      !(
        (hoveredElement && isHoveredEntity) ||
        (isEntityModalOpen && activeEntity?.entityId === entityId)
      ),
    variables: {
      tenant: tenantId,
      entity_id: activeEntity?.edrPayload?.entity_id,
    },
    onCompleted: ({ getEntityEdrStatus }) => {
      const { entity_edr_status, tenant, updated_timestamp } =
        getEntityEdrStatus;
      setActiveEntity?.((prevState) => ({
        ...prevState,
        entityIsIsolated:
          entity_edr_status === 'isolated' ||
          entity_edr_status === 'isolation_pending',
        entityEdrStatus: {
          tenant,
          entity_edr_status,
          updated_timestamp,
        },
      }));
    },
  });

  //Query strings for Metadata, Originator, and Responder
  const queryStringMetadata = `createevents(rawstring="") | id.resp_h:="${entityName}"
              |ioc:lookup(field=[id.resp_h], type="ip_address", strict=false, confidenceThreshold="low", include=["published_date", "malicious_confidence"])
              |asn(field=id.resp_h)
              |ipLocation(field=id.resp_h, as=resp_geo)
              |table([id.resp_h.asn, id.resp_h.org, resp_geo.city, resp_geo.state, resp_cc, resp_geo.country, service, ioc[0].published_date, ioc[0].malicious_confidence], limit=1)\n`;
  const queryStringOriginator = `id.orig_h=${entityName}
              |resp_bytes>0 OR history=/[had]/
              |groupBy(service)
              |table([service], limit=1)`;
  const queryStringResponder = `id.resp_h=${entityName}
              |resp_bytes>0 OR history=/[had]/
              |groupBy(service)
              |table([service], limit=1)`;

  const [simpleSearchQueryMetadata, { loading: isSimpleSearchMetaLoading }] =
    useSimpleSearchQuery({
      variables: {
        clientSessionId: localStorage.getItem('session_id_token'),
        queryString: queryStringMetadata,
        end: end.toString(),
        start: responseIntervalStart.toString(),
        tenantsForQuery: [tenantId],
      },
      onCompleted: (data) => {
        if (data.simpleSearchQuery?.events[0]) {
          const parsedData = JSON.parse(data.simpleSearchQuery?.events[0]);
          // Check if resp_cc is present. if not, set it to resp_geo.country from parsedData
          if (!parsedData.resp_cc) {
            parsedData.resp_cc = parsedData['resp_geo.country'];
          }
          if (entityId === activeEntity?.entityId) {
            setActiveEntity?.((prevState) => ({
              ...prevState,
              logscaleMetaData: parsedData,
            }));
          }
        }
      },
    });

  const [simpleSearchQueryOriginator, { loading: isSimpleSearchOrigLoading }] =
    useSimpleSearchQuery({
      variables: {
        clientSessionId: localStorage.getItem('session_id_token'),
        queryString: queryStringOriginator,
        end: end.toString(),
        start: sixHourIntervalStart.toString(),
        tenantsForQuery: [tenantId],
      },
      onCompleted: (data) => {
        if (data.simpleSearchQuery?.events[0]) {
          const parsedData = JSON.parse(data.simpleSearchQuery?.events[0]);
          if (entityId === activeEntity?.entityId) {
            setActiveEntity?.((prevState) => ({
              ...prevState,
              logscaleOrigData: parsedData,
            }));
          }
        }
      },
    });

  const [simpleSearchQueryResponder, { loading: isSimpleSearchRespLoading }] =
    useSimpleSearchQuery({
      variables: {
        clientSessionId: localStorage.getItem('session_id_token'),
        queryString: queryStringResponder,
        end: end.toString(),
        start: sixHourIntervalStart.toString(),
        tenantsForQuery: [tenantId],
      },
      onCompleted: (data) => {
        if (data.simpleSearchQuery?.events[0]) {
          const parsedData = JSON.parse(data.simpleSearchQuery?.events[0]);
          if (entityId === activeEntity?.entityId) {
            setActiveEntity?.((prevState) => ({
              ...prevState,
              logscaleRespData: parsedData,
            }));
          }
        }
      },
    });

  useEffect(() => {
    setActiveEntity?.((prevState) => ({
      ...prevState,
      isSimpleSearchLoading:
        isSimpleSearchMetaLoading ||
        isSimpleSearchOrigLoading ||
        isSimpleSearchRespLoading,
    }));
  }, [
    isSimpleSearchMetaLoading,
    isSimpleSearchOrigLoading,
    isSimpleSearchRespLoading,
    setActiveEntity,
  ]);

  const useEntityInformationQueriesLoading = useMemo(
    () =>
      getEDRPayloadLoading ||
      getEntityEdrStatusLoading ||
      isSimpleSearchMetaLoading ||
      isSimpleSearchOrigLoading ||
      isSimpleSearchRespLoading ||
      getEDRConfigurationLoading ||
      getEntityDetectionTimelinePaginatedLoading ||
      queryAlertMetadataExcludedEntitiesPaginatedLoading,
    [
      getEDRConfigurationLoading,
      getEDRPayloadLoading,
      getEntityDetectionTimelinePaginatedLoading,
      getEntityEdrStatusLoading,
      isSimpleSearchOrigLoading,
      isSimpleSearchMetaLoading,
      isSimpleSearchRespLoading,
      queryAlertMetadataExcludedEntitiesPaginatedLoading,
    ],
  );

  return {
    useEntityInformationQueriesLoading,
    simpleSearchQueryMetadata,
    simpleSearchQueryOriginator,
    simpleSearchQueryResponder,
  };
};
