import { ComponentStyleConfig } from '@chakra-ui/react';
import { getColor, transparentize } from '@chakra-ui/theme-tools';

import { textStyles } from '../../common';

const bgLayerMap: Map<string, string> = new Map([
  ['base', 'layer.0'],
  ['first', 'layer.1'],
  ['second', 'layer.2'],
  ['third', 'layer.3'],
]);

const lightBgLayerMap: Map<string, string> = new Map([
  ['base', 'layer.1'],
  ['first', 'layer.2'],
  ['second', 'layer.3'],
  ['third', 'layer.2'],
]);

const borderLayerMap: Map<string, string> = new Map([
  ['base', 'border.layer.0'],
  ['first', 'border.layer.1'],
  ['second', 'border.layer.2'],
  ['third', 'border.layer.3'],
]);

const dividerLayerMap: Map<string, string> = new Map([
  ['base', 'border.layer.1'],
  ['first', 'border.layer.2'],
  ['second', 'border.layer.3'],
  ['third', 'border.layer.2'],
]);

export const dataTableStyles: ComponentStyleConfig = {
  parts: [
    'headerGroup',
    'header',
    'sortableHeader',
    'divider',
    'body',
    'row',
    'expandedRow',
    'draggedRow',
    'cell',
    'pagination',
    'paginationButton',
    'footer',
  ],
  baseStyle: (props: any) => {
    const { layerStyle = 'first' } = props;
    const bg = bgLayerMap.get(layerStyle);
    const lightBg = lightBgLayerMap.get(layerStyle);
    const border = borderLayerMap.get(layerStyle);

    return {
      headerGroup: {
        display: 'flex',
        bg: lightBg,
        flex: '1 0 100%',
        minW: 'fit-content !important',
        gap: 0,
      },
      header: {
        px: 4,
        display: 'flex',
        color: 'text.primary',
        position: 'relative',
        ...textStyles['body-md-bold'],
      },
      sortableHeader: {
        px: 4,
        ...textStyles['body-md-bold'],
        w: 'full',
        display: 'flex',
        whiteSpace: 'nowrap',
        border: 'solid 1px',
        borderColor: 'transparent',
        borderRadius: 'base',
        paddingStart: 3,
        _focusVisible: {
          outline: 'none',
          borderColor: 'blue.400',
          boxShadow: '0px 0px 0px 1.5px #0093ee',
        },
      },
      divider: {
        borderColor: dividerLayerMap.get(layerStyle),
      },
      body: {
        flexDirection: 'column',
        bg: bg,
        overflowY: 'hidden',
        overflowX: 'auto',
      },
      row: {
        position: 'relative',
        minW: 'fit-content !important',
        '._selected > &': {
          bg: lightBg,
        },
        _hover: {
          cursor: props.isRowClickable ? 'pointer' : undefined,
          bg: transparentize(getColor(props.theme, 'blue.500'), 0.1)(props),
        },
        ...(props.isLined && {
          borderBottom: 'solid 1px',
          borderColor: border,
          _last: { border: 'none' },
        }),
        _focusWithin: {
          ':not(._unselected > &)': {
            bg: lightBg,
          },
        },
      },
      rowActionsWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        height: 'full',
      },
      rowActions: {
        px: 2,
        display: 'flex',
        height: 'full',
        alignItems: 'center',
        justifyContent: 'center',
        _groupHover: {
          bg: transparentize(getColor(props.theme, 'blue.500'), 0.1)(props),
        },
        _groupFocusWithin: {
          bg: lightBg,
        },
        '& > :not(style) ~ :not(style)': {
          ml: 2,
        },
      },
      expandedRow: {
        display: 'flex',
        position: 'relative',
        w: 'full',
        px: 4,
        py: 4,
        ...(props.isLined && {
          borderTop: 'solid 1px',
          borderColor: border,
        }),
      },
      draggedRow: {
        bg: 'layer.3',
      },
      cell: {
        py: 2.5,
        px: 4,
        display: 'flex',
        alignItems: 'center',
        ...textStyles['body-md'],
      },
      pagination: {
        display: 'flex',
        px: 4,
        py: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        ...(props.isLined && {
          borderTop: 'solid 1px',
          borderColor: border,
        }),
        height: 12,
      },
      paginationButton: {
        mr: 'auto',
        bg: 'transparent !important',
        border: 'solid 1px',
        borderRadius: 'base',
        borderColor: 'transparent',
        color: 'blue.300',
        _disabled: {
          color: 'gray.600',
        },
        _focusVisible: {
          outline: 'none',
          borderColor: 'blue.500',
          boxShadow: '0px 0px 0px 1.5px #0093ee',
        },
      },
      footer: {
        px: 4,
        py: 2.5,
        display: 'flex',
        ...(props.isLined && {
          borderTop: 'solid 1px',
          borderColor: border,
        }),
      },
    };
  },
  sizes: {
    xs: {
      header: {
        py: 1,
      },
      sortableHeader: {
        py: 1,
      },
      cell: {
        py: 1,
      },
    },
    sm: {
      header: {
        py: 1.5,
      },
      sortableHeader: {
        py: 1.5,
      },
      cell: {
        py: 1.5,
      },
    },
    md: {
      header: {
        py: 2.5,
      },
      sortableHeader: {
        py: 2.5,
      },
      cell: {
        py: 2.5,
      },
    },
    lg: {
      header: {
        py: 3.5,
      },
      sortableHeader: {
        py: 3.5,
      },
      cell: {
        py: 3.5,
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
