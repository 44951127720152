import {
  BoxProps,
  TabProps as ChakraTabProps,
  Tabs as ChakraTabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { ComponentType, ReactNode } from 'react';

import { useTabsFromChildren } from './hooks';

export interface TabsProps {
  children: ReactNode;
  orientation?: 'horizontal' | 'vertical';
  onChange?: (index: number) => void;
  index?: number;
  defaultIndex?: number;
  TabComponent?: ComponentType<ChakraTabProps & Record<string, unknown>>;
  variant?: string;
  isFitted?: boolean;
  listBg?: BoxProps['bg'];
  size?: string;
  isLazy?: boolean;
}

export const Tabs = ({
  children,
  TabComponent = Tab,
  orientation,
  isFitted,
  listBg,
  size = 'md',
  isLazy = false,
  ...rest
}: TabsProps) => {
  const tabsFromChildren = useTabsFromChildren(children);

  if (!children) {
    return null;
  }

  return (
    <ChakraTabs
      size={size}
      isFitted={orientation === 'vertical' ? false : isFitted}
      orientation={orientation}
      isLazy={isLazy}
      {...rest}
    >
      <TabList bg={listBg}>
        {tabsFromChildren.map(({ title, props }, index) => {
          const { titleText, title: propsTitle, ...otherProps } = props;

          return (
            <TabComponent
              key={index}
              title={titleText ?? propsTitle}
              {...otherProps}
            >
              {title}
            </TabComponent>
          );
        })}
      </TabList>
      <TabPanels>
        {tabsFromChildren.map(
          ({ content }, index) =>
            content && <TabPanel key={index}>{content}</TabPanel>,
        )}
      </TabPanels>
    </ChakraTabs>
  );
};
