import { useApolloClient } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Text,
  Tooltip,
  useDisclosure,
  usePrevious,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Input, Switch } from '@gamma/form-fields';
import { MuiIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_EDR_CONFIGURATION,
  IVerifyEDRConfiguration,
  useDeleteEDRConfiguration,
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
  useStoreEDRConfiguration,
  useVerifyEDRConfiguration,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { isEqual } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const { integrations } = i18n.pages;
const { save, cancel, error, success, warning } = integrations;

const {
  noGoBack,
  yesDelete,
  enabled: enabledLabel,
  disabled: disabledLabel,
  connectionNotVerified,
  connectionVerified: connectionVerifiedMessage,
  paloAlto,
  integration,
} = integrations;

const {
  integrationHeader,
  accessKey,
  deleteConnectionHeader,
  deleteConnectionTagline,
  secretKey,
  s3Bucket,
  s3FilePrefix,
  verifyPaloAltoConnection,
  documentation,
  forMoreInformation,
  saveSuccess,
  saveError,
  verifyError,
  deleteError,
  deleteSuccess,
  verifyConnection,
} = paloAlto;

const { PALO_ALTO_DOCS } = URLS;

interface PaloAltoSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PaloAltoSettingsModal = ({
  isOpen,
  onClose,
}: PaloAltoSettingsModalProps) => {
  const [configurationVerified, setConfigurationVerified] = useState<
    boolean | undefined
  >();
  const [clientSecretInputPwType, setClientSecretInputPwType] =
    useState<boolean>(true);
  const [clientAccessInputPwType, setClientAccessInputPwType] =
    useState<boolean>(true);
  const showToast = useToast();
  const { userRole, setUserPrivileges, userPrivileges, userLicense } =
    useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};
  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const {
    loading: EDRConfigLoading,
    error: EDRConfigError,
    data: EDRData,
  } = getEDRConfigQuery({
    variables: {
      provider_name: 'palo_alto_networks',
    },
    onCompleted: (data) => {
      if (data.getEDRConfiguration === null) {
        reset({});
      } else {
        const { provider_name: _, ...rest } = data.getEDRConfiguration;
        reset({ ...rest });
      }
    },
  });

  const client = useApolloClient();

  const logOperationCall = useLogOperationCall();

  const {
    getValues,
    watch,
    control,
    register,
    reset,
    formState: { isValid },
  } = useForm();

  const formData = watch();

  const previousFormData = usePrevious(formData);

  useEffect(() => {
    if (
      previousFormData !== undefined &&
      !isEqual(previousFormData, formData)
    ) {
      setConfigurationVerified(undefined);
    }
  }, [formData, previousFormData]);

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [verifyEDRConfiguration, { loading: verifyEDRConfigurationLoading }] =
    useVerifyEDRConfiguration({
      onCompleted: (data: IVerifyEDRConfiguration) => {
        setConfigurationVerified(data?.verifyEDRConfiguration?.verified);
      },
      onError: () => {
        showToast({
          title: error,
          description: verifyError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [storeEDRConfiguration, { loading: storeEDRConfigurationLoading }] =
    useStoreEDRConfiguration({
      variables: {
        provider_name: 'palo_alto_networks',
        config: {
          palo_alto_networks: {
            ...formData,
            polling_seconds: -1,
            edr_action_enabled: formData.enabled,
          },
        },
      },
      onCompleted: async () => {
        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'getEDRConfiguration',
        });
        client.cache.gc();
        showToast({
          title: success,
          description: saveSuccess,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
        const updatedInput = {
          status: formData.enabled ? 'Enabled' : 'Disabled',
          service_name: 'Palo Alto Networks',
        };
        logOperationCall(
          'ENABLE_DISABLE_CONFIGURATION',
          'INTEGRATION',
          JSON.stringify(updatedInput),
        );
        const newUserPrivileges = { ...userPrivileges };
        newUserPrivileges[tenant]['palo-alto-networks'] = formData.enabled;
        setUserPrivileges(newUserPrivileges);
        onClose();
      },
      onError: async () => {
        showToast({
          title: error,
          description: saveError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const [deleteEDRConfiguration, { loading: deleteEDRConfigurationLoading }] =
    useDeleteEDRConfiguration({
      variables: {
        provider_name: 'palo_alto_networks',
      },
      refetchQueries: [
        {
          query: GET_EDR_CONFIGURATION,
          variables: {
            provider_name: 'palo_alto_networks',
          },
        },
      ],
      onCompleted: async () => {
        showToast({
          title: success,
          description: deleteSuccess,
          status: 'success',
          isClosable: true,
          position: 'bottom-right',
        });
        const newUserPrivileges = { ...userPrivileges };
        newUserPrivileges[tenant]['palo-alto-networks'] = false;
        setUserPrivileges(newUserPrivileges);
        reset({ enabled: false });
        onClose();
      },
      onError: async () => {
        showToast({
          title: error,
          description: deleteError,
          status: 'error',
          isClosable: true,
          position: 'bottom-right',
        });
      },
    });

  const handleClose = () => {
    if (EDRData?.getEDRConfiguration === null) {
      reset({ enabled: false });
    } else if (EDRData?.getEDRConfiguration) {
      const { provider_name: _, ...rest } = EDRData.getEDRConfiguration;
      reset({ ...rest });
    }
    onClose();
  };

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        isCentered={true}
        title={`Palo Alto ${integration}`}
        closeOnEsc={!verifyEDRConfigurationLoading}
        isCloseDisabled={verifyEDRConfigurationLoading}
        closeOnOverlayClick={!verifyEDRConfigurationLoading}
        body={
          EDRConfigError || EDRConfigLoading ? (
            <GraphQLReqStatus
              error={EDRConfigError}
              loading={EDRConfigLoading}
              isBackground={true}
            ></GraphQLReqStatus>
          ) : (
            <VStack as="form" alignItems="flex-start" spacing={4}>
              <Text textStyle="body-md">{integrationHeader}</Text>
              <Controller
                name="enabled"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Switch
                    {...register('enabled', {})}
                    inline
                    name={name}
                    isChecked={value}
                    isDisabled={false}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    label={formData.enabled ? enabledLabel : disabledLabel}
                  />
                )}
              />
              <Box>
                <Text>
                  {verifyPaloAltoConnection}
                  {forMoreInformation}
                  <Link
                    href={PALO_ALTO_DOCS}
                    textDecoration="underline"
                    isExternal
                  >
                    {documentation}
                  </Link>
                  .
                </Text>
              </Box>
              <Input
                {...register('access_key', {
                  required: true,
                })}
                isRequired={true}
                isDisabled={false}
                type={clientAccessInputPwType ? 'password' : 'text'}
                label={accessKey}
                rightElement={
                  <Button>
                    <MuiIcon
                      cursor="pointer"
                      aria-label={`${
                        clientAccessInputPwType ? 'Show' : 'Hide'
                      } Client Secret`}
                      onClick={() =>
                        setClientAccessInputPwType(!clientAccessInputPwType)
                      }
                    >
                      {clientAccessInputPwType
                        ? 'visibility'
                        : 'visibility_off'}
                    </MuiIcon>
                  </Button>
                }
              />
              <Input
                {...register('secret_key', {
                  required: true,
                })}
                isRequired={true}
                isDisabled={false}
                label={secretKey}
                type={clientSecretInputPwType ? 'password' : 'text'}
                rightElement={
                  <Button>
                    <MuiIcon
                      cursor="pointer"
                      aria-label={`${
                        clientSecretInputPwType ? 'Show' : 'Hide'
                      } Client Secret`}
                      onClick={() =>
                        setClientSecretInputPwType(!clientSecretInputPwType)
                      }
                    >
                      {clientSecretInputPwType
                        ? 'visibility'
                        : 'visibility_off'}
                    </MuiIcon>
                  </Button>
                }
              />

              <Input
                {...register('bucket', {
                  required: true,
                })}
                isRequired={true}
                isDisabled={false}
                label={s3Bucket}
              />

              <Input
                {...register('prefix', {
                  required: true,
                })}
                isRequired={true}
                isDisabled={false}
                label={s3FilePrefix}
              />
              <ButtonGroup
                w="100%"
                justifyContent="space-between"
                isDisabled={!isValid || configurationVerified}
              >
                <Button
                  variant="solid"
                  colorScheme="gray"
                  leftIcon={<MuiIcon>check_circle</MuiIcon>}
                  onClick={() => {
                    verifyEDRConfiguration({
                      variables: {
                        provider_name: 'palo_alto_networks',
                        config: {
                          palo_alto_networks: {
                            ...getValues(),
                            polling_seconds: -1,
                            edr_action_enabled: formData.enabled,
                          },
                        },
                      },
                    });
                  }}
                  isDisabled={!isValid || configurationVerified}
                  isLoading={verifyEDRConfigurationLoading}
                >
                  {verifyConnection}
                </Button>
                <Tooltip label={deleteConnectionHeader}>
                  <IconButton
                    variant="solid"
                    colorScheme={deleteEDRConfigurationLoading ? 'gray' : 'red'}
                    onClick={deleteOnOpen}
                    icon={<MuiIcon>delete</MuiIcon>}
                    aria-label={deleteConnectionHeader}
                    isLoading={deleteEDRConfigurationLoading}
                    isDisabled={deleteEDRConfigurationLoading || !isValid}
                  />
                </Tooltip>
              </ButtonGroup>
              {configurationVerified === true && (
                <Alert status="success" w="100%">
                  <Flex>
                    <MuiIcon color="green.100" mr={2}>
                      check_circle
                    </MuiIcon>
                    <Box>
                      <Text textStyle="body-md-bold">
                        {connectionVerifiedMessage}
                      </Text>
                    </Box>
                  </Flex>
                </Alert>
              )}
              {configurationVerified === false && (
                <Alert status="error" w="100%">
                  <Flex>
                    <MuiIcon color="red.100" mr={2}>
                      warning
                    </MuiIcon>
                    <Box>
                      <Text textStyle="body-md-bold">
                        {connectionNotVerified}
                      </Text>
                    </Box>
                  </Flex>
                </Alert>
              )}
            </VStack>
          )
        }
        footer={
          <ButtonGroup isDisabled={storeEDRConfigurationLoading}>
            <Button variant="solid" colorScheme="gray" onClick={handleClose}>
              {cancel}
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                storeEDRConfiguration();
              }}
              isLoading={storeEDRConfigurationLoading}
              isDisabled={
                storeEDRConfigurationLoading ||
                !isValid ||
                (formData?.enabled && !configurationVerified)
              }
            >
              {save}
            </Button>
          </ButtonGroup>
        }
      />
      <Modal
        size="lg"
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title={deleteConnectionHeader}
        body={
          <Alert variant="subtle" status="warning" w="100%">
            <Flex>
              <MuiIcon color="yellow.100" mr={2}>
                error
              </MuiIcon>
              <Box>
                <Text textStyle="body-md-bold">{warning}</Text>
                <Text>{deleteConnectionTagline}</Text>
              </Box>
            </Flex>
          </Alert>
        }
        footer={
          <ButtonGroup isDisabled={deleteEDRConfigurationLoading}>
            <Button variant="solid" colorScheme="gray" onClick={deleteOnClose}>
              {noGoBack}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={() => {
                deleteEDRConfiguration();
                deleteOnClose();
              }}
              isLoading={deleteEDRConfigurationLoading}
              isDisabled={deleteEDRConfigurationLoading}
            >
              {yesDelete}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};
