import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import {
  DateRangeContext,
  EntityInformationContext,
  EntityInstance,
} from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { useContext, useEffect } from 'react';
import { EntityInformationActions } from '../EntityInformationActions';
import {
  CrowdstrikeModalSection,
  LogscaleModalSection,
  MSDefenderModalSection,
  PaloAltoModalSection,
} from './Components';
interface EntityInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  activeEntity?: EntityInstance;
}

export const EntityInformationModal = ({
  isOpen,
  onClose,
  activeEntity,
}: EntityInformationModalProps) => {
  const { start, end } = useDateRangeQueryString();

  const { dateRange } = useContext(DateRangeContext);

  const { onSuppressOpen, hoveredElement, setHoveredElement } = useContext(
    EntityInformationContext,
  );

  const { entityName, entityCategory, entityIsSuppressed } = activeEntity || {};

  useEffect(() => {
    if (isOpen && hoveredElement) {
      setHoveredElement?.(undefined);
    }
  }, [isOpen, hoveredElement, setHoveredElement]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent w="1280px">
        <ModalHeader>
          <VStack alignItems="start">
            <Text>{activeEntity?.entityName}</Text>
            <HStack spacing={4} alignItems="start" divider={<StackDivider />}>
              <HStack
                px={2}
                border="1px solid"
                borderRadius="base"
                borderColor="border.1"
              >
                <MuiIcon size="sm">calendar_today</MuiIcon>

                <Text fontSize="small">
                  {`Last ${dateRange?.[0]} ${dateRange?.[1]}`}
                </Text>
              </HStack>
              <EntityInformationActions
                end={end}
                start={start}
                isModal={true}
                entityName={entityName}
                entityCategory={entityCategory}
                entityIsSuppressed={entityIsSuppressed}
                onClose={onClose}
                onSuppressEntity={onSuppressOpen}
              />
            </HStack>
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="start" divider={<StackDivider />}>
            <LogscaleModalSection activeEntity={activeEntity} />
            <CrowdstrikeModalSection activeEntity={activeEntity} />
            <MSDefenderModalSection activeEntity={activeEntity} />
            <PaloAltoModalSection activeEntity={activeEntity} />
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid" borderColor="border.layer.1">
          <Button variant="solid" colorScheme="gray" onClick={onClose}>
            {i18n.buttons.close}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
