import { Button, ButtonGroup, Center, Divider, HStack } from '@chakra-ui/react';
import { usePrevious } from '@gamma/hooks';
import {
  FlushPanelContent,
  Panel,
  PanelHeader,
  PanelHeaderProps,
  PanelHeading,
  PanelHeadingProps,
  PanelProps,
} from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';
import { gammaContext } from '@gamma/theme';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import {
  ComponentPropsWithoutRef,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IdType, Row, TableInstance } from 'react-table';

import { MuiIcon } from '@gamma/icons';
import { mergeRefs } from '@gamma/util';
import { DataTable, DataTableProps } from '../../DataTable';
import { useDataTableContext } from '../../hooks';
import { DataTableColumnVisibilityMenu } from '../DataTableColumnVisibilityMenu';
import { DataTableContextProvider } from '../DataTableContextProvider';

const HEADER_SIZE = '45px';

export type RowLengthsState = {
  curPageLength: number;
  totalLength: number;
  filteredLength: number;
};

export interface DataTablePanelProps<DataType extends {}> // eslint-disable-line @typescript-eslint/no-empty-object-type
  extends DataTableProps<DataType> {
  /**
   * The text content to display in the select all action button
   * defaults to gamma's `i18n.globals.selectAll` text
   */
  selectAllText?: string | ReactNode;
  /**
   * The text content to display in the cancel action button
   * defaults to gamma's `i18n.globals.cancel` text
   */
  cancelText?: string;
  /** The flag to determine if the select all button should be shown */
  isSelectAllHidden?: boolean;
  /** The flag to determine if the cancel button should be shown */
  isCancelHidden?: boolean;
  /** The number that shows the number of selected records */
  numberOfSelectAllItems?: number;
  /** The flag to determine if the component is loading */
  isLoading?: boolean;
  /** A combination of props that are the controls around a custom select all component's functionality and the UI content associated with it */
  selectAllProps?: {
    isDisabled?: boolean;
    leftIcon?:
      | ReactElement<any, string | JSXElementConstructor<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
      | undefined;
    isCustomSelectAll?: boolean;
  };
  /** React node that appears in the right of panel heading */
  afterHeading?: ReactNode;
  /** The function used to create the panel heading content when no rows are selected */
  formatHeading?: (rowLengthsState: RowLengthsState) => string | ReactNode;
  /** the function used to create the panel heading content when rows are selected */
  formatSelectedHeading?: (
    selected: number,
    rowLengthsState: RowLengthsState,
  ) => string | ReactNode;
  /** The function used to create additional action buttons in the header when rows are selected */
  renderSelectedActions?: (
    selected: Row<DataType>[],
    table: TableInstance<DataType> | null,
  ) => ReactNode;
  /** The function triggered when a user clicks the select all button */
  onSelectAllClick?: () => void;
  /** The function triggered when a user clicks the cancel button */
  onCancelClick?: () => void;
  /** Panel layer style */
  layerStyle?: string;
  /**
   * shows column visibility toggling menu in top right of panel, columns must
   * have a `Header` prop in order to be togglable.
   * */
  isColumnVisibilityTogglable?: boolean;
  /**
   * a subset of column ids for which visibility is togglable, only considered
   * if isColumnVisibilityTogglable is true.
   * If unspecified, all columns that have a Header are considered togglable
   * */
  visibilityTogglableColumns?: IdType<DataType>[];
  beforeTable?: ReactNode;
  hasSelectedAllRows?: boolean;
  panelProps?: PanelProps;
  panelHeaderProps?: PanelHeaderProps;
  panelHeadingProps?: PanelHeadingProps;
}

const _DataTablePanel = <DataType extends {}>( // eslint-disable-line @typescript-eslint/no-empty-object-type
  props: DataTablePanelProps<DataType>,
  ref:
    | ((instance: TableInstance<DataType>) => void)
    | React.RefObject<TableInstance<DataType>>
    | null
    | undefined,
) => {
  return (
    <DataTableContextProvider data={props.data}>
      <DataTablePanelComponent {...props} ref={ref} />
    </DataTableContextProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const DataTablePanelComponentImpl = <DataType extends {}>(
  {
    data,
    selectAllText,
    cancelText,
    isLoading,
    isSelectAllHidden,
    isCancelHidden,
    numberOfSelectAllItems,
    selectAllProps,
    formatHeading = ({ totalLength }) =>
      `${totalLength} Row${totalLength > 1 ? 's' : ''}`,
    formatSelectedHeading = (rows) =>
      `${rows} Row${rows > 1 ? 's' : ''} Selected`,
    renderSelectedActions,
    onRowSelection,
    onSelectAllClick,
    onCancelClick,
    'data-testid': dataTestId = 'DTPanel',
    layerStyle = 'first',
    afterHeading,
    isColumnVisibilityTogglable,
    visibilityTogglableColumns = [],
    beforeTable,
    hasSelectedAllRows,
    panelProps,
    panelHeaderProps,
    panelHeadingProps,
    ...tableProps
  }: DataTablePanelProps<DataType>,
  ref:
    | ((instance: TableInstance<DataType>) => void)
    | React.RefObject<TableInstance<DataType>>
    | null
    | undefined,
) => {
  const {
    totalLength: [totalLength],
    curPageLength: [curPageLength],
    filteredLength: [filteredLength],
  } = useDataTableContext<DataType>();
  const { i18n } = useContext(gammaContext);
  const tableRef = useRef<TableInstance<DataType>>(null);
  const [selectedRows, setSelectedRows] = useState<Row<DataType>[]>([]);
  const oldSelectedRows = usePrevious(selectedRows);
  const hasSelectedRows = useMemo(
    () => Boolean(selectedRows.length),
    [selectedRows],
  );

  useEffect(() => {
    if (tableRef.current) {
      setSelectedRows(tableRef.current.selectedFlatRows);
    }
  }, []);

  const handleRowSelection = (rows: Row<DataType>[]) => {
    onRowSelection?.(rows);
    setSelectedRows(rows);
  };

  const selectedActions = useMemo(() => {
    return renderSelectedActions?.(selectedRows, tableRef.current);
  }, [selectedRows, renderSelectedActions]);

  // select all rows IN TABLE
  const handleSelectAllClick = () => {
    tableRef.current?.toggleAllRowsSelected(!hasSelectedAllRows);
    onSelectAllClick?.();
  };

  // unselect all rows in table
  const handleCancelClick = () => {
    tableRef.current?.toggleAllRowsSelected(false);
    onCancelClick?.();
  };

  const animate = hasSelectedRows ? 'selected' : 'inactive';

  return (
    <Panel
      h="auto"
      data-testid={dataTestId}
      position="relative"
      layerStyle={layerStyle}
      {...panelProps}
    >
      <PanelHeader
        minH={HEADER_SIZE}
        maxH={HEADER_SIZE}
        as="div"
        overflow="hidden"
        w="calc(100% + (var(--chakra-space-4) * 2))"
        data-testid={`${dataTestId}-header`}
        {...panelHeaderProps}
      >
        <AnimatePresence>
          {selectedRows.length > 0 ? (
            <MotionPH
              exit="inactive"
              initial="inactive"
              variants={selectedVariants}
              animate={animate}
              maxHeight={HEADER_SIZE} // force max height to be 48px to avoid jittering during animations
              key="selected-header"
              bg="blue.500"
              w="calc(100% + (var(--chakra-space-6) * 2))"
              my={-3}
              data-testid={`${dataTestId}-selected-header`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <PanelHeading
                data-testid={`${dataTestId}-selected-rows-count`}
                color="gray.50"
                {...panelHeadingProps}
              >
                {formatSelectedHeading(
                  numberOfSelectAllItems
                    ? numberOfSelectAllItems
                    : selectedRows?.length,
                  {
                    totalLength,
                    curPageLength,
                    filteredLength,
                  },
                )}
              </PanelHeading>
              <ButtonGroup
                variant="outline"
                my={-1}
                overflow="hidden"
                data-testid={`${dataTestId}-selected-actions-group`}
                isDisabled={selectAllProps?.isDisabled}
              >
                {selectedActions}
                {selectedActions && !(isSelectAllHidden && isCancelHidden) ? (
                  <Divider
                    orientation="vertical"
                    borderColor="gray.50"
                    height={8}
                  />
                ) : undefined}
                {!isSelectAllHidden && (
                  <Button
                    variant="dataTablePanelSelectedAction"
                    onClick={handleSelectAllClick}
                    data-testid={`${dataTestId}-selected-actions-select-all`}
                    leftIcon={
                      selectAllProps?.isCustomSelectAll ? (
                        selectAllProps.leftIcon
                      ) : (
                        <MuiIcon mb={-1}>library_add_check</MuiIcon>
                      )
                    }
                  >
                    {selectAllText || i18n.globals.selectAll}
                  </Button>
                )}
                {!isCancelHidden && (
                  <Button
                    variant="dataTablePanelSelectedAction"
                    onClick={handleCancelClick}
                    data-testid={`${dataTestId}-selected-actions-cancel`}
                  >
                    {cancelText || i18n.globals.cancel}
                  </Button>
                )}
              </ButtonGroup>
            </MotionPH>
          ) : (
            <MotionPH
              transformTemplate={template}
              exit="selected"
              initial={
                (oldSelectedRows?.length ?? 0) > 0 ? 'selected' : 'inactive'
              }
              variants={inactiveVariants}
              animate={animate}
              maxHeight={HEADER_SIZE} // force max height to be 48px to avoid jittering during animations
              key="inactive-header"
              w="calc(100% + (var(--chakra-space-6) * 2))"
              my={-3}
              data-testid={`${dataTestId}-default-header`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <PanelHeading
                data-testid={`${dataTestId}-default-heading`}
                {...panelHeadingProps}
              >
                {formatHeading({
                  totalLength,
                  curPageLength,
                  filteredLength,
                })}
              </PanelHeading>
              <HStack spacing={4} alignItems="center">
                {afterHeading}
                {isColumnVisibilityTogglable && tableRef.current?.columns && (
                  <DataTableColumnVisibilityMenu
                    visibilityTogglableColumns={visibilityTogglableColumns}
                  />
                )}
              </HStack>
            </MotionPH>
          )}
        </AnimatePresence>
      </PanelHeader>
      {beforeTable}
      <FlushPanelContent mt={beforeTable ? 0 : undefined}>
        <DataTable
          {...tableProps}
          hasContextProvider={true}
          isColumnVisibilityTogglable={isColumnVisibilityTogglable}
          layerStyle={layerStyle}
          data={data}
          onRowSelection={handleRowSelection}
          data-testid={`${dataTestId}-table`}
          ref={ref ? mergeRefs(ref, tableRef) : tableRef}
          emptyBodyContent={
            isLoading ? (
              <LoadingSpinner />
            ) : (
              (tableProps.emptyBodyContent ?? <Center>No Data</Center>)
            )
          }
        />
      </FlushPanelContent>
    </Panel>
  );
};

export const DataTablePanelComponent = forwardRef(
  DataTablePanelComponentImpl,
) as <
  DataType extends {}, // eslint-disable-line @typescript-eslint/no-empty-object-type
>(
  props: DataTablePanelProps<DataType> & {
    ref?:
      | ((instance: TableInstance<DataType>) => void)
      | React.RefObject<TableInstance<DataType>>
      | null
      | undefined;
  },
) => ReturnType<typeof DataTablePanelComponentImpl>;

export const DataTablePanel = forwardRef(_DataTablePanel) as <
  DataType extends {}, // eslint-disable-line @typescript-eslint/no-empty-object-type
>(
  props: DataTablePanelProps<DataType> & {
    ref?:
      | ((instance: TableInstance<DataType>) => void)
      | React.RefObject<TableInstance<DataType>>
      | null
      | undefined;
  },
) => ReturnType<typeof DataTablePanelComponentImpl>;

const MotionPH = motion(PanelHeader);

const selectedVariants: Variants = {
  selected: {
    y: '0px',
    height: HEADER_SIZE,
    minHeight: HEADER_SIZE,
    transition: {
      ease: 'easeInOut',
    },
  },
  inactive: {
    y: `-${HEADER_SIZE}`,
    height: '0px',
    minHeight: '0px',
    transition: {
      ease: 'easeInOut',
    },
  },
};

const inactiveVariants: Variants = {
  selected: {
    y: HEADER_SIZE,
    height: '0px',
    minHeight: '0px',
    transition: {
      ease: 'easeInOut',
    },
  },
  inactive: {
    y: '0px',
    height: HEADER_SIZE,
    minHeight: HEADER_SIZE,
    transition: {
      ease: 'easeInOut',
    },
  },
};

const template: ComponentPropsWithoutRef<
  typeof MotionPH
>['transformTemplate'] = ({ y }) => {
  if (y === '0px') {
    return 'none';
  }
  return `translateY(${y})`;
};
