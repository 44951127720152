import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { GetDetectionMethodSummaryResponse } from './types';

const DETECTION_METHOD_SUMMARY_QUERY = gql`
  query GetDetectionMethodSummary($tenant: String!) {
    getDetectionMethodSummary(tenant: $tenant) {
      detection_method
      total
      enabled
    }
  }
`;

export const useGetDetectionMethodSummary = (
  options: QueryHookOptions<
    GetDetectionMethodSummaryResponse,
    { tenant: string }
  >,
) => {
  return useQuery<GetDetectionMethodSummaryResponse, { tenant: string }>(
    DETECTION_METHOD_SUMMARY_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      skip: !options.variables?.tenant,
    },
  );
};
