import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  useToast,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { createContext, ReactNode, useEffect, useMemo, useRef } from 'react';
import LocalizedStrings, {
  LocalizedStrings as LocalizedStringsType,
} from 'react-localization';

import { Toast } from '@gamma/toast';
import { gammaI18n, GammaI18nType } from '../i18n';
import { en } from '../i18n/en';
import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  layerStyles,
  letterSpacings,
  lineHeights,
  shadows,
  textStyles,
} from './common';
import { semanticTokens } from './common/tokens';
import { components } from './components';
import { Fonts } from './GlobalStyles';

export const themeOverrides = extendTheme({
  colors,
  components,
  fonts,
  lineHeights,
  fontSizes,
  letterSpacings,
  textStyles,
  shadows,
  semanticTokens,
  layerStyles,
  breakpoints,
  radii: {
    base: '4px',
  },
  config: {
    initialColorMode: localStorage.getItem('chakra-ui-color-mode') ?? 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: (_props: unknown) => ({
      html: {
        WebkitFontSmoothing: 'auto',
        MozOsxFontSmoothing: 'auto',
      },
      body: {
        bg: 'layer.0',
        color: 'text.primary',
        height: '100vh',
        overflow: 'hidden',
        fontSize: 'md',
        fontFamily: 'body',
        lineHeight: 'md',
      },
      '.select__menu-portal': {
        zIndex: 'var(--chakra-zIndices-tooltip) !important',
      },
      '.chakra-collapse[style*="height: auto"]': {
        // this allows AccordionPanel contents to overflow when expanded, needed
        // for select menus to render outside of an expanded accordion panel.
        overflow: 'initial !important',
      },
      // this makes monaco editor line numbers not wrap when they're larger than 5
      '.monaco-editor .margin-view-overlays .line-numbers': {
        minWidth: 'fit-content',
      },
    }),
  },
});

interface GammaProviderProps {
  fontPath?: string;
  children: ReactNode;
  theme?: Record<string, unknown>;
  i18n?: LocalizedStringsType<
    Partial<GammaI18nType> & { [index: string]: unknown }
  >;
}

export interface GammaContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: LocalizedStringsType<GammaI18nType & { [index: string]: any }>;
}

export const gammaContext = createContext<GammaContext>({
  i18n: gammaI18n,
});

export const GammaProvider = ({
  fontPath = '/static/fonts/',
  children,
  theme: propsTheme = {},
  i18n,
}: GammaProviderProps) => {
  const mounted = useRef(false);
  const toast = useToast();
  const extendedTheme = extendTheme(themeOverrides, propsTheme);

  const localization = useMemo(() => {
    return new LocalizedStrings({ en: { ...en, ...i18n } });
  }, [i18n]);

  useEffect(() => {
    // this effect prevents a FOUT issue with the icon font. Its fairly
    // complicated, but the gist is to unhide the icon font with a css class
    // AFTER fonts are loaded.

    if (mounted.current || !document.fonts) {
      // prevents strict mode from multi-firing
      return;
    }

    mounted.current = true;

    const onError = (e: unknown) => {
      let description =
        'Icons cannot be rendered, please refresh the browser to restart icon loading attempt.';
      if (e instanceof Error) {
        description = `${description} Error: ${e.message}`;
      }

      toast({
        status: 'error',
        title: 'Error loading icons',
        description,
      });
    };

    document.fonts.ready
      .then(async (fonts) => {
        // @font-face loads fonts lazily, so if we happen to load on a page
        // that does not use the icon font, it wont be loaded at this time.

        // we also don't want to FORCE the fontFace to load with something like
        // font.load indiscriminately. If we load via CSS and via font.load
        // there can be cases where larger assets can be rejected for
        // TOO_MANY_RETRIES.

        // So, instead, we wait passively for document.fonts.ready THEN test if
        // the icon font was loaded. Only if it has not loaded do we force the
        // loading.

        const iconFontFamily = 'Material Symbols Sharp';
        const fontDict: Record<string, FontFace> = {};

        fonts.forEach((font) => {
          fontDict[font.family] = font;
        });

        const iconFont = fontDict[iconFontFamily];
        if (iconFont && iconFont.status !== 'loaded') {
          try {
            await iconFont.load();
          } catch (e) {
            onError(e);
          }
        }

        document.body.classList.add('icons-loaded');
      })
      .catch(onError);
  }, []);

  return (
    <gammaContext.Provider value={{ i18n: localization }}>
      <ChakraProvider
        theme={extendedTheme}
        toastOptions={{ defaultOptions: { render: Toast } }}
      >
        <ColorModeScript
          initialColorMode={extendedTheme.config.initialColorMode}
        />
        <Fonts fontPath={fontPath} />
        <Global
          styles={`
						#root { height: 100%; }
						.mui-icon {
							opacity: 0
						}
						.icons-loaded .mui-icon {
							opacity: 1
						}
					`}
        />

        {children}
      </ChakraProvider>
    </gammaContext.Provider>
  );
};
