import { i18n } from '@gamma/investigator/localization';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { ChatGPTSettingsCardModal } from '../General/Components';
import { CrowdstrikeSettingsCardModal } from '../General/Components/CrowdstrikeSettingsCardModal';
import { MSDefenderSettingsCardModal } from '../General/Components/MSDefenderSettingsCardModal';
import { ServiceNowSettingsCardModal } from '../General/Components/ServiceNowSettingsCardModal';
import { PaloAltoSettingsModal } from '../General/Components/PaloAltoSettingsModal/PaloAltoSettingsModal';

export interface IntegrationsModalProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  title?: string;
  isEnableChanged: boolean;
  setIsEnableChanged: Dispatch<SetStateAction<boolean>>;
}

export interface IntegrationsData {
  description: string;
  available: boolean;
  featureFlag?: string;
  learnMoreLink: string;
  overviewContent: string;
  pathName: string;
  privilegeName: string;
  settingsModal: null | ((props: IntegrationsModalProps) => ReactNode);
  tags: string[];
  title: string;
  type: 'INVEST' | 'FLEET' | 'CORELIGHT' | 'PARTNER';
  fallbackText?: string;
}

const { integrations } = i18n.pages;
const {
  AIAssistance,
  microsoftDefender,
  microsoftDefenderShortDescription,
  microsoftDefenderDescription,
  enrichment,
  response,
  GPTPrivateData,
  GPTNonPrivateData,
  GPTPrivateDataDescription,
  GPTNonPrivateDataDescription,
  serviceNow: { serviceNow, serviceNowShortDescription },
  crowdStrike: { crowdStrike, crowdStrikeShortDescription },
  paloAltoTitle,
  paloAltoDescription,
  paloAltoOverview,
  ticketCreation,
  chatGPTPrivateOverview,
  chatGPTNonPrivateOverview,
  crowdstrikeOverview,
  serviceNowOverview,
  awsAthenaTitle,
  awsTitle,
  axoniusTitle,
  criblTitle,
  crowdstrikeExportTitle,
  customSyslogTitle,
  customJsonTitle,
  devoTitle,
  elasticHttpTitle,
  endaceTitle,
  googleJsonTitle,
  googleDynamicTitle,
  kafkaTitle,
  microsoftSentinelTitle,
  mispTitle,
  reversingLabsTitle,
  securonixTitle,
  splunkForwarderTitle,
  splunkHecFleetTitle,
  sumologicTitle,
  // threatqTitle,
  crowdstrikeContentTitle,
  elasticContentTitle,
  googleSecopsTitle,
  microsoftContentTitle,
  sentinelOneContentTitle,
  splunkContentTitle,
  splunkPhantomTitle,
  alienVaultTitle,
  analyst1IocTitle,
  analyst1YaraTitle,
  analyist1SuricataTitle,
  crowdstrikeFalconTitle,
  crowdstrikeIocTitle,
  crowdstrikeYaraTitle,
  crowdstrikeSuricataTitle,
  customEnrichLogsTitle,
  customIocTitle,
  customYaraTitle,
  customSuricataTitle,
  fireeyeTitle,
  mandiantTitle,
  microsoftSplunkTitle,
  proofpointTitle,
  sentinelOneEnrichmentTitle,
  stixTaxiiTitle,
  tenableTitle,
  threatqTitle,
  awsAthenaShortDescription,
  awsShortDescription,
  axoniusShortDescription,
  criblShortDescription,
  crowdstrikeExportShortDescription,
  customSyslogShortDescription,
  customJsonShortDescription,
  devoShortDescription,
  elasticHttpShortDescription,
  endaceShortDescription,
  googleJsonShortDescription,
  googleDynamicShortDescription,
  kafkaShortDescription,
  microsoftSentinelShortDescription,
  mispShortDescription,
  reversingLabsShortDescription,
  securonixShortDescription,
  splunkForwarderShortDescription,
  splunkHecFleetShortDescription,
  sumologicShortDescription,
  // threatqShortDescription,
  crowdstrikeContentShortDescription,
  elasticContentShortDescription,
  googleSecopsShortDescription,
  microsoftContentShortDescription,
  sentinelOneContentShortDescription,
  splunkContentShortDescription,
  splunkPhantomShortDescription,
  alienVaultShortDescription,
  analyst1IocShortDescription,
  analyst1YaraShortDescription,
  analyist1SuricataShortDescription,
  crowdstrikeFalconShortDescription,
  crowdstrikeIocShortDescription,
  crowdstrikeYaraShortDescription,
  crowdstrikeSuricataShortDescription,
  customEnrichLogsShortDescription,
  customIocShortDescription,
  customYaraShortDescription,
  customSuricataShortDescription,
  fireeyeShortDescription,
  mandiantShortDescription,
  microsoftSplunkShortDescription,
  proofpointShortDescription,
  sentinelOneEnrichmentShortDescription,
  stixTaxiiShortDescription,
  tenableShortDescription,
  // threatqShortDescription,
  awsAthenaOverview,
  awsOverview,
  axoniusOverview,
  // criblOverview,
  crowdstrikeExportOverview,
  customSyslogOverview,
  customJsonOverview,
  devoOverview,
  elasticHttpOverview,
  endaceOverview,
  googleJsonOverview,
  googleDynamicOverview,
  kafkaOverview,
  microsoftSentinelOverview,
  mispOverview,
  reversingLabsOverview,
  securonixOverview,
  splunkForwarderOverview,
  splunkHecFleetOverview,
  sumologicOverview,
  threatqIocOverview,
  threatqIocShortDescription,
  crowdstrikeContentOverview,
  elasticContentOverview,
  googleSecopsOverview,
  microsoftContentOverview,
  sentinelOneContentOverview,
  splunkContentOverview,
  splunkPhantomOverview,
  alienVaultOverview,
  analyst1IocOverview,
  analyst1YaraOverview,
  analyist1SuricataOverview,
  crowdstrikeFalconOverview,
  crowdstrikeIocOverview,
  crowdstrikeYaraOverview,
  crowdstrikeSuricataOverview,
  customEnrichLogsOverview,
  customIocOverview,
  customYaraOverview,
  customSuricataOverview,
  fireeyeOverview,
  mandiantOverview,
  microsoftSplunkOverview,
  proofpointOverview,
  sentinelOneEnrichmentOverview,
  stixTaxiiOverview,
  tenableOverview,
  threatqFleetOverview,
  update,
  sensor,
  partner,
  firewall,
  threatqFleetShortDescription,
  tags: {
    threatIntel,
    ingest,
    awsAthena,
    logExport,
    caasm,
    edr,
    siem,
    contentPackage,
    pcap,
    hostedFile,
    sandbox,
    soar,
    playbook,
    vulnerabilityManagement,
  },
} = integrations;

export const emptyIntegrationsData: IntegrationsData = {
  available: true,
  title: '',
  description: '',
  pathName: '',
  privilegeName: '',
  settingsModal: null,
  learnMoreLink: '',
  overviewContent: '',
  type: 'INVEST',
  tags: [],
  fallbackText: '',
};

export const IntegrationsConstants: Record<string, IntegrationsData> = {
  'microsoft-defender': {
    available: true,
    title: microsoftDefender,
    description: microsoftDefenderShortDescription,
    pathName: 'microsoft-defender',
    privilegeName: 'microsoft-defender',
    settingsModal: (props) => <MSDefenderSettingsCardModal {...props} />,
    learnMoreLink: '',
    overviewContent: microsoftDefenderDescription,
    tags: [enrichment, response],
    type: 'INVEST',
  },
  'chatgpt-private-data': {
    available: true,
    title: GPTPrivateData,
    description: GPTPrivateDataDescription,
    featureFlag: 'llm_summary',
    pathName: 'chatgpt-private',
    privilegeName: 'chatgpt_private_data',
    settingsModal: (props) => {
      return <ChatGPTSettingsCardModal isPrivateDataCard={true} {...props} />;
    },
    learnMoreLink: '',
    overviewContent: chatGPTPrivateOverview,
    tags: [AIAssistance],
    type: 'INVEST',
  },
  chatgpt: {
    available: true,
    title: GPTNonPrivateData,
    description: GPTNonPrivateDataDescription,
    pathName: 'chatgpt',
    privilegeName: 'chatgpt',
    settingsModal: (props) => {
      return <ChatGPTSettingsCardModal isPrivateDataCard={false} {...props} />;
    },
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/investigator/settings/gtp-settings.html',
    overviewContent: chatGPTNonPrivateOverview,
    tags: [AIAssistance],
    type: 'INVEST',
  },
  'service-now': {
    available: true,
    title: serviceNow,
    description: serviceNowShortDescription,
    pathName: 'service-now',
    privilegeName: 'service-now',
    settingsModal: (props) => <ServiceNowSettingsCardModal {...props} />,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/investigator/settings/service-now-settings.html',
    overviewContent: serviceNowOverview,
    tags: [ticketCreation],
    type: 'INVEST',
  },
  crowdstrike: {
    available: true,
    title: crowdStrike,
    description: crowdStrikeShortDescription,
    pathName: 'crowdstrike',
    privilegeName: 'crowdstrike',
    settingsModal: (props) => <CrowdstrikeSettingsCardModal {...props} />,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/investigator/settings/crowdstrike-edr.html',
    overviewContent: crowdstrikeOverview,
    tags: [enrichment, response],
    type: 'INVEST',
  },
  'palo-alto-networks': {
    available: true,
    title: paloAltoTitle,
    description: paloAltoDescription,
    pathName: 'palo-alto-networks',
    privilegeName: 'palo-alto-networks',
    settingsModal: (props) => <PaloAltoSettingsModal {...props} />,
    learnMoreLink: '',
    // TODO: enable this when live
    // learnMoreLink: '/docs/settings/palo-alto.html',
    overviewContent: paloAltoOverview,
    tags: [firewall, response],
    type: 'INVEST',
  },
  'aws-athena': {
    available: true,
    title: awsAthenaTitle,
    description: awsAthenaShortDescription,
    pathName: 'aws-athena',
    privilegeName: 'aws-athena',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/kinesis.html',
    overviewContent: awsAthenaOverview,
    tags: [awsAthena, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  aws: {
    available: true,
    title: awsTitle,
    description: awsShortDescription,
    pathName: 'aws',
    privilegeName: 'aws',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/kinesis.html',
    overviewContent: awsOverview,
    tags: [logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  axonius: {
    available: true,
    title: axoniusTitle,
    description: axoniusShortDescription,
    pathName: 'axonius',
    privilegeName: 'axonius',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/axonius.html',
    overviewContent: axoniusOverview,
    tags: [caasm, enrichment],
    type: 'FLEET',
    fallbackText: update,
  },
  cribl: {
    available: true,
    title: criblTitle,
    description: criblShortDescription,
    pathName: 'cribl',
    privilegeName: 'cribl',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: '',
    tags: [logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'crowdstrike-export': {
    available: true,
    title: crowdstrikeExportTitle,
    description: crowdstrikeExportShortDescription,
    pathName: 'crowdstrike-export',
    privilegeName: 'crowdstrike-export',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/crwdlog_exp.html',
    overviewContent: crowdstrikeExportOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'custom-syslog': {
    available: true,
    title: customSyslogTitle,
    description: customSyslogShortDescription,
    pathName: 'custom-syslog',
    privilegeName: 'custom-syslog',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/syslog.html',
    overviewContent: customSyslogOverview,
    tags: [logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'custom-json': {
    available: true,
    title: customJsonTitle,
    description: customJsonShortDescription,
    pathName: 'custom-json',
    privilegeName: 'custom-json',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/json_tcp.html',
    overviewContent: customJsonOverview,
    tags: [logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  devo: {
    available: true,
    title: devoTitle,
    description: devoShortDescription,
    pathName: 'devo',
    privilegeName: 'devo',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/json_tcp.html',
    overviewContent: devoOverview,
    tags: [siem],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'elastic-http': {
    available: true,
    title: elasticHttpTitle,
    description: elasticHttpShortDescription,
    pathName: 'elastic-http',
    privilegeName: 'elastic-http',
    settingsModal: null,
    learnMoreLink:
      'https://investigator.corelight.com/docs/settings/alert-export.html',
    overviewContent: elasticHttpOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  endace: {
    available: true,
    title: endaceTitle,
    description: endaceShortDescription,
    pathName: 'endace',
    privilegeName: 'endace',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: endaceOverview,
    tags: [pcap, enrichment],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'google-json': {
    available: true,
    title: googleJsonTitle,
    description: googleJsonShortDescription,
    pathName: 'google-json',
    privilegeName: 'google-json',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/goosecops_exp.html',
    overviewContent: googleJsonOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'google-dynamic': {
    available: true,
    title: googleDynamicTitle,
    description: googleDynamicShortDescription,
    pathName: 'google-dynamic',
    privilegeName: 'google-dynamic',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/goopubsub_exp.html',
    overviewContent: googleDynamicOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  kafka: {
    available: true,
    title: kafkaTitle,
    description: kafkaShortDescription,
    pathName: 'kafka',
    privilegeName: 'kafka',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/sensor/export/kafka.html#kafka',
    overviewContent: kafkaOverview,
    tags: [logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'microsoft-sentinel': {
    available: true,
    title: microsoftSentinelTitle,
    description: microsoftSentinelShortDescription,
    pathName: 'microsoft-sentinel',
    privilegeName: 'microsoft-sentinel',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/json_tcp.html',
    overviewContent: microsoftSentinelOverview,
    tags: [siem, logExport],
    type: 'FLEET',

    fallbackText: sensor,
  },
  misp: {
    available: true,
    title: mispTitle,
    description: mispShortDescription,
    pathName: 'misp',
    privilegeName: 'misp',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/misp.html',
    overviewContent: mispOverview,
    tags: [threatIntel, ingest],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'reversing-labs': {
    available: true,
    title: reversingLabsTitle,
    description: reversingLabsShortDescription,
    pathName: 'reversing-labs',
    privilegeName: 'reversing-labs',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: reversingLabsOverview,
    tags: [sandbox],
    type: 'FLEET',
    fallbackText: sensor,
  },
  securonix: {
    available: true,
    title: securonixTitle,
    description: securonixShortDescription,
    pathName: 'securonix',
    privilegeName: 'securonix',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/json_tcp.html',
    overviewContent: securonixOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'splunk-forwarder': {
    available: true,
    title: splunkForwarderTitle,
    description: splunkForwarderShortDescription,
    pathName: 'splunk-forwarder',
    privilegeName: 'splunk-forwarder',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/sensor/export/splunk.html',
    overviewContent: splunkForwarderOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'splunk-hec-fleet': {
    available: true,
    title: splunkHecFleetTitle,
    description: splunkHecFleetShortDescription,
    pathName: 'splunk-hec-fleet',
    privilegeName: 'splunk-hec-fleet',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/splunk_hec.html',
    overviewContent: splunkHecFleetOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  sumologic: {
    available: true,
    title: sumologicTitle,
    description: sumologicShortDescription,
    pathName: 'sumologic',
    privilegeName: 'sumologic',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.com/docs/sensor/sensor/export/syslog.html',
    overviewContent: sumologicOverview,
    tags: [siem, logExport],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'threatq-ioc': {
    available: true,
    title: threatqTitle,
    description: threatqIocShortDescription,
    pathName: 'threatq-ioc',
    privilegeName: 'threatq-ioc',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/threatq.html',
    overviewContent: threatqIocOverview,
    tags: [threatIntel, ingest],
    type: 'FLEET',
    fallbackText: sensor,
  },
  'crowdstrike-partner': {
    available: true,
    title: crowdstrikeContentTitle,
    description: crowdstrikeContentShortDescription,
    pathName: 'crowdstrike-partner',
    privilegeName: 'crowdstrike-partner',
    settingsModal: null,
    learnMoreLink: 'https://marketplace.crowdstrike.com/partners/corelight',
    overviewContent: crowdstrikeContentOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'elastic-content': {
    available: true,
    title: elasticContentTitle,
    description: elasticContentShortDescription,
    pathName: 'elastic-content',
    privilegeName: 'elastic-content',
    settingsModal: null,
    learnMoreLink:
      'https://www.elastic.co/guide/en/integrations/current/corelight.html',
    overviewContent: elasticContentOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'google-secops': {
    available: true,
    title: googleSecopsTitle,
    description: googleSecopsShortDescription,
    pathName: 'google-secops',
    privilegeName: 'google-secops',
    settingsModal: null,
    learnMoreLink:
      'https://cloud.google.com/chronicle/docs/ingestion/default-parsers/collect-corelight-logs',
    overviewContent: googleSecopsOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'microsoft-content': {
    available: true,
    title: microsoftContentTitle,
    description: microsoftContentShortDescription,
    pathName: 'microsoft-content',
    privilegeName: 'microsoft-content',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: microsoftContentOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'sentinel-one-content': {
    available: true,
    title: sentinelOneContentTitle,
    description: sentinelOneContentShortDescription,
    pathName: 'sentinel-one-content',
    privilegeName: 'sentinel-one-content',
    settingsModal: null,
    learnMoreLink:
      'https://www.sentinelone.com/partners/singularity-marketplace/',
    overviewContent: sentinelOneContentOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'splunk-content': {
    available: true,
    title: splunkContentTitle,
    description: splunkContentShortDescription,
    pathName: 'splunk-content',
    privilegeName: 'splunk-content',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: splunkContentOverview,
    tags: [siem, contentPackage],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'splunk-phantom': {
    available: true,
    title: splunkPhantomTitle,
    description: splunkPhantomShortDescription,
    pathName: 'splunk-phantom',
    privilegeName: 'splunk-phantom',
    settingsModal: null,
    learnMoreLink: 'https://github.com/corelight/phantom-playbooks',
    overviewContent: splunkPhantomOverview,
    tags: [soar, playbook],
    type: 'PARTNER',
    fallbackText: partner,
  },
  'alien-vault': {
    available: true,
    title: alienVaultTitle,
    description: alienVaultShortDescription,
    pathName: 'alien-vault',
    privilegeName: 'alien-vault',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/otx.html',
    overviewContent: alienVaultOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'analyst1-ioc': {
    available: true,
    title: analyst1IocTitle,
    description: analyst1IocShortDescription,
    pathName: 'analyst1-ioc',
    privilegeName: 'analyst1-ioc',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: analyst1IocOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',

    fallbackText: update,
  },
  'analyst1-yara': {
    available: true,
    title: analyst1YaraTitle,
    description: analyst1YaraShortDescription,
    pathName: 'analyst1-yara',
    privilegeName: 'analyst1-yara',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: analyst1YaraOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'analyst1-suricata': {
    available: true,
    title: analyist1SuricataTitle,
    description: analyist1SuricataShortDescription,
    pathName: 'analyst1-suricata',
    privilegeName: 'analyst1-suricata',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: analyist1SuricataOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',

    fallbackText: update,
  },
  'crowdstrike-falcon': {
    available: true,
    title: crowdstrikeFalconTitle,
    description: crowdstrikeFalconShortDescription,
    pathName: 'crowdstrike-falcon',
    privilegeName: 'crowdstrike-falcon',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/crowdStrikeExposureMgmt.html',
    overviewContent: crowdstrikeFalconOverview,
    tags: [edr, enrichment],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'crowdstrike-ioc': {
    available: true,
    title: crowdstrikeIocTitle,
    description: crowdstrikeIocShortDescription,
    pathName: 'crowdstrike-ioc',
    privilegeName: 'crowdstrike-ioc',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/crowdStrikeThreatIntel.html',
    overviewContent: crowdstrikeIocOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'crowdstrike-yara': {
    available: true,
    title: crowdstrikeYaraTitle,
    description: crowdstrikeYaraShortDescription,
    pathName: 'crowdstrike-yara',
    privilegeName: 'crowdstrike-yara',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: crowdstrikeYaraOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'crowdstrike-suricata': {
    available: true,
    title: crowdstrikeSuricataTitle,
    description: crowdstrikeSuricataShortDescription,
    pathName: 'crowdstrike-suricata',
    privilegeName: 'crowdstrike-suricata',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/crowdStrikeSuricata.html',
    overviewContent: crowdstrikeSuricataOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'custom-enrich-logs': {
    available: true,
    title: customEnrichLogsTitle,
    description: customEnrichLogsShortDescription,
    pathName: 'custom-enrich-logs',
    privilegeName: 'custom-enrich-logs',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/input/index.html',
    overviewContent: customEnrichLogsOverview,
    tags: [enrichment],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'custom-ioc': {
    available: true,
    title: customIocTitle,
    description: customIocShortDescription,
    pathName: 'custom-ioc',
    privilegeName: 'custom-ioc',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/intel/index.html',
    overviewContent: customIocOverview,
    tags: [ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'custom-yara': {
    available: true,
    title: customYaraTitle,
    description: customYaraShortDescription,
    pathName: 'custom-yara',
    privilegeName: 'custom-yara',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: customYaraOverview,
    tags: [ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'custom-suricata': {
    available: true,
    title: customSuricataTitle,
    description: customSuricataShortDescription,
    pathName: 'custom-suricata',
    privilegeName: 'custom-suricata',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/suricata/index.html',
    overviewContent: customSuricataOverview,
    tags: [hostedFile, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  fireeye: {
    available: true,
    title: fireeyeTitle,
    description: fireeyeShortDescription,
    pathName: 'fireeye',
    privilegeName: 'fireeye',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/fireEye.html',
    overviewContent: fireeyeOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  mandiant: {
    available: true,
    title: mandiantTitle,
    description: mandiantShortDescription,
    pathName: 'mandiant',
    privilegeName: 'mandiant',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/mandiantThreatIntel.html',
    overviewContent: mandiantOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'microsoft-splunk': {
    available: true,
    title: microsoftSplunkTitle,
    description: microsoftSplunkShortDescription,
    pathName: 'microsoft-splunk',
    privilegeName: 'microsoft-splunk',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/sensor/export/defender.html',
    overviewContent: microsoftSplunkOverview,
    tags: [enrichment],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  proofpoint: {
    available: true,
    title: proofpointTitle,
    description: proofpointShortDescription,
    pathName: 'proofpoint',
    privilegeName: 'proofpoint',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: proofpointOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'sentinel-one-enrichment': {
    available: true,
    title: sentinelOneEnrichmentTitle,
    description: sentinelOneEnrichmentShortDescription,
    pathName: 'sentinel-one-enrichment',
    privilegeName: 'sentinel-one-enrichment',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/sentinelOne.html',
    overviewContent: sentinelOneEnrichmentOverview,
    tags: [edr, enrichment],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  'stix-taxii': {
    available: true,
    title: stixTaxiiTitle,
    description: stixTaxiiShortDescription,
    pathName: 'stix-taxii',
    privilegeName: 'stix-taxii',
    settingsModal: null,
    learnMoreLink: '',
    overviewContent: stixTaxiiOverview,
    tags: [threatIntel, ingest],
    type: 'CORELIGHT',
    fallbackText: update,
  },
  tenable: {
    available: true,
    title: tenableTitle,
    description: tenableShortDescription,
    pathName: 'tenable',
    privilegeName: 'tenable',
    settingsModal: null,
    learnMoreLink:
      'https://docs.corelight.services/docs/sensor/corelight-update/policyConfiguration/sources/integrations/tenable.html',
    overviewContent: tenableOverview,
    tags: [vulnerabilityManagement, enrichment],
    type: 'CORELIGHT',
    fallbackText: update,
  },
};
