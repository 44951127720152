import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { ChatGPTToolTip } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';
import { ErrorMessage, GraphQLReqStatus } from '@gamma/progress';
import { SessionSummaryHeaders } from '@gamma/investigator/queries';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components/DetailsGrid';
import { MuiIcon } from '@gamma/icons';
import { SessionSummaryData } from '@gamma/investigator/queries';

export interface SessionSummaryProps {
  sessionSummaryData?: SessionSummaryData;
  error?: ErrorMessage;
  loading: boolean;
}

export const SessionSummary = ({
  sessionSummaryData,
  error,
  loading,
}: SessionSummaryProps) => {
  const { detection } = i18n.pages.detections;
  const { sessionSummary } = i18n.pages.alertDetails;

  const {
    isOpen: sessionSummaryIsOpen,
    onOpen: sessionSummaryOnOpen,
    onClose: sessionSummaryOnClose,
  } = useDisclosure();

  const headerIcons: Record<SessionSummaryHeaders, string> = {
    ioc: 'flag',
    alert_summary: 'report',
    unusual_findings: 'search',
    attack_tactics: 'handyman',
    alert_beacons: 'e911_emergency',
  };

  return error || loading ? (
    <GraphQLReqStatus error={error} loading={loading} />
  ) : (
    <>
      <Button
        px={2}
        marginTop={2}
        height="auto"
        variant="pill"
        w="fit-content"
        colorScheme="gray"
        whiteSpace="initial"
        data-testid="activity-summary-modal-button"
        size="sm"
        leftIcon={<ChatGPTToolTip boxSize={4} />}
        onClick={sessionSummaryOnOpen}
      >
        {sessionSummary.analyzeActivities}
      </Button>
      <Modal
        size="xl"
        isOpen={sessionSummaryIsOpen}
        onClose={sessionSummaryOnClose}
        title={
          <Box display="flex" flexDir="row">
            <ChatGPTToolTip boxSize={6} />
            <Text marginLeft={2}>{sessionSummary.alertConnectionInsights}</Text>
          </Box>
        }
        body={
          sessionSummaryData ? (
            <DetailsGrid templateColumns="1fr" gridRowGap={0}>
              {Object.values(SessionSummaryHeaders).map(
                (header, index, headers) => {
                  const showBorder = index !== headers.length - 1;
                  const borderStyles = {
                    borderBottom: '0.5px solid',
                    borderBottomColor: 'gray',
                  };
                  const rowStyles = {
                    ...(showBorder ? borderStyles : {}),
                    alignItems: 'start',
                  };
                  return (
                    <HStack style={rowStyles} paddingY={4} key={header}>
                      <DetailsGridRow
                        title={
                          <HStack w={240}>
                            <Box style={{ paddingTop: '5px' }}>
                              <MuiIcon size="sm" marginEnd={3}>
                                {headerIcons[header] ?? 'flag'}
                              </MuiIcon>
                            </Box>
                            <Text>{sessionSummary.headers[header]}</Text>
                          </HStack>
                        }
                      >
                        <Box>
                          {sessionSummaryData[header].map((text: string, i) => (
                            <Text key={`${header}-${i}`}>{text}</Text>
                          ))}
                        </Box>
                      </DetailsGridRow>
                    </HStack>
                  );
                },
              )}
            </DetailsGrid>
          ) : (
            <p>{sessionSummary.dataUnavailable}</p>
          )
        }
        footer={
          <Button
            variant="solid"
            colorScheme="gray"
            onClick={sessionSummaryOnClose}
          >
            {detection.close}
          </Button>
        }
      />
    </>
  );
};
