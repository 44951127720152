import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IGetEDREndpointDevices } from './types';

export const GET_EDR_ENDPOINT_DEVICES = gql`
  query getEdrEndpointDevices(
    $edr_endpoint_device_inputs: [EdrEndpointDeviceInput!]!
    $offset: Int
    $size: Int
  ) {
    getEdrEndpointDevices(
      edr_endpoint_device_inputs: $edr_endpoint_device_inputs
      offset: $offset
      size: $size
    ) {
      offset
      size
      total_items
      edr_endpoint_devices {
        entity_status
        entity_status_updated_timestamp
        tenant
        entity_type
        entity_id
        data_source
        obsts
        expire_at
        last_updated_timestamp
        ... on MicrosoftDefenderEndpointDevice {
          id
          machine {
            mergedIntoMachineId
            isPotentialDuplication
            isExcluded
            exclusionReason
            computerDnsName
            firstSeen
            lastSeen
            osPlatform
            osVersion
            osProcessor
            version
            lastIpAddress
            lastExternalIpAddress
            agentVersion
            osBuild
            healthStatus
            deviceValue
            rbacGroupId
            rbacGroupName
            riskScore
            exposureLevel
            isAadJoined
            aadDeviceId
            machineTags
            defenderAvStatus
            onboardingStatus
            osArchitecture
            managedBy
            managedByStatus
            ipAddresses {
              ipAddress
              macAddress
              type
              operationalStatus
            }
            vmMetadata {
              vmId
              cloudProvider
              resourceId
              subscriptionId
            }
          }
          machine_actions {
            id
            type
            status
            requestor
            requestorComment
            creationDateTimeUtc
            lastUpdateDateTimeUtc
          }
        }
      }
    }
  }
`;

export const useGetEDREndpointDevices = (
  options: QueryHookOptions<IGetEDREndpointDevices, OperationVariables>,
) => useQuery<IGetEDREndpointDevices>(GET_EDR_ENDPOINT_DEVICES, options);
