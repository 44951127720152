import { useApolloClient } from '@apollo/client';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
  useDisclosure,
  usePrevious,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Input, NumberInput, Switch } from '@gamma/form-fields';
import { MuiIcon } from '@gamma/icons';
import { URLS } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  GET_EDR_CONFIGURATION,
  IVerifyEDRConfiguration,
  MicrosoftDefenderConfiguration,
  MicrosoftDefenderConfigurationInput,
  useDeleteEDRConfiguration,
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
  useStoreEDRConfiguration,
  useVerifyEDRConfiguration,
} from '@gamma/investigator/queries';
import { Panel } from '@gamma/layout';
import { Alert as GammaAlert, Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { isEqual } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

const { integrations } = i18n.pages;
const { save, cancel, error, success, warning } = integrations;

const {
  noGoBack,
  yesDelete,
  enabled: enabledLabel,
  disabled: disabledLabel,
  connectionNotVerified,
  connectionVerified: connectionVerifiedMessage,
} = integrations;

const {
  saveSuccess,
  saveError,
  deleteError,
  deleteSuccess,
  isolateEntity,
  isolateHelpText,
  isolateEnabledAlert,
  verifyConnection,
  deleteResult,
  deleteWarning,
  pollingHelpText,
  enterCredentials,
  msdefenderIntegration,
  msdefenderFormFields,
  msdefenderDocumentation,
  deleteMSDefenderConnection,
  connectionVerifiedEnableIntegration,
  detectionsCanBeEscalatedToMSDefender,
  detectionsCanNotBeEscalatedToMSDefender,
} = integrations.msdefender;

const { receiveEntityEnrichment } = integrations;

const { DEFENDER_DOCS } = URLS;

interface MSDefenderSettingsCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  edrConfiguration?: MicrosoftDefenderConfiguration;
}

export const MSDefenderSettingsCardModal = ({
  isOpen,
  onClose,
}: MSDefenderSettingsCardModalProps) => {
  const showToast = useToast();

  const { userRole, setUserPrivileges, userPrivileges, userLicense } =
    useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};

  const [isEnableChanged, setIsEnableChanged] = useState<boolean>(false);
  const client = useApolloClient();

  const logOperationCall = useLogOperationCall();

  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const { loading, error, data } = getEDRConfigQuery({
    variables: {
      provider_name: 'microsoft_defender',
    },
  });

  const edrConfiguration =
    data?.getEDRConfiguration as MicrosoftDefenderConfiguration;

  if (error) {
    return (
      <GraphQLReqStatus
        error={error}
        loading={false}
        isBackground={true}
      ></GraphQLReqStatus>
    );
  }

  const {
    enabled,
    edr_action_enabled,
    app_id,
    app_secret,
    defender_tenant_id,
    polling_seconds,
  } = edrConfiguration || {};

  const defaultFormDataValues = {
    enabled: enabled || false,
    edr_action_enabled: edr_action_enabled || false,
    app_id: app_id || '',
    app_secret: app_secret || '',
    defender_tenant_id: defender_tenant_id || '',
    polling_seconds: polling_seconds ? Math.floor(polling_seconds / 60) : 60,
  };

  const {
    watch,
    control,
    reset,
    register,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultFormDataValues,
  });

  const formData = watch();
  const previousFormData = usePrevious(formData);

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [appSecretInputPwType, setAppSecretInputPwType] =
    useState<boolean>(true);

  const [configuration, setConfiguration] =
    useState<MicrosoftDefenderConfigurationInput>({
      enabled: false,
      edr_action_enabled: false,
      app_id: '',
      app_secret: '',
      defender_tenant_id: '',
      polling_seconds: 60,
    });

  const [configurationVerified, setConfigurationVerified] = useState<
    boolean | undefined
  >();

  const [
    verifyEDRConfiguration,
    {
      data: verifyEDRConfigurationData,
      loading: verifyEDRConfigurationLoading,
      error: verifyEDRConfigurationError,
    },
  ] = useVerifyEDRConfiguration({
    fetchPolicy: 'network-only',
    variables: {
      provider_name: 'microsoft_defender',
      config: {
        microsoft_defender: {
          ...configuration,
          polling_seconds: configuration?.polling_seconds * 60,
        },
      },
    },
    onCompleted: (data: IVerifyEDRConfiguration) => {
      setConfigurationVerified(data?.verifyEDRConfiguration?.verified);
    },
  });

  const handleVerifyEscalationConfiguration = async () => {
    verifyEDRConfiguration();
  };

  const [
    storeEDRConfiguration,
    {
      data: storeEDRConfigurationData,
      loading: storeEDRConfigurationLoading,
      error: storeEDRConfigurationError,
    },
  ] = useStoreEDRConfiguration({
    variables: {
      provider_name: 'microsoft_defender',
      config: {
        microsoft_defender: {
          ...configuration,
          polling_seconds: configuration?.polling_seconds * 60,
        },
      },
    },
    onCompleted: async () => {
      client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getEDRConfiguration',
      });
      client.cache.gc();
      showToast({
        title: success,
        description: saveSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
      if (isEnableChanged && enabled !== formData.enabled) {
        const updatedInput = {
          status: formData.enabled ? 'Enabled' : 'Disabled',
          service_name: 'Microsoft Defender',
        };

        logOperationCall(
          'ENABLE_DISABLE_CONFIGURATION',
          'INTEGRATION',
          JSON.stringify(updatedInput),
        );
      }
      const newUserPrivileges = { ...userPrivileges };
      newUserPrivileges[tenant]['microsoft-defender'] = formData.enabled;
      setUserPrivileges(newUserPrivileges);
      onClose();
    },
    onError: async () => {
      showToast({
        title: error,
        description: saveError,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const handleStoreEDRConfiguration = async () => {
    await storeEDRConfiguration();
    setConfigurationVerified(undefined);
  };

  const [
    deleteEDRConfiguration,
    {
      data: deleteEDRConfigurationData,
      loading: deleteEDRConfigurationLoading,
      error: deleteEDRConfigurationError,
    },
  ] = useDeleteEDRConfiguration({
    variables: {
      provider_name: 'microsoft_defender',
    },
    refetchQueries: [
      {
        query: GET_EDR_CONFIGURATION,
        variables: {
          provider_name: 'microsoft_defender',
        },
      },
    ],
    onCompleted: async () => {
      showToast({
        title: success,
        description: deleteSuccess,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
      onClose();
    },
    onError: async () => {
      showToast({
        title: error,
        description: deleteError,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  const handleDeleteEDRConfiguration = async () => {
    await deleteEDRConfiguration();
    setConfigurationVerified(undefined);
  };

  const handleEnableDisableConfigurations = () => {
    setIsEnableChanged(!isEnableChanged);
  };

  useEffect(() => {
    if (
      previousFormData !== undefined &&
      !isEqual(previousFormData, formData)
    ) {
      setConfigurationVerified(undefined);
    }
  }, [formData, previousFormData]);

  useEffect(() => {
    if (isOpen && edrConfiguration) {
      reset(defaultFormDataValues);
    }
  }, [isOpen, edrConfiguration, reset]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        title={msdefenderIntegration}
        closeOnEsc={!verifyEDRConfigurationLoading}
        isCloseDisabled={verifyEDRConfigurationLoading}
        closeOnOverlayClick={!verifyEDRConfigurationLoading}
        body={
          <VStack as="form" alignItems="flex-start" spacing={4}>
            <Text textStyle="body-md">{receiveEntityEnrichment}</Text>
            {/* @ts-ignore */}
            <Controller
              name="enabled"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Switch
                  inline
                  name={name}
                  isChecked={value}
                  isDisabled={false}
                  onChange={(e) => {
                    onChange(e);
                    handleEnableDisableConfigurations();
                  }}
                  label={formData.enabled ? enabledLabel : disabledLabel}
                />
              )}
            />
            <Panel layerStyle="second">
              <VStack alignItems="start">
                <Tooltip placement="end" label={isolateHelpText}>
                  <HStack>
                    <Text textStyle="body-md">{isolateEntity}</Text>
                    <MuiIcon color="text.primary" size="sm">
                      help
                    </MuiIcon>
                  </HStack>
                </Tooltip>
                <Controller
                  name="edr_action_enabled"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Switch
                      inline
                      name={name}
                      isChecked={value}
                      isDisabled={false}
                      label={
                        formData.edr_action_enabled
                          ? enabledLabel
                          : disabledLabel
                      }
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
                {formData?.edr_action_enabled && (
                  <GammaAlert variant="subtle" status="warning">
                    {isolateEnabledAlert}
                  </GammaAlert>
                )}
              </VStack>
            </Panel>
            <Box>
              <Text>
                {`${enterCredentials} `}
                <Link
                  isExternal
                  href={DEFENDER_DOCS}
                  textDecoration="underline"
                >
                  {msdefenderDocumentation}
                </Link>
                .
              </Text>
            </Box>
            <Input
              {...register('defender_tenant_id', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              label={msdefenderFormFields.defenderTenantId}
            />
            <Input
              {...register('app_id', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              label={msdefenderFormFields.appId}
            />
            <Input
              {...register('app_secret', {
                required: true,
              })}
              isRequired={true}
              isDisabled={false}
              label={msdefenderFormFields.appSecret}
              type={appSecretInputPwType ? 'password' : 'text'}
              rightElement={
                <Button>
                  <MuiIcon
                    cursor="pointer"
                    aria-label={`${
                      appSecretInputPwType ? 'Show' : 'Hide'
                    } Client ID`}
                    onClick={() =>
                      setAppSecretInputPwType(!appSecretInputPwType)
                    }
                  >
                    {appSecretInputPwType ? 'visibility' : 'visibility_off'}
                  </MuiIcon>
                </Button>
              }
            />
            <Controller
              name="polling_seconds"
              control={control}
              rules={{
                min: 30,
                max: 1440,
                required: true,
              }}
              render={({ field: { onChange, value, name } }) => (
                <NumberInput
                  min={30}
                  max={1440}
                  name={name}
                  value={value}
                  stepper={true}
                  isRequired={true}
                  isDisabled={false}
                  tooltip={pollingHelpText}
                  hint={msdefenderFormFields.minThirtyMinutes}
                  label={msdefenderFormFields.pollingMinutes}
                  onChange={(value) => onChange(Number(value))}
                />
              )}
            />
            <ButtonGroup
              w="100%"
              justifyContent="space-between"
              isDisabled={!isValid || configurationVerified}
            >
              <Button
                variant="solid"
                colorScheme="gray"
                leftIcon={<MuiIcon>check_circle</MuiIcon>}
                onClick={async () => {
                  await setConfiguration({
                    enabled: formData?.enabled,
                    edr_action_enabled: formData?.edr_action_enabled,
                    app_id: formData?.app_id,
                    app_secret: formData?.app_secret,
                    defender_tenant_id: formData?.defender_tenant_id,
                    polling_seconds: formData?.polling_seconds,
                  });
                  handleVerifyEscalationConfiguration();
                }}
                isDisabled={!isValid || configurationVerified}
                isLoading={verifyEDRConfigurationLoading}
              >
                {verifyConnection}
              </Button>
              <Tooltip label={deleteMSDefenderConnection}>
                <IconButton
                  variant="solid"
                  colorScheme={
                    (!app_id && !app_secret && !defender_tenant_id) ||
                    deleteEDRConfigurationLoading
                      ? 'gray'
                      : 'red'
                  }
                  onClick={deleteOnOpen}
                  icon={<MuiIcon>delete</MuiIcon>}
                  aria-label={deleteMSDefenderConnection}
                  isLoading={deleteEDRConfigurationLoading}
                  isDisabled={
                    (!app_id && !app_secret && !defender_tenant_id) ||
                    deleteEDRConfigurationLoading
                  }
                />
              </Tooltip>
            </ButtonGroup>
            {configurationVerified === true && (
              <Alert status="success" w="100%">
                <Flex>
                  <MuiIcon color="green.100" mr={2}>
                    check_circle
                  </MuiIcon>
                  <Box>
                    <Text textStyle="body-md-bold">
                      {connectionVerifiedMessage}
                    </Text>
                    <Text>
                      {configuration.enabled
                        ? detectionsCanBeEscalatedToMSDefender
                        : connectionVerifiedEnableIntegration}
                    </Text>
                  </Box>
                </Flex>
              </Alert>
            )}
            {configurationVerified === false && (
              <Alert status="error" w="100%">
                <Flex>
                  <MuiIcon color="red.100" mr={2}>
                    warning
                  </MuiIcon>
                  <Box>
                    <Text textStyle="body-md-bold">
                      {connectionNotVerified}
                    </Text>
                    <Text>{detectionsCanNotBeEscalatedToMSDefender}</Text>
                  </Box>
                </Flex>
              </Alert>
            )}
          </VStack>
        }
        footer={
          <ButtonGroup isDisabled={storeEDRConfigurationLoading}>
            <Button variant="solid" colorScheme="gray" onClick={onClose}>
              {cancel}
            </Button>
            <Button
              variant="solid"
              onClick={async () => {
                await setConfiguration({
                  enabled: formData?.enabled,
                  edr_action_enabled: formData?.edr_action_enabled,
                  app_id: formData?.app_id,
                  app_secret: formData?.app_secret,
                  defender_tenant_id: formData?.defender_tenant_id,
                  polling_seconds: formData?.polling_seconds,
                });
                handleStoreEDRConfiguration();
              }}
              isLoading={storeEDRConfigurationLoading}
              isDisabled={
                storeEDRConfigurationLoading ||
                !(isDirty && isValid) ||
                (formData?.enabled && !configurationVerified)
              }
            >
              {save}
            </Button>
          </ButtonGroup>
        }
      />
      <Modal
        size="lg"
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        title="Delete Microsoft Defender Connection"
        body={
          <Alert variant="subtle" status="warning" w="100%">
            <Flex>
              <MuiIcon color="yellow.100" mr={2}>
                error
              </MuiIcon>
              <Box>
                <Text textStyle="body-md-bold">{warning}</Text>
                <Text>{deleteWarning}</Text>
                <Text>{deleteResult}</Text>
              </Box>
            </Flex>
          </Alert>
        }
        footer={
          <ButtonGroup isDisabled={deleteEDRConfigurationLoading}>
            <Button variant="solid" colorScheme="gray" onClick={deleteOnClose}>
              {noGoBack}
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={async () => {
                await handleDeleteEDRConfiguration();
                deleteOnClose();
              }}
              isLoading={deleteEDRConfigurationLoading}
              isDisabled={deleteEDRConfigurationLoading}
            >
              {yesDelete}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};
