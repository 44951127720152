import {
  Button,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ROUTES, URLS } from '@gamma/investigator/constants';
import {
  AuthContext,
  EntityInformationContext,
} from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { logScaleEntityPivot } from '@gamma/investigator/utilities';
import { RouterLink } from '@gamma/navigation';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useMemo } from 'react';

import validator from 'validator';

const ipaddr = require('ipaddr.js');

const { CENSYS, SHODAN, VIRUS_TOTAL } = URLS;

const { entitySuppressTooltip } = i18n.pages.entities;

const { copied, copyValue, controls, viewDetails, viewDetections } =
  i18n.pages.detections;

export interface EntityInformationActionsProps {
  end?: number;
  start?: number;
  isModal?: boolean;
  entityName?: string;
  entityCategory?: string;
  entityIsSuppressed?: boolean;
  onClose?: () => void;
  onSuppressEntity?: () => void;
}

export const EntityInformationActions = ({
  end,
  start,
  isModal,
  entityName,
  entityCategory,
  entityIsSuppressed,
  onClose,
  onSuppressEntity,
}: EntityInformationActionsProps) => {
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(entityName || '');

  const { userRole } = useContext(AuthContext);

  const { activeEntity, onEntityModalOpen } = useContext(
    EntityInformationContext,
  );

  const { isLoading, showSuppressEntity } = activeEntity || {};

  useEffect(() => {
    if (hasCopied) {
      toast({
        position: 'bottom-right',
        status: 'success',
        description: copied,
      });
    }
  }, [hasCopied, toast]);

  const logScaleEntityQuery = useMemo(
    () =>
      start && end && entityName
        ? logScaleEntityPivot({
            start,
            end,
            entityName,
          })
        : '',
    [end, entityName, start],
  );

  return (
    <VStack pb={!isModal ? 2 : 0} spacing={4}>
      <HStack w="100%">
        <Menu>
          <MenuButton
            as={Button}
            flexGrow={1}
            variant="solid"
            colorScheme="blue"
            size="sm"
          >
            <HStack ml={!isModal ? '102px' : 0}>
              <MuiIcon>frame_inspect</MuiIcon>
              <Text>{controls.investigate}</Text>
              <MuiIcon>expand_more</MuiIcon>
            </HStack>
          </MenuButton>
          <MenuList>
            {!isModal && (
              <MenuItem
                as={Link}
                _hover={{
                  textDecoration: 'none',
                }}
                onClick={onEntityModalOpen}
              >
                <HStack>
                  <MuiIcon>dashboard</MuiIcon>
                  <Text>{viewDetails}</Text>
                </HStack>
              </MenuItem>
            )}
            <MenuItem
              minW={!isModal ? '335px' : 'auto'}
              target="_blank"
              as={RouterLink}
              icon={<MuiIcon>database</MuiIcon>}
              to={{
                pathname: ROUTES.logSearch,
                search: logScaleEntityQuery,
              }}
            >
              <HStack>
                <Text>{controls.viewIpLogs}</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </MenuItem>
            <MenuItem
              as={RouterLink}
              onClick={onClose}
              icon={<MuiIcon>list_alt</MuiIcon>}
              to={{
                pathname: ROUTES.detections,
                search: `start=${start}&end=${end}&source_name=${entityName}`,
              }}
            >
              {viewDetections}
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu offset={[isModal ? -192 : -199, 8]}>
          <Tooltip label={controls.moreActions}>
            <MenuButton
              as={IconButton}
              variant="solid"
              colorScheme="gray"
              isLoading={isLoading}
              size="sm"
            >
              <HStack justifyContent="center">
                <MuiIcon>more_horiz</MuiIcon>
              </HStack>
            </MenuButton>
          </Tooltip>
          <MenuList>
            {userRole !== 'viewer' && (
              <Tooltip label={!showSuppressEntity ? entitySuppressTooltip : ''}>
                <MenuItem
                  isDisabled={isLoading || !showSuppressEntity}
                  onClick={onSuppressEntity}
                  icon={
                    isLoading ? (
                      <LoadingSpinner size="xs" minH="auto" />
                    ) : entityIsSuppressed ? (
                      <MuiIcon>notifications</MuiIcon>
                    ) : (
                      <MuiIcon>notifications_off</MuiIcon>
                    )
                  }
                >
                  {entityIsSuppressed
                    ? controls.unSuppressEntity
                    : controls.suppressEntity}
                </MenuItem>
              </Tooltip>
            )}
            <MenuItem onClick={onCopy} icon={<MuiIcon>content_copy</MuiIcon>}>
              {copyValue}
            </MenuItem>
            {isModal &&
              entityName &&
              validator.isIP(entityName) &&
              ipaddr.parse(entityName)?.range() !== 'private' && (
                <>
                  <MenuDivider />
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${VIRUS_TOTAL}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-virustotal.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>VirusTotal</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${SHODAN}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-shodan.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>Shodan</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${CENSYS}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-censys.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>Censys</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                </>
              )}
          </MenuList>
        </Menu>
      </HStack>
      {!isModal &&
        entityName &&
        validator.isIP(entityName) &&
        ipaddr.parse(entityName)?.range() !== 'private' && (
          <HStack w="100%">
            <Button
              as={Link}
              isExternal
              flexGrow={1}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              textAlign="center"
              href={`${VIRUS_TOTAL}/${entityName}`}
              css={{ textDecoration: 'none !important' }}
            >
              <HStack>
                <Image
                  w="13px"
                  h="13px"
                  src="/assets/images/logo-virustotal.png"
                />
                <Text color="text.primary">VirusTotal</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Button>
            <Button
              as={Link}
              isExternal
              flexGrow={1}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              textAlign="center"
              href={`${SHODAN}/${entityName}`}
              css={{ textDecoration: 'none !important' }}
            >
              <HStack>
                <Image w="16px" h="16px" src="/assets/images/logo-shodan.png" />
                <Text color="text.primary">Shodan</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Button>
            <Button
              as={Link}
              isExternal
              flexGrow={1}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              textAlign="center"
              href={`${CENSYS}/${entityName}`}
              css={{ textDecoration: 'none !important' }}
            >
              <HStack>
                <Image w="16px" h="16px" src="/assets/images/logo-censys.png" />
                <Text color="text.primary">Censys</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Button>
          </HStack>
        )}
    </VStack>
  );
};
