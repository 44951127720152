import { Icon, IconProps, useTheme } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';

export interface SortIconProps extends IconProps {
  sort?: 'asc' | 'desc';
}

export const SortIcon = ({ sort, css: _, ...rest }: SortIconProps) => {
  const theme = useTheme();
  const sortedColor = getColor(theme, 'blue.300');
  return (
    <Icon viewBox="0 0 24 24" height="24px" width="24px" {...rest}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.5 13.5v-6h-3L14.785 3 19.5 7.5h-3v6h-3Z"
          fill={sort === 'desc' ? sortedColor : 'currentColor'}
        />
        <path
          d="M9.214 21 4.5 16.5h3v-6h3v6h3L9.214 21Z"
          fill={sort === 'asc' ? sortedColor : 'currentColor'}
        />
      </g>
    </Icon>
  );
};
