import {
  Flex,
  UnorderedList,
  useMultiStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';
import {
  createContext,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  RefObject,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';

export interface SidebarProps {
  children: ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
  footer?: ReactNode;
  storageId?: string;
  isOpen: boolean;
  autoCloseSidebar: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export interface SidebarContext {
  isOpen: boolean;
  autoCloseSidebar: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

// eslint-disable-next-line no-redeclare
export const SidebarContext = createContext<SidebarContext>({
  isOpen: false,
  autoCloseSidebar: true,
  setIsOpen: () => null,
});

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  (
    { children, contentRef, footer, isOpen, autoCloseSidebar, setIsOpen },
    ref,
  ) => {
    const styles = useMultiStyleConfig('Sidebar', { expanded: isOpen });

    const skipNavLinks = useCallback(() => {
      contentRef?.current?.focus({ preventScroll: true });
    }, [contentRef]);

    const handleKeyPress = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
      (event) => {
        if ([' ', 'Enter'].includes(event.key)) {
          skipNavLinks();
        }
      },
      [skipNavLinks],
    );

    const [isOpenTransitioning, setIsOpenTransitioning] = useState(false);
    useLayoutEffect(() => {
      setIsOpenTransitioning(true);
      const t = setTimeout(() => {
        setIsOpenTransitioning(false);
      }, 300);
      return () => {
        setIsOpenTransitioning(false);
        clearTimeout(t);
      };
    }, [isOpen]);

    return (
      <SidebarContext.Provider
        value={{
          isOpen: isOpen && !isOpenTransitioning,
          autoCloseSidebar,
          setIsOpen,
        }}
      >
        <Flex __css={styles.container} data-testid="gamma-sidebar" ref={ref}>
          <Flex __css={styles.body}>
            <VisuallyHidden>
              <button
                onClick={skipNavLinks}
                onKeyPress={handleKeyPress}
                data-testid="gamma-sidebar-skip-nav-button"
              >
                Skip to main content
              </button>
            </VisuallyHidden>
            <UnorderedList styleType="none" ml="0">
              {children}
            </UnorderedList>
            {footer && (
              <UnorderedList styleType="none" ml="0">
                {footer}
              </UnorderedList>
            )}
          </Flex>
        </Flex>
      </SidebarContext.Provider>
    );
  },
);
