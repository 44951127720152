import { usePrevious } from '@gamma/hooks';
import { isEqual, isUndefined, unset } from 'lodash';
import { ComponentType, useEffect, useMemo, useRef } from 'react';
import {
  CellProps,
  Column,
  Filters,
  HeaderProps,
  MetaBase,
  Row,
  SortByFn,
  SortingRule,
  TableState,
  TableToggleRowsSelectedProps,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from 'react-table';

import { Flex, IconButton } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import React from 'react';
import { DataTablePageSize } from '../../components';
import { useResizeColumns } from '../useResizeColumns';
import { useTableExpansionColumn } from '../useTableExpansionColumn';
import { useTableSelectionColumn } from '../useTableSelectionColumn';

function groupByUseControlledState<DataType extends object>(
  state: TableState<DataType>,
): TableState<DataType> {
  // When grouped by a given column, this hides that column.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [
          ...(state.hiddenColumns || []),
          ...state.groupBy,
        ].filter((d, i, all) => all.indexOf(d) === i),
      };
    }
    return state;
  }, [state]);
}

function traverseColumn<DataType extends object>(
  f: (
    column: Omit<Column<DataType>, 'columns'>,
  ) => Omit<Column<DataType>, 'columns'>,
  column: Column<DataType>,
): Column<DataType> {
  return {
    ...f(column),
    ...('columns' in column
      ? { columns: column.columns.map((column) => traverseColumn(f, column)) }
      : undefined),
  } as any;
}

export interface UseDataTableParams<DataType extends Record<string, unknown>> {
  /** A readonly array of column metadata for the table */
  columns: readonly Column<DataType>[];
  /** The default values for table column */
  defaultColumn?: Partial<Column<DataType>>;
  /** A readonly array of data for the table */
  data: readonly DataType[] | null | undefined;
  /** Flag whether the table rows are selectable or not */
  isSelectable?: boolean;
  /** Flag whether the table selected rows should reset on pagination change */
  autoResetRowSelection?: boolean;
  /** Flag whether the table selected rows should reset filters */
  autoResetFilters?: boolean;
  /**
   * Flag whether the table columns are sortable or not
   * @default true
   */
  isSortable?: boolean;
  /**
   * Flag whether the table has pagination or not
   * @default true
   */
  isPaginable?: boolean;
  /**
   * Flag whether the table rows are expandable or not
   * @default false
   */
  isExpandable?: boolean;
  /**
   * Flag whether the table's pagination is controlled manually or not
   * @default false
   */
  isPaginationManual?: boolean;
  /**
   * Flag whether the table's sorting is controlled manually or not
   * @default false
   */
  isSortingManual?: boolean;
  /**
   * Flag whether the table's column filtering is controlled manually or not
   * @default false
   */
  isFilterManual?: boolean;
  /**
   * Flag whether the table column sorting is case sensitive or not
   * @default false
   */
  isSortCaseSensitive?: boolean;
  /**
   * Flag whether the table should reset the page to 1 when the data changes
   * @default true
   */
  autoResetPage?: boolean;
  /**
   * Flag whether the table should reset the sort by when the data changes
   * @default true
   */
  autoResetSortBy?: boolean;
  /**
   * Flag whether the table should reset the global filter when the data changes
   * @default false
   */
  autoResetExpanded?: boolean;
  /**
   * Flag whether the table should reset expanded row states when data changes
   * @default false
   */
  autoResetGlobalFilter?: boolean;
  /**
   * The amount of pages to show when the table pagination is manually controlled
   * @default 1
   */
  pageCount?: number;
  /**
   * How many rows to show per page
   * @deprecated use initialState instead to avoid issues with state persistence
   */
  pageSize?: DataTablePageSize;
  /** Text to filter the table's data */
  search?: string;
  /** The initial state of the table, passed to the `useTable` hook */
  initialState?: Partial<TableState<DataType>>;
  /**
   * The name where the table state should be saved to local storage
   * if defined, the table will persist its state through multiple sessions from local storage
   */
  storageKey?: string;
  /**
   * The function to test whether a row is selectable or not, disables any rows
   * that return a truthy value.
   * Return a string if you want the checkbox to have a tooltip on hover
   */
  rowIsSelectable?: (row: Row<DataType>) => boolean | string;
  /**
   * The function to test whether a row is expandable or not, disables any rows
   * that return a truthy value.
   * Return a string if you want the button to have a tooltip on hover
   */
  rowIsExpandable?: (row: Row<DataType>) => boolean | string;
  /** The function triggered whenever a row is selected */
  onRowSelection?: (rows: Row<DataType>[]) => void;
  /** the on change event for the row selection checkbox */
  onRowSelectChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    cellProps: CellProps<DataType>,
  ) => void;
  /** the on change event for all row selection checkbox */
  onRowSelectAllChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    headerProps: HeaderProps<DataType>,
  ) => void;
  /** The function triggered when the search prop is updated */
  onSearchFilter?: (rows: Row<DataType>[]) => void;
  /** The function triggered when a column is filtered */
  onColumnFilter?: (filters: Filters<DataType>, rows: Row<DataType>[]) => void;
  /** The function triggered when the page is changed */
  onPageChange?: (rows: Row<DataType>[]) => void;
  /**
   * Event when page index changes, differs from onPageChange which has a
   * dependency on the rows of the page
   */
  onPageIndexChange?: (pageIndex: number) => void;
  /**
   * The function triggered during manual pagination/sorting when the page,
   * page size, or sorting is changed
   */
  onFetchData?: (
    pageIndex: number,
    pageSize: number,
    sortBy: SortingRule<DataType>[],
  ) => void;
  /** The content to render when a row is expanded */
  renderExpandedRow?: ComponentType<Row<DataType>>;
  /** Allows for customized filter behavior */
  globalFilter?:
    | string
    | ((
        rows: Array<Row<DataType>>,
        columnIds: Array<Column['id']>,
        globalFilterValue: any, // eslint-disable-line @typescript-eslint/no-explicit-any
      ) => Row<DataType>[]);
  /** prevents loading hiddenColumns from local storage if this is false */
  isColumnVisibilityTogglable?: boolean;
  getRowId?: (
    originalRow: DataType,
    relativeIndex: number,
    parent?: Row<DataType> | undefined,
  ) => string;
  useControlledState?: (
    state: TableState<DataType>,
    meta: MetaBase<DataType>,
  ) => TableState<DataType>;
  getControlledToggleRowSelectedProps?: (
    toggleProps: Partial<TableToggleRowsSelectedProps>,
    meta: MetaBase<DataType>,
  ) => Partial<TableToggleRowsSelectedProps>;
}

const emptyArray: readonly any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any

export function useDataTable<DataType extends Record<string, unknown>>({
  columns,
  defaultColumn,
  data,
  pageCount: manualPageCount,
  pageSize: manualPageSize,
  isSelectable,
  isExpandable = false,
  isPaginable = true,
  isSortable = true,
  isPaginationManual,
  isSortingManual,
  isFilterManual,
  isSortCaseSensitive,
  autoResetRowSelection = true,
  autoResetPage = true,
  autoResetSortBy = true,
  autoResetGlobalFilter = false,
  autoResetFilters = true,
  initialState: propsInitialState,
  search,
  storageKey,
  rowIsSelectable,
  rowIsExpandable,
  onRowSelection,
  onRowSelectChange,
  onRowSelectAllChange,
  onSearchFilter,
  onPageChange,
  onPageIndexChange,
  onFetchData,
  onColumnFilter,
  getRowId,
  renderExpandedRow,
  globalFilter,
  isColumnVisibilityTogglable,
  useControlledState,
  getControlledToggleRowSelectedProps,
}: UseDataTableParams<DataType>) {
  const mountedRef = useRef<boolean>(false); // if true, we're after first mount
  const capsInsensitiveSorting: Record<
    string,
    SortByFn<DataType>
  > = isSortCaseSensitive
    ? {}
    : {
        alphanumeric: (row1, row2, columnName) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return String(rowOneColumn)?.toUpperCase() >
              String(rowTwoColumn)?.toUpperCase()
              ? 1
              : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      };

  const initialState = useMemo(() => {
    if (!mountedRef.current && storageKey) {
      // if we're mounting for the first time and if props.storageKey is defined
      const savedState = localStorage.getItem(storageKey);
      if (savedState) {
        // ... and if saved state was found in localStorage
        const result = JSON.parse(savedState);

        if (!isColumnVisibilityTogglable) {
          // if visibility is not togglable, dont set hiddenColumns
          unset(result, 'hiddenColumns');
        }

        return result;
      }
    }

    // since the table can be controlled with initial state we want to make sure
    // that any rerenders use the initial state from props instead of the saved state
    return propsInitialState;
  }, [storageKey, propsInitialState, isColumnVisibilityTogglable]);

  const table = useTable(
    {
      columns: useMemo(
        () =>
          columns.map((column) =>
            traverseColumn(
              (column) => ({
                ...column,
                disableGroupBy: column.disableGroupBy ?? true,
                disableResizing: column.disableResizing ?? 'columns' in column, // Column group resizing is quirky; see https://github.com/TanStack/table/issues/3067.
              }),
              column,
            ),
          ),
        [columns],
      ),
      defaultColumn,
      data: data || emptyArray,
      autoResetPage,
      autoResetSortBy,
      autoResetGlobalFilter,
      autoResetFilters,
      useControlledState,
      autoResetSelectedRows: autoResetRowSelection,
      manualPagination: isPaginationManual || !isPaginable,
      manualSortBy: isSortingManual,
      manualFilters: isFilterManual,
      ...(isPaginationManual && { pageCount: manualPageCount }),
      disableSortBy: !isSortable,
      initialState: {
        sortBy: emptyArray,
        filters: emptyArray,
        ...initialState,
        pageSize: initialState?.pageSize ?? manualPageSize ?? 10,
      },
      sortTypes: {
        ...capsInsensitiveSorting,
      },
      getRowId,
      globalFilter,
    },
    useFlexLayout,
    useFilters,
    useGroupBy,
    useGlobalFilter,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowState,
    useTableSelectionColumn({
      rowIsSelectable,
      isSelectable,
      onRowSelectChange,
      onRowSelectAllChange,
    }),
    useTableExpansionColumn({
      rowIsExpandable,
      renderExpandedRow,
      isExpandable,
    }),
    (hooks) => {
      if (getControlledToggleRowSelectedProps) {
        hooks.getToggleRowSelectedProps.push(
          getControlledToggleRowSelectedProps,
        );
      }

      hooks.useControlledState.push(groupByUseControlledState);

      hooks.visibleColumns.push((columns, { instance }) => {
        if (!instance.state.groupBy.length) {
          return columns;
        }

        return [
          {
            id: 'expander', // Make sure it has an ID
            // Build our expander column
            Header: ({ allColumns, state: { groupBy } }) => {
              return groupBy.map((columnId) => {
                const column = allColumns.find((d) => d.id === columnId);

                if (!column) {
                  return null;
                }

                const {
                  key: headerKeyProp,
                  style: headerStyleProp,
                  ...headerProps
                } = column.getHeaderProps();

                return (
                  <div
                    key={headerKeyProp}
                    style={{
                      ...headerStyleProp,
                      display: 'flex',
                      alignItems: 'stretch',
                    }}
                    {...headerProps}
                  >
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <IconButton
                        {...column.getGroupByToggleProps()}
                        aria-label="Group"
                        color="text"
                        mr={2}
                        icon={
                          <MuiIcon size="sm">
                            {column.isGrouped ? 'atr' : 'group_work'}
                          </MuiIcon>
                        }
                        variant="link"
                      />
                    ) : null}
                    {column.render('Header')}
                  </div>
                );
              });
            },
            // @ts-expect-error `any` type is expected
            Cell: ({ row }) => {
              if (row.canExpand) {
                // @ts-expect-error `any` type is expected
                const groupedCell = row.allCells.find((d) => d.isGrouped);
                const {
                  key: toggleRowExpandedKey,
                  style: toggleRowExpandedStyle,
                  ...toggleRowExpandedProps
                } = row.getToggleRowExpandedProps();

                return (
                  <Flex gap={4}>
                    <IconButton
                      key={toggleRowExpandedKey}
                      {...toggleRowExpandedProps}
                      aria-label="Group"
                      color="text"
                      icon={
                        <MuiIcon size="sm">
                          {row.isExpanded
                            ? 'keyboard_arrow_up'
                            : 'keyboard_arrow_down'}
                        </MuiIcon>
                      }
                      variant="link"
                      style={{
                        ...toggleRowExpandedStyle,
                        marginLeft: row.depth * 16,
                      }}
                    />
                    <div>
                      {groupedCell.render('Cell')} ({row.subRows.length})
                    </div>
                  </Flex>
                );
              }

              return null;
            },
            width: columns
              .map(({ width }) => width)
              .filter((width): width is number => typeof width === 'number')
              .reduce((mean, value, ix) => (mean * ix + value) / (ix + 1)),
            minWidth: 150,
          },
          ...columns,
        ];
      });
    },
  );

  const {
    page,
    pageCount,
    gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: {
      hiddenColumns,
      pageIndex,
      pageSize,
      globalFilter: globalFilterValue,
      sortBy,
      filters,
      selectedRowIds,
    },
    setGlobalFilter,
    rows,
  } = table;

  // save table state to local storage anytime it changes
  useEffect(() => {
    if (mountedRef.current && storageKey) {
      // if we're re-rendering
      const jsonState = JSON.stringify({
        hiddenColumns,
        pageIndex,
        pageSize,
        sortBy,
        globalFilter: globalFilterValue,
        filters,
      });
      localStorage.setItem(storageKey, jsonState);
    }
  }, [
    filters,
    globalFilterValue,
    pageIndex,
    pageSize,
    sortBy,
    storageKey,
    hiddenColumns,
  ]);

  const oldPage = usePrevious(page);
  const oldSelectedRowIds = usePrevious(selectedRowIds);
  const oldSearch = usePrevious(globalFilterValue);
  const oldFilters = usePrevious(filters);
  const oldPageIndex = usePrevious(pageIndex);
  const oldPageSize = usePrevious(pageSize);
  const oldSortBy = usePrevious(sortBy);

  useEffect(() => {
    if (
      pageIndex !== oldPageIndex ||
      sortBy !== oldSortBy ||
      pageSize !== oldPageSize
    ) {
      onFetchData?.(pageIndex, pageSize, sortBy);
    }
  }, [
    onFetchData,
    pageIndex,
    pageSize,
    sortBy,
    oldPageIndex,
    oldPageSize,
    oldSortBy,
  ]);

  useEffect(() => {
    if (globalFilterValue !== oldSearch) {
      onSearchFilter?.(rows);
    }
  }, [globalFilterValue, rows, onSearchFilter, oldSearch]);

  // Untoggle all rows if current page changes or if source data changes.
  useEffect(() => {
    if (
      !isEqual(oldPage, page) &&
      selectedFlatRows.length &&
      autoResetRowSelection
    ) {
      toggleAllRowsSelected(false);
    }
  }, [
    oldPage,
    page,
    selectedFlatRows.length,
    toggleAllRowsSelected,
    autoResetRowSelection,
  ]);

  useEffect(() => {
    if (isUndefined(oldSelectedRowIds)) {
      // this check is essentially an "isMounted" test. `selectedRowIds` mutates
      // a couple of times when the table mounts and we're trying to avoid
      // triggering an "onChange" event.
      return;
    }

    // Have to filter out the disabled rows manually
    // as the useRowSelect plugin does not allow for
    // specifying disabled rows.
    const filteredFlatRows = selectedFlatRows.filter((row) => {
      const isRowSelectable = rowIsSelectable?.(row) ?? true;
      return isRowSelectable && typeof isRowSelectable !== 'string';
    });
    onRowSelection?.(filteredFlatRows);
  }, [JSON.stringify(selectedRowIds)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGlobalFilter(search);
  }, [search, setGlobalFilter]);

  useEffect(() => {
    if (pageIndex + 1 > pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  // update the page size when prop is changed
  useEffect(() => {
    if (manualPageSize) {
      setPageSize(manualPageSize);
    }
  }, [manualPageSize, setPageSize]);

  // trigger onColumnFilter prop whenever the column filters have changed
  useEffect(() => {
    if (oldFilters !== undefined && oldFilters !== filters) {
      onColumnFilter?.(filters, rows);
    }
  }, [oldFilters, filters, onColumnFilter, rows]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    onPageChange?.(page);
  }, [onPageChange, page]);

  useEffect(() => {
    onPageIndexChange?.(pageIndex);
  }, [onPageIndexChange, pageIndex]);

  return table;
}
