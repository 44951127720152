import { createContext, Dispatch, SetStateAction } from 'react';
import { EntityInstance } from './EntityInformationProvider';

export interface IEntityInformationContext {
  onSuppressOpen?: () => void;
  onEntityModalOpen?: () => void;
  handleOpenIsolation?: (
    isolationType: 'crowdstrike' | 'microsoft_defender',
  ) => void;
  isEntityModalOpen?: boolean;
  hoveredElement?: HTMLDivElement;
  setHoveredElement?: Dispatch<SetStateAction<HTMLDivElement | undefined>>;
  activeEntity?: EntityInstance;
  setActiveEntity?: Dispatch<SetStateAction<EntityInstance | undefined>>;
}

export const EntityInformationContext =
  createContext<IEntityInformationContext>({
    onSuppressOpen: () => false,
    onEntityModalOpen: () => false,
    handleOpenIsolation: () => false,
    isEntityModalOpen: false,
    hoveredElement: undefined,
    setHoveredElement: () => false,
    activeEntity: undefined,
    setActiveEntity: () => false,
  });
