import {
  chakraComponents,
  Props as ChakraSelectProps,
  GroupBase,
  MenuListProps,
  Select,
} from 'chakra-react-select';
import { UIEvent } from 'react';
import { ComboboxWrapper } from '../Components';
import { OptionType, useCombobox } from '../hooks';

export interface ComboboxProps<TOption extends OptionType> {
  'data-testid'?: string;
  id?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  category: string;
  placeholder?: string;
  options: TOption[];
  value: TOption[];
  onChange?: (value: TOption[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  size?: 'xs' | 'sm' | 'md';
  isSearchable?: boolean;
  onMenuScrollBottomCustom?: () => void;
}

function CustomMenuList<TOption>(
  props: MenuListProps<TOption, boolean, GroupBase<TOption>>,
) {
  const { children, selectProps, innerProps } = props;

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const el = e.currentTarget;
    const isNearBottom = el.scrollHeight - el.scrollTop - el.clientHeight < 5;

    const callback = (
      selectProps as ChakraSelectProps<TOption, boolean, GroupBase<TOption>> & {
        onMenuScrollBottomCustom?: () => void;
      }
    ).onMenuScrollBottomCustom;

    if (isNearBottom && typeof callback === 'function') {
      callback();
    }
  };

  return (
    <chakraComponents.MenuList
      {...props}
      innerProps={{
        ...innerProps,
        onScroll: handleScroll,
      }}
    >
      {children}
    </chakraComponents.MenuList>
  );
}

export const Combobox = <TOption extends OptionType>(
  props: ComboboxProps<TOption>,
) => {
  const {
    'data-testid': dataTestId = 'gamma-combobox',
    isMulti = true,
    placeholder = `Search ${props.category}`,
    size = 'md',
    isSearchable = props.isSearchable,
    onMenuScrollBottomCustom,
  } = props;
  const { wrapperProps, selectProps } = useCombobox<TOption>({
    ...props,
    'data-testid': dataTestId,
    isMulti,
    placeholder,
    size,
    isSearchable,
  });

  type ExtendedSelectProps = ChakraSelectProps<
    TOption,
    boolean,
    GroupBase<TOption>
  > & {
    onMenuScrollBottomCustom?: () => void;
  };

  const extendedSelectProps: ExtendedSelectProps = {
    ...selectProps,
    onMenuScrollBottomCustom,
    components: {
      ...(selectProps.components || {}),
      MenuList: CustomMenuList,
    },
  };

  return (
    <ComboboxWrapper {...wrapperProps}>
      <Select {...extendedSelectProps} />
    </ComboboxWrapper>
  );
};
