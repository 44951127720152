import { IconButton, Tooltip, useClipboard, useToast } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { useEffect } from 'react';

export interface CopyIconButtonProps {
  value: string;
  label?: string;
  tooltip?: string;
  toastOnCopy?: boolean;
  variant?: string;
  colorScheme?: string;
  'data-testid'?: string;
  toastText?: string;
}

export const CopyIconButton = ({
  value,
  tooltip = 'Copy',
  label = 'Copy to clipboard',
  toastOnCopy = true,
  variant = 'subtle',
  colorScheme = 'gray',
  'data-testid': dataTestId = 'copy-icon-button',
  toastText = 'Copied to clipboard.',
}: CopyIconButtonProps) => {
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();

  useEffect(() => {
    if (toastOnCopy && hasCopied) {
      toast({
        position: 'top',
        status: 'success',
        description: toastText,
      });
    }
  }, [hasCopied, toast, toastText, toastOnCopy]);

  return (
    <Tooltip label={tooltip} shouldWrapChildren>
      <IconButton
        type="button"
        border="none"
        size="box-sm"
        variant={variant}
        colorScheme={colorScheme}
        onClick={onCopy}
        icon={<MuiIcon aria-label={'Copy'}>content_copy</MuiIcon>}
        aria-label={label}
        data-testid={dataTestId}
      />
    </Tooltip>
  );
};
