import { useDisclosure } from '@chakra-ui/react';
import { SnippetBox } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { Modal } from '@gamma/overlay';
import { ReactNode } from 'react';

interface RuleEditorModalProps {
  type: 'suricata' | 'yara';
  value: string;
  children: ReactNode;
}

export const RuleEditorModal = ({
  type,
  value,
  children,
}: RuleEditorModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const modalTitle =
    type === 'yara'
      ? i18n.pages.alertDetails.yara.modal.title
      : i18n.pages.alertDetails.suricata.modal.title;

  return (
    <Modal
      title={modalTitle}
      data-testid="code-editor-modal"
      size={'xl'}
      isCentered={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      body={<SnippetBox snippet={value} isCopyable={true} />}
    >
      {children}
    </Modal>
  );
};
