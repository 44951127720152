import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Text,
  useStyles,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { getColumnFlexValue } from '@gamma/util';
import { useEffect, useState } from 'react';
import { Column, ColumnInstance, HeaderGroup, SortingRule } from 'react-table';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DataTableColumnHeaderProps<DataType extends {}> {
  column: HeaderGroup<DataType>;
  originalColumn: Column<DataType> | ColumnInstance<DataType> | undefined;
  isLast?: boolean;
  onColumnSort?: (columnSort: SortingRule<DataType>) => void;
  'data-testid'?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export const DataTableColumnHeader = <DataType extends {}>({
  column,
  originalColumn,
  isLast,
  onColumnSort,
  'data-testid': dataTestId = 'column-header',
}: DataTableColumnHeaderProps<DataType>) => {
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const styles = useStyles();
  const { style = {}, key, ...headerProps } = column.getHeaderProps();
  const { flex: _flex, ...headerStyles } = style;

  const isExpandOrSelectColumn = ['expanded', 'selection'].includes(
    originalColumn?.id ?? '',
  );
  const extraCellProps = {
    p: 0,
    justifyContent: 'center',
  };

  useEffect(() => {
    setShowIcon(isHovered || column.isSorted);
  }, [column.isSorted, isHovered]);

  const content = column.render('Header');

  return (
    <Flex
      key={key}
      {...headerProps}
      // @ts-expect-error `justifyContent` is only overwritten sometimes
      justifyContent={alignmentMap.get(column.align ?? 'left')}
      {...(isExpandOrSelectColumn ? extraCellProps : {})}
      style={headerStyles}
      flex={getColumnFlexValue(column.width, originalColumn)}
      alignItems="center"
      __css={styles.header}
      minW={originalColumn?.minWidth}
      maxW={originalColumn?.maxWidth}
      py={column.canSort ? 0 : undefined}
      px={column.canSort && !column.canGroupBy ? 0 : undefined}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {column.canGroupBy ? (
        <IconButton
          {...column.getGroupByToggleProps()}
          title={
            column.isGrouped
              ? 'Ungroup'
              : `Group${content && typeof content === 'string' ? ` by ${content}` : ''}`
          }
          aria-label="Group"
          color="text"
          mr={column.canSort ? 0 : 2}
          icon={
            <MuiIcon size="sm">
              {column.isGrouped ? 'atr' : 'group_work'}
            </MuiIcon>
          }
          variant="link"
        />
      ) : null}
      {column.canSort ? (
        <Button
          {...column.getSortByToggleProps({
            onClick: (event) => {
              const { isSorted, isSortedDesc } = column;
              const isNextSortDesc = !isSorted
                ? false
                : !isSortedDesc
                  ? true
                  : undefined;
              column.toggleSortBy(isNextSortDesc, event.shiftKey);
              onColumnSort?.({
                id: column.id,
                desc: isNextSortDesc,
              });
            },
          })}
          rightIcon={showIcon ? getSortIcon(column) : undefined}
          justifyContent={alignmentMap.get(column.align ?? 'left')}
          __css={styles.sortableHeader}
          data-testid={dataTestId}
        >
          {content}
        </Button>
      ) : (
        <Text
          as="span"
          isTruncated
          display="flex"
          flex="1 0 auto"
          overflow="inherit"
          // @ts-expect-error `justifyContent` is only overwritten sometimes
          justifyContent={alignmentMap.get(column.align ?? 'left')}
          data-testid={dataTestId}
          {...(isExpandOrSelectColumn ? extraCellProps : {})}
        >
          {content}
        </Text>
      )}
      {column.canFilter && // true by default
        column.Filter && // column.render requires this to be set in order to not throw errors
        column.render('Filter')}
      {column.canResize &&
        !column.placeholderOf &&
        !column.fullWidth &&
        !isLast && (
          <Box
            position="absolute"
            top={0}
            right={-2}
            width={4}
            zIndex={1}
            height="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            data-testid={`${dataTestId}-column-separator`}
            {...column.getResizerProps()}
          >
            <Divider
              orientation="vertical"
              my={2}
              height="calc(100% - var(--chakra-space-4))"
              __css={styles.divider}
            />
          </Box>
        )}
    </Flex>
  );
};

const alignmentMap = new Map<'left' | 'right' | 'center', string>([
  ['left', 'flex-start'],
  ['right', 'flex-end'],
  ['center', 'center'],
]);

const getSortIcon = <DataType extends Record<string, unknown>>(
  column: ColumnInstance<DataType>,
) => {
  if (column.isSorted) {
    return column.isSortedDesc ? (
      <MuiIcon data-testid="column-header-sort-icon">arrow_downward</MuiIcon>
    ) : (
      <MuiIcon data-testid="column-header-sort-icon">arrow_upward</MuiIcon>
    );
  }
  return (
    <MuiIcon color={'gray.500'} data-testid="column-header-sort-icon">
      sort
    </MuiIcon>
  );
};
