import { ApolloError } from '@apollo/client';
import {
  Box,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DataTablePageSize, DataTablePanel } from '@gamma/data-table';
import { MuiIcon, RadarIcon } from '@gamma/icons';
import { CustomSeverityScoreModal } from '@gamma/investigator/components';
import { ROUTES, graphqlErrorRedirects } from '@gamma/investigator/constants';
import { AuthContext, OrgTenantsContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertMetadata,
  IQueryAlertMetadataPaginated,
  QUERY_ALERT_METADATA_PAGINATED,
  QueryAlertMetadataPaginatedVariables,
  SetAlertStatusBySearchTermFiltersInput,
  useGetDetectionMethodSummary,
  useSetAlertActiveStatus,
  useSetAlertStatusBySearchTerm,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { Board, Column, Panel } from '@gamma/layout';
import { Alert, ErrorBoundary } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Column as ReactTableCol, SortingRule } from 'react-table';

import { useUserSession } from '@gamma/investigator/hooks';
import { AlertsCatalogSettingsModal } from '@gamma/investigator/pages/system';
import { SeverityScore } from '../../SeverityScore';
import { AlertCatalogSearch, AlertMetaStatusSwitch } from './components';
import { AlertTableHeaderStatusMenu } from './components/AlertTableHeaderStatusMenu';
import { DetectionMethodSummary } from './components/DetectionMethodSummary';

const { settingPerTenant } = i18n.federatedTenant;

export interface IAlertsCatalogTableContext {
  loading: boolean;
  error?: ApolloError;
  data?: IQueryAlertMetadataPaginated;
  pageCount: number;
  pageIndex: number;
  pageSize: DataTablePageSize;
  sortBy: SortingRule<AlertMetadata>[];
  onFetchData: (pageIndex: number, pageSize: number, sortBy: any) => void;
  hiddenCols: KeyAlertMeta[];
  variables: QueryAlertMetadataPaginatedVariables;
  setQuery: Dispatch<SetStateAction<string | null>>;
  queryTerm: string | null;
  prevQueryTerm: string | null;
  setQueryTerm: Dispatch<SetStateAction<string | null>>;
  setPrevQueryTerm: Dispatch<SetStateAction<string | null>>;
}

type KeyAlertMeta = keyof AlertMetadata;

interface AlertsCatalogTableSearchProps {
  loading: boolean;
  queryTerm: string | null;
  setQuery: Dispatch<SetStateAction<string | null>>;
  setQueryTerm: Dispatch<SetStateAction<string | null>>;
  setBulkActive: Dispatch<SetStateAction<boolean | null>>;
  setPrevQueryTerm: Dispatch<SetStateAction<string | null>>;
  setHasSelectedAll: Dispatch<SetStateAction<boolean>>;
  setAlertStatusBySearchTermFilters: Dispatch<
    SetStateAction<SetAlertStatusBySearchTermFiltersInput>
  >;
}

export const AlertsCatalogTableSearch = ({
  loading,
  queryTerm,
  setQuery,
  setQueryTerm,
  setBulkActive,
  setPrevQueryTerm,
  setHasSelectedAll,
  setAlertStatusBySearchTermFilters,
}: AlertsCatalogTableSearchProps) =>
  useMemo(
    () => (
      <AlertCatalogSearch
        loading={loading}
        queryTerm={queryTerm}
        setQuery={setQuery}
        setQueryTerm={setQueryTerm}
        setBulkActive={setBulkActive}
        setPrevQueryTerm={setPrevQueryTerm}
        setHasSelectedAll={setHasSelectedAll}
        setAlertStatusBySearchTermFilters={setAlertStatusBySearchTermFilters}
      />
    ),
    [
      loading,
      queryTerm,
      setQuery,
      setQueryTerm,
      setBulkActive,
      setPrevQueryTerm,
      setHasSelectedAll,
      setAlertStatusBySearchTermFilters,
    ],
  );

export const AlertsCatalogTable = () => {
  const {
    data,
    error,
    sortBy,
    loading,
    pageSize,
    pageIndex,
    pageCount,
    variables,
    onFetchData,
    hiddenCols,
    setQuery,
    queryTerm,
    setQueryTerm,
    setPrevQueryTerm,
  } = useOutletContext<IAlertsCatalogTableContext>();

  const { signOut } = useUserSession();
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const [alertStatusBySearchTermFilters, setAlertStatusBySearchTermFilters] =
    useState<SetAlertStatusBySearchTermFiltersInput>({
      active: [],
      severity: [],
      types: [],
    });

  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const navigate = useNavigate();

  const showToastMessage = useToast();

  const severityDisclosure = useDisclosure();

  const { userPrivilegesLoading, isOrgTenant, userRole } =
    useContext(AuthContext);

  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<AlertMetadata[] | []>([]);
  const [bulkActive, setBulkActive] = useState<boolean | null>(null);

  const { detections, logSearch } = ROUTES;

  const {
    editAlert,
    searchInLogs,
    viewAllDetections,
    errorRes,
    successRes,
    alertStatusActive,
    alertStatusInactive,
    alertsStatusActive,
    alertsStatusInactive,
    selectAllItems,
  } = i18n.pages.system.alertCatalog;

  const [
    setAlertStatusBySearchTerm,
    { loading: setAlertStatusBySearchTermLoading },
  ] = useSetAlertStatusBySearchTerm({
    variables: {
      items: {
        tenant: orgTenantsQueryParam?.[0],
        active: bulkActive,
        search_term: queryTerm ?? '',
        filters: alertStatusBySearchTermFilters,
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY_ALERT_METADATA_PAGINATED,
        variables: variables,
      },
    ],
    onCompleted: (setAlertStatusBySearchTermData) => {
      const totalRecordsUpdated =
        setAlertStatusBySearchTermData?.setAlertStatusBySearchTerm?.[0]
          ?.updated_record_count;
      showToastMessage({
        status: 'success',
        title: successRes,
        description: bulkActive
          ? `${totalRecordsUpdated} ${alertsStatusActive}`
          : `${totalRecordsUpdated} ${alertsStatusInactive}`,
        isClosable: true,
        position: 'bottom-right',
      });
      setBulkActive(null);
    },
    onError: (err) => {
      showToastMessage({
        status: 'error',
        title: errorRes,
        description: error?.message,
        isClosable: true,
        position: 'bottom-right',
      });
      setBulkActive(null);
    },
  });

  const [setAlertActiveStatus, { loading: bulkLoading }] =
    useSetAlertActiveStatus({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_ALERT_METADATA_PAGINATED,
          variables: variables,
        },
      ],
      variables: {
        items: selectedRows.map((row) => {
          return {
            tenant: orgTenantsQueryParam?.[0],
            active: bulkActive,
            content_id: row.content_id,
            alert_name: row.title,
            alert_type: row.logsource.category,
          };
        }),
      },
      onError: () => {
        showToastMessage({
          status: 'error',
          title: errorRes,
          description: error?.message,
          isClosable: true,
          position: 'bottom-right',
        });
        setBulkActive(null);
      },
      onCompleted: () => {
        const makePluralAlertMessage = selectedRows.length > 1;
        const whichAlertStatusActive = makePluralAlertMessage
          ? alertsStatusActive
          : alertStatusActive;
        const whichAlertStatusInactive = makePluralAlertMessage
          ? alertsStatusInactive
          : alertStatusInactive;
        showToastMessage({
          status: 'success',
          title: successRes,
          description: bulkActive
            ? whichAlertStatusActive
            : whichAlertStatusInactive,
          isClosable: true,
          position: 'bottom-right',
        });
        setBulkActive(null);
      },
    });

  useEffect(() => {
    if (bulkActive === true || bulkActive === false) {
      if (hasSelectedAll) {
        setAlertStatusBySearchTerm();
      } else {
        setAlertActiveStatus();
      }
    }
  }, [
    bulkActive,
    hasSelectedAll,
    setAlertActiveStatus,
    setAlertStatusBySearchTerm,
  ]);

  const [
    alertForCustomSeverityScoreModal,
    setAlertForCustomSeverityScoreModal,
  ] = useState<AlertMetadata | null>(null);

  const dataTableColumns: ReactTableCol<AlertMetadata>[] = useMemo(
    () => [
      {
        width: 80,
        Header: i18n.tables.status,
        accessor: 'active',
        Cell: (props) =>
          orgTenantsQueryParam?.[0] && (
            <AlertMetaStatusSwitch
              variables={variables}
              tenant={orgTenantsQueryParam?.[0]}
              activeRow={props.cell.row.original}
            />
          ),
        disableSortBy: true,
      },
      {
        width: 115,
        align: 'right',
        Header: i18n.tables.severity,
        accessor: 'severity',
        Cell: (props) => (
          <SeverityScore
            dataTestId={`alert-catalog-table-severity-score-${props?.cell?.row?.index}`}
            isCustom={
              props.cell.row.original?.severity_info?.custom_severity_enabled
            }
            score={
              props.cell.row.original?.severity_info?.custom_severity_enabled
                ? props.cell.row.original?.severity_info?.custom_severity
                : props.value
            }
          />
        ),
      },
      {
        Header: i18n.tables.securityAlertCategory,
        accessor: 'title',
        disableSortBy: true,
      },
      {
        width: 150,
        Header: i18n.tables.type,
        accessor: 'logsource',
        Cell: (props) => {
          const { original } = props.cell.row;
          return (
            <>
              {original.logsource.category
                ? stringifyAlertType(original.logsource.category)
                : i18n.pages.system.alertCatalog.none}
            </>
          );
        },
        disableSortBy: true,
      },
      {
        width: 184,
        Header: i18n.tables.actions,
        accessor: 'tenant_alert',
        Cell: (props) => {
          const { original, index } = props.cell.row;
          return (
            <HStack>
              {userRole !== 'viewer' && (
                <Tooltip label={editAlert} placement="bottom-end">
                  <IconButton
                    data-testid={`alert-catalog-table-edit-score-button-${index}`}
                    size="box-md"
                    variant="solid"
                    colorScheme="gray"
                    icon={<MuiIcon>edit</MuiIcon>}
                    aria-label={editAlert}
                    onClick={() => {
                      severityDisclosure?.onOpen();
                      setAlertForCustomSeverityScoreModal(original);
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip label={viewAllDetections} placement="bottom-end">
                <IconButton
                  size="box-md"
                  variant="solid"
                  colorScheme="gray"
                  onClick={() => {
                    return navigate({
                      pathname: detections,
                      search: `alert_category=${original?.title}`,
                    });
                  }}
                  icon={<RadarIcon boxSize={5} />}
                  aria-label={viewAllDetections}
                  data-testid="alert-catalog-alerts-details-button"
                />
              </Tooltip>
            </HStack>
          );
        },
        disableSortBy: true,
      },
      {
        accessor: 'content_id',
      },
      {
        accessor: '_score',
      },
    ],
    [
      detections,
      editAlert,
      logSearch,
      navigate,
      queryTerm,
      searchInLogs,
      variables,
      viewAllDetections,
    ],
  );

  const filteredDataTableColumns = useMemo(
    () =>
      dataTableColumns.filter(
        (col) => !hiddenCols.includes(col.accessor as KeyAlertMeta) && col,
      ),
    [dataTableColumns, hiddenCols],
  );

  const metadata = data?.queryAlertMetadataPaginated?.metadata || [];
  const totalItems = data?.queryAlertMetadataPaginated?.total_items || 0;

  useEffect(() => {
    if (
      selectedRows.length !== pageSize &&
      selectedRows.length !== totalItems
    ) {
      setHasSelectedAll(false);
    }
  }, [selectedRows]);

  const { data: methodData, loading: methodLoading } =
    useGetDetectionMethodSummary({
      variables: {
        tenant: orgTenantsQueryParam?.[0] || '',
      },
    });

  const { alertCatalog } = i18n.navigation.system;

  const {
    isOpen: alertSettingsModalIsOpen,
    onOpen: alertSettingsModalOnOpen,
    onClose: alertSettingsModalOnClose,
  } = useDisclosure();
  return (
    <ErrorBoundary page="Alert Catalog" styleClass="main">
      <HStack
        width="100%"
        padding={3}
        justifyContent="space-between"
        marginTop={-5}
      >
        <Text textStyle="h3" fontWeight={400}>
          {alertCatalog}
        </Text>
        {userRole !== 'viewer' && (
          <IconButton
            size="sm"
            variant="ghost"
            color="inherit"
            aria-label="Alert Settings Button"
            onClick={alertSettingsModalOnOpen}
            data-testid="alert-settings-button"
            icon={<MuiIcon>more_vert</MuiIcon>}
          />
        )}
      </HStack>
      <AlertsCatalogSettingsModal
        isOpen={alertSettingsModalIsOpen}
        onClose={alertSettingsModalOnClose}
        selectedTenant={orgTenantsQueryParam?.[0]}
      />
      <Board data-testid="alert-catalog-table-board">
        {isOrgTenant && (
          <Column>
            <Alert variant="subtle" title={settingPerTenant} />
          </Column>
        )}
        <Column>
          <DetectionMethodSummary
            data={methodData?.getDetectionMethodSummary}
            loading={methodLoading}
          />
        </Column>
        <AlertsCatalogTableSearch
          loading={bulkLoading}
          queryTerm={queryTerm}
          setQuery={setQuery}
          setQueryTerm={setQueryTerm}
          setBulkActive={setBulkActive}
          setPrevQueryTerm={setPrevQueryTerm}
          setHasSelectedAll={setHasSelectedAll}
          setAlertStatusBySearchTermFilters={setAlertStatusBySearchTermFilters}
        />
        {loading || userPrivilegesLoading ? (
          <Column>
            <Panel>
              <GraphQLReqStatus
                signOut={signOut}
                extended={graphqlErrorRedirects}
                loading={loading || !!userPrivilegesLoading}
              />
            </Panel>
          </Column>
        ) : (
          <>
            <Column>
              <DataTablePanel
                autoResetRowSelection={true}
                data-testid="alert-catalog-table"
                isSelectable={userRole !== 'viewer'}
                isLoading={bulkLoading || setAlertStatusBySearchTermLoading}
                onRowSelection={(rows) =>
                  setSelectedRows(rows.map((row) => row.original))
                }
                data={metadata}
                pageSize={pageSize}
                pageCount={pageCount}
                autoResetPage={false}
                autoResetSortBy={false}
                isPaginationManual={true}
                onFetchData={onFetchData}
                columns={filteredDataTableColumns}
                itemCount={totalItems || 0}
                onRowClick={({ original }, event) => {
                  if (
                    (event.target as HTMLElement)?.className?.includes &&
                    !['icon', 'button', 'switch'].some((classNameString) =>
                      (event.target as HTMLElement).className.includes(
                        classNameString,
                      ),
                    )
                  ) {
                    navigate({
                      pathname: original.content_id,
                      search: params.toString(),
                    });
                  }
                }}
                hasSelectedAllRows={hasSelectedAll}
                onSelectAllClick={() => setHasSelectedAll(!hasSelectedAll)}
                onCancelClick={() => setHasSelectedAll(false)}
                initialState={{
                  pageIndex,
                  sortBy,
                  hiddenColumns: hiddenCols,
                }}
                renderSelectedActions={() => (
                  <AlertTableHeaderStatusMenu
                    loading={bulkLoading || setAlertStatusBySearchTermLoading}
                    setBulkActive={setBulkActive}
                  />
                )}
                selectAllText={
                  <>
                    <Box
                      mr={2}
                      w="18px"
                      h="18px"
                      bg="transparent"
                      borderRadius="base"
                      border="solid 1px white"
                    >
                      {hasSelectedAll &&
                      (selectedRows.length === pageSize ||
                        selectedRows.length === totalItems) ? (
                        <MuiIcon mt={-1} size="xs">
                          check
                        </MuiIcon>
                      ) : null}
                    </Box>
                    <Text>
                      {i18n.formatString(selectAllItems, String(totalItems))}
                    </Text>
                  </>
                }
                selectAllProps={{
                  isCustomSelectAll: true,
                  leftIcon: <></>,
                  isDisabled: bulkLoading || setAlertStatusBySearchTermLoading,
                }}
                numberOfSelectAllItems={
                  hasSelectedAll && selectedRows.length === pageSize
                    ? totalItems
                    : 0
                }
                onPageIndexChange={() => setHasSelectedAll(false)}
                emptyBodyContent={i18n.pages.alerts.na}
              />
            </Column>
            {userRole !== 'viewer' &&
              severityDisclosure?.isOpen &&
              alertForCustomSeverityScoreModal && (
                <CustomSeverityScoreModal
                  isFromAlertCatalogTable={true}
                  severityDisclosure={severityDisclosure}
                  alert={alertForCustomSeverityScoreModal}
                />
              )}
          </>
        )}
      </Board>
    </ErrorBoundary>
  );
};
