import {
  Button,
  ButtonGroup,
  HStack,
  Radio,
  RadioGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Column } from '@gamma/layout';
import { Alert, Modal } from '@gamma/overlay';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Label } from '@gamma/form-fields';
import {
  GET_ALERT_AND_DETECTION_TENANT_CONFIGS,
  useGetAlertAndDetectionTenantConfigs,
  useUpdateAlertAndDetectionTenantConfigs,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { RefreshWithCircleIcon } from '@gamma/icons';

const { settingPerTenant } = i18n.federatedTenant;

export interface AlertsCatalogSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedTenant?: string;
}

const {
  newRulesSettings,
  yaraRules,
  otherRules,
  autoEnableAll,
  autoEnableOtherRules,
  disableNewRules,
  cancel,
  save,
  yaraRulesToastText,
  autoEnable,
  autoDisable,
  success,
  error: errorText,
  alertSettingsError,
  reset: resetText,
} = i18n.pages.system.alertCatalog;

export interface AlertsCatalogSettingsFormData {
  yaraRules: string;
}
export const AlertsCatalogSettingsModal = ({
  isOpen,
  onClose,
  selectedTenant,
}: AlertsCatalogSettingsModalProps) => {
  const showToast = useToast();
  const { isOrgTenant, userLicense } = useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};
  const handleClose = () => {
    reset({
      yaraRules: data?.getAlertAndDetectionTenantConfigs.configs[0].config_value
        ? 'enableNewYara'
        : 'disableNewYara',
    });
    onClose();
  };

  const { loading, error, data } = useGetAlertAndDetectionTenantConfigs({
    variables: {
      tenant: selectedTenant || tenant,
    },
    onCompleted: () => {
      const configs = data?.getAlertAndDetectionTenantConfigs.configs;
      if (configs) {
        configs.forEach((config) => {
          if (config.config_key === 'auto_enable_new_YARA_rules') {
            reset({
              yaraRules: config.config_value
                ? 'enableNewYara'
                : 'disableNewYara',
            });
          }
        });
      }
    },
  });

  const {
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm();

  const formData = watch();

  const [
    updateAlertAndDetectionTenantConfigs,
    { loading: updateAlertSettingsLoading },
  ] = useUpdateAlertAndDetectionTenantConfigs({
    awaitRefetchQueries: true,
    variables: {
      tenant: selectedTenant || tenant,
      value: formData.yaraRules === 'enableNewYara',
    },
    refetchQueries: [
      {
        query: GET_ALERT_AND_DETECTION_TENANT_CONFIGS,
        variables: { tenant: selectedTenant || tenant },
      },
    ],
    onCompleted: async () => {
      const toastDescription = i18n.formatString(
        yaraRulesToastText,
        formData.yaraRules === 'enableNewYara' ? autoEnable : autoDisable,
      );
      showToast({
        title: success,
        description: toastDescription,
        status: 'success',
        isClosable: true,
        position: 'bottom-right',
      });
      handleClose();
    },
    onError: () => {
      showToast({
        title: errorText,
        description: alertSettingsError,
        status: 'error',
        isClosable: true,
        position: 'bottom-right',
      });
    },
  });

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
      title={newRulesSettings}
      body={
        error ? (
          <GraphQLReqStatus
            error={error}
            loading={false}
            isBackground={true}
          ></GraphQLReqStatus>
        ) : (
          <VStack>
            {isOrgTenant && (
              <Column>
                <Alert variant="subtle" title={settingPerTenant} />
              </Column>
            )}
            {updateAlertSettingsLoading || loading ? (
              <LoadingSpinner size="sm" />
            ) : (
              <Controller
                control={control}
                name="yaraRules"
                render={({ field }) => (
                  <Label label={yaraRules} name={'yaraRules'}>
                    <RadioGroup {...field}>
                      <Radio
                        width="100%"
                        data-testid="corelight-score-radio-button"
                        value={'enableNewYara'}
                      >
                        <Text
                          data-testid="corelight-score-text"
                          textStyle="body-md"
                        >
                          {autoEnableAll}
                        </Text>
                      </Radio>
                      <Radio
                        data-testid="custom-score-radio-button"
                        value={'disableNewYara'}
                      >
                        <Text
                          data-testid="custom-score-text"
                          textStyle="body-md"
                        >
                          {disableNewRules}
                        </Text>
                      </Radio>
                    </RadioGroup>
                  </Label>
                )}
              />
            )}
            {/* TODO: implement when ready */}
            {/*
          <Controller
            control={control}
            name="otherRules"
            render={({ field }) => (
              <Label name="otherRules" label={otherRules}>
                <RadioGroup {...field}>
                  <Radio
                    data-testid="corelight-score-radio-button"
                    value={'enableNewOther'}
                  >
                    <Text
                      data-testid="corelight-score-text"
                      textStyle="body-md"
                    >
                      {autoEnableOtherRules}
                    </Text>
                  </Radio>
                  <Radio
                    data-testid="custom-score-radio-button"
                    value={'disableNewOther'}
                  >
                    <Text data-testid="custom-score-text" textStyle="body-md">
                      {disableNewRules}
                    </Text>
                  </Radio>
                </RadioGroup>
              </Label>
            )}
          />
          */}
          </VStack>
        )
      }
      footer={
        <HStack alignContent="space-between" width="100%">
          <Button
            variant="ghost"
            colorScheme="gray"
            leftIcon={<RefreshWithCircleIcon boxSize={4} />}
            size="sm"
            onClick={() =>
              reset({
                yaraRules: data?.getAlertAndDetectionTenantConfigs.configs[0]
                  .config_value
                  ? 'enableNewYara'
                  : 'disableNewYara',
              })
            }
          >
            {resetText}
          </Button>
          <ButtonGroup justifyContent="flex-end" isDisabled={false} w="100%">
            <Button
              variant="solid"
              colorScheme="gray"
              onClick={handleClose}
              isDisabled={updateAlertSettingsLoading}
            >
              {cancel}
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              isDisabled={!isDirty}
              isLoading={updateAlertSettingsLoading}
              onClick={() => updateAlertAndDetectionTenantConfigs()}
            >
              {save}
            </Button>
          </ButtonGroup>
        </HStack>
      }
    />
  );
};
