import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export interface GetAlertAndDetectionTenantConfigsVariables {
  tenant: string;
}

export interface ConfigData {
  config_key: string;
  config_value: string;
}

export interface GetAlertAndDetectionTenantConfigsData {
  tenant: string;
  configs: ConfigData[];
}

export interface GetAlertAndDetectionTenantConfigsResponse {
  getAlertAndDetectionTenantConfigs: GetAlertAndDetectionTenantConfigsData;
}

export const GET_ALERT_AND_DETECTION_TENANT_CONFIGS = gql`
  query getAlertAndDetectionTenantConfigs($tenant: String) {
    getAlertAndDetectionTenantConfigs(tenant: $tenant) {
      tenant
      configs {
        config_key
        config_value
      }
      category
    }
  }
`;

export const useGetAlertAndDetectionTenantConfigs = (
  options: QueryHookOptions<
    GetAlertAndDetectionTenantConfigsResponse,
    GetAlertAndDetectionTenantConfigsVariables
  >,
) => {
  return useQuery<
    GetAlertAndDetectionTenantConfigsResponse,
    GetAlertAndDetectionTenantConfigsVariables
  >(GET_ALERT_AND_DETECTION_TENANT_CONFIGS, options);
};
