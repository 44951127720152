export const stringifyAlertType = (alertType: string | undefined) => {
  if (!alertType) return '';
  switch (alertType.toLocaleLowerCase()) {
    case 'ml':
    case 'ml_results':
      return 'Machine Learning';
    case 'notice':
      return 'Notice';
    case 'sigma':
      return 'Sigma';
    case 'suricata_corelight':
      return 'Suricata';
    case 'yara_corelight':
      return 'Yara';
    case 'anomaly':
      return 'Anomaly';
    case 'custom_search_rule':
      return 'Search Based';
    default:
      return '';
  }
};
