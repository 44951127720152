import { Box, useColorMode } from '@chakra-ui/react';
import {
  Detection,
  DetectionAlertSummary,
  EntityFilter,
  useGetChartTimelineFilters,
  useGetDetectionTimelineSummary,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { OptionType } from '@gamma/form-fields';
import { i18n } from '@gamma/investigator/localization';
import { RelatedDetectionsAlerts } from './Components/RelatedDetectionsAlerts';
import { ApexDetectionsChart } from './Components/RelatedDetectionsChart';

const { correlationChart } = i18n.pages.detections;
const { headings } = correlationChart;

interface RelatedDetectionsProps {
  detection: Detection;
  suricataRuleText: string;
  selectedDetection?: Detection;
  selectedDetectionId?: string;
  setIsSlideDrawerOpen?: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getDetectionsLoading: boolean;
  getAppConfigurationsData: string | null;
}

export const RelatedDetections = ({
  detection,
  suricataRuleText,
  selectedDetection,
  selectedDetectionId,
  getDetectionsLoading,
  setIsSlideDrawerOpen,
  setSelectedDetectionId,
  getAppConfigurationsData,
}: RelatedDetectionsProps) => {
  const { colorMode } = useColorMode();
  const [ts, setTs] = useState(0);

  useEffect(() => {
    setTs(moment().unix());
  }, [colorMode, selectedDetectionId]);

  const {
    tenant,
    detection_id,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = detection || {};

  const latestAlertDateTime = moment(latest_start_timestamp * 1000);
  const currentDiffLatest = moment
    .duration(moment().diff(latestAlertDateTime))
    .get('days');
  const showPastFrameAlerts =
    moment.duration(moment().diff(latestAlertDateTime)).get('months') > 0 ||
    moment.duration(moment().diff(latestAlertDateTime)).get('years') > 0;

  const endDateTimeOne =
    showPastFrameAlerts || currentDiffLatest >= 15
      ? moment(latest_start_timestamp * 1000).add(15, 'days')
      : moment();

  const endDateTimeTwo =
    showPastFrameAlerts || currentDiffLatest >= 15
      ? moment(latest_start_timestamp * 1000).add(15, 'days')
      : moment();

  const end = endDateTimeOne;
  const start = endDateTimeTwo.subtract(30, 'days');

  const [startSet, setStartSet] = useState<number | undefined>();
  const [endSet, setEndSet] = useState<number | undefined>();

  useEffect(() => {
    if (end && !endSet) {
      setEndSet(end.unix());
    }
    if (start && !startSet) {
      setStartSet(start.unix());
    }
  }, [end, start, endSet, startSet]);

  const maxRows = 5;

  const [interval, setInterval] = useState<number>(86400);

  const [alertFilters, setAlertFilters] = useState<OptionType[]>([]);
  const [severityFilters, setSeverityFilters] = useState<OptionType[]>([]);
  const [sourceFilters, setSourceFilters] = useState<OptionType[]>([]);
  const [destFilters, setDestFilters] = useState<OptionType[]>([]);
  const [sourceDestFilters, setSourceDestFilters] = useState<OptionType[]>([]);

  const [destOffset, setDestOffset] = useState(0);
  const [accumDestIPs, setAccumDestIPs] = useState<string[]>([]);

  const {
    loading: getChartTimelineFiltersLoading,
    error: getChartTimelineFiltersError,
    data: getChartTimelineFiltersData,
    refetch: refetchChartTimelineFilters,
  } = useGetChartTimelineFilters({
    skip: !tenant || !detection_id || !endSet || !startSet || !interval,
    fetchPolicy: 'cache-and-network',
    variables: {
      end: endSet,
      start: startSet,
      interval,
      detection_id,
      size: 10,
      detection_tenant: tenant,
      source_offset: 0,
      dest_offset: destOffset,
      source_dest_pair_offset: 0,

      severity_filter:
        severityFilters.length > 0
          ? severityFilters.map((sev) => sev.value)
          : undefined,

      entity_filter: (() => {
        const sourceArr = sourceFilters.map((f) => f.value as string);
        const destArr = destFilters.map((f) => f.value as string);
        const parsedPairs: { source: string[]; dest: string[] } = {
          source: [],
          dest: [],
        };
        sourceDestFilters.forEach((f) => {
          const parsedVal = JSON.parse(f.value as string);
          parsedPairs.source.push(parsedVal.source);
          parsedPairs.dest.push(parsedVal.dest);
        });

        const entityFilterInput: Partial<EntityFilter> = {};

        if (sourceArr.length > 0) {
          entityFilterInput.source = { source_entities: sourceArr };
        }
        if (destArr.length > 0) {
          entityFilterInput.dest = { dest_entities: destArr };
        }
        if (parsedPairs.source.length > 0 && parsedPairs.dest.length > 0) {
          entityFilterInput.source_dest = {
            source_entities: parsedPairs.source,
            dest_entities: parsedPairs.dest,
          };
        }
        return Object.keys(entityFilterInput).length > 0
          ? entityFilterInput
          : undefined;
      })(),

      alert_name_filter:
        alertFilters.length > 0
          ? alertFilters.map((alert) => JSON.parse(alert.value as string))
          : undefined,
    },
  });

  const { alert_name_filter, entity_filter, severity_filter } =
    getChartTimelineFiltersData?.getChartTimelineFilters || {};

  const totalDestItems = entity_filter?.dest?.total_items ?? 0;

  useEffect(() => {
    const newDest = entity_filter?.dest?.dest_entities || [];

    console.log('[useEffect] offset=', destOffset, ' newDest=', newDest);

    if (newDest.length) {
      if (destOffset === 0) {
        setAccumDestIPs(newDest);
      } else {
        setAccumDestIPs((prev) => [...prev, ...newDest]);
      }
    }
  }, [entity_filter, destOffset]);

  const alertOptions = useMemo(() => {
    if (!alert_name_filter?.length) return [];
    return alert_name_filter.map((alert) => ({
      value: JSON.stringify(alert),
      label: alert.alert_name,
    }));
  }, [alert_name_filter]);

  const severityOptions = useMemo(() => {
    if (!severity_filter?.length) return [];
    return severity_filter
      .map((sev) => {
        switch (sev) {
          case 'low':
            return { value: 'low', label: headings.low };
          case 'medium':
            return { value: 'medium', label: headings.medium };
          case 'high':
            return { value: 'high', label: headings.high };
          default:
            return null;
        }
      })
      .filter(Boolean) as OptionType[];
  }, [severity_filter]);

  const [sourceOptions, destOptions, sourceDestOptions] = useMemo(() => {
    if (!entity_filter) {
      return [[], [], []];
    }
    const { source, source_dest } = entity_filter;

    const sOptions: OptionType[] =
      source?.source_entities?.map((src) => ({
        value: src,
        label: src,
        type: 'source',
      })) ?? [];

    const dOptions: OptionType[] = accumDestIPs.map((dst) => ({
      value: dst,
      label: dst,
      type: 'dest',
    }));

    const sdOptions: OptionType[] =
      source_dest?.source_entities?.map((srcEntity: string, idx: number) => {
        const destEntity = source_dest.dest_entities?.[idx] ?? '';
        return {
          value: JSON.stringify({ source: srcEntity, dest: destEntity }),
          label: `${srcEntity} - ${destEntity}`,
          type: 'source_dest',
        };
      }) ?? [];

    return [sOptions, dOptions, sdOptions];
  }, [entity_filter, accumDestIPs]);

  const variables = useMemo(() => {
    return {
      end: endSet,
      start: startSet,
      interval,
      detection_id,
      size: 10,
      detection_tenant: tenant,
      entity_filter: (() => {
        const sourceArr = sourceFilters.map((f) => f.value as string);
        const destArr = destFilters.map((f) => f.value as string);
        const parsedPairs: { source: string[]; dest: string[] } = {
          source: [],
          dest: [],
        };

        sourceDestFilters.forEach((f) => {
          const parsedVal = JSON.parse(f.value as string);
          parsedPairs.source.push(parsedVal.source);
          parsedPairs.dest.push(parsedVal.dest);
        });

        const entityFilterInput: Partial<EntityFilter> = {};

        if (sourceArr.length > 0) {
          entityFilterInput.source = { source_entities: sourceArr };
        }
        if (destArr.length > 0) {
          entityFilterInput.dest = { dest_entities: destArr };
        }
        if (parsedPairs.source.length > 0 && parsedPairs.dest.length > 0) {
          entityFilterInput.source_dest = {
            source_entities: parsedPairs.source,
            dest_entities: parsedPairs.dest,
          };
        }
        return Object.keys(entityFilterInput).length > 0
          ? entityFilterInput
          : undefined;
      })(),

      severity_filter:
        severity_filter && severity_filter.length > 0
          ? severity_filter
          : undefined,
      alert_name_filter:
        alert_name_filter && alert_name_filter.length > 0
          ? alert_name_filter
          : undefined,
    };
  }, [
    endSet,
    startSet,
    interval,
    detection_id,
    maxRows,
    tenant,
    sourceFilters,
    destFilters,
    sourceDestFilters,
    severity_filter,
    alert_name_filter,
  ]);

  const { loading, error, data } = useGetDetectionTimelineSummary({
    skip: !tenant || !detection_id || !endSet || !startSet || !interval,
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const {
    getCurrentDetectionTimelineSummary,
    getDetectionSourceDestinationTimelineSummary,
    getDetectionSourceTimelineSummary,
    getDetectionDestinationTimelineSummary,
  } = data || {};

  const currentDetectionDetections = useMemo(
    () =>
      getCurrentDetectionTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ) || [],
    [getCurrentDetectionTimelineSummary],
  );

  const detectionSourceDestinationDetections = useMemo(
    () =>
      getDetectionSourceDestinationTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ) || [],
    [getDetectionSourceDestinationTimelineSummary],
  );

  const detectionSourceDetections = useMemo(
    () =>
      getDetectionSourceTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ) || [],
    [getDetectionSourceTimelineSummary],
  );

  const detectionDestinationDetections = useMemo(
    () =>
      getDetectionDestinationTimelineSummary?.summary?.flatMap(
        (summary) => summary.detections,
      ) || [],
    [getDetectionDestinationTimelineSummary],
  );

  const [chartDetections, setChartDetections] = useState<{
    currentDetectionDetections?: DetectionAlertSummary[];
    detectionSourceDetections?: DetectionAlertSummary[];
    detectionDestinationDetections?: DetectionAlertSummary[];
    detectionSourceDestinationDetections?: DetectionAlertSummary[];
  }>({
    currentDetectionDetections: [],
    detectionSourceDetections: [],
    detectionDestinationDetections: [],
    detectionSourceDestinationDetections: [],
  });

  useEffect(() => {
    setChartDetections({
      currentDetectionDetections,
      detectionSourceDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    });
  }, [
    currentDetectionDetections,
    detectionSourceDetections,
    detectionDestinationDetections,
    detectionSourceDestinationDetections,
  ]);

  if (
    loading ||
    getChartTimelineFiltersLoading ||
    error ||
    getChartTimelineFiltersError
  ) {
    return (
      <GraphQLReqStatus
        error={error || getChartTimelineFiltersError}
        loading={!!loading || getChartTimelineFiltersLoading}
      />
    );
  }

  return (
    <Box key={ts}>
      <ApexDetectionsChart
        loading={getChartTimelineFiltersLoading}
        error={getChartTimelineFiltersError}
        end={end.unix()}
        start={start.unix()}
        maxRows={maxRows}
        chartsData={data}
        interval={interval}
        setInterval={setInterval}
        alertOptions={alertOptions}
        alertFilters={alertFilters}
        setAlertFilters={setAlertFilters}
        sourceOptions={sourceOptions}
        sourceFilters={sourceFilters}
        setSourceFilters={setSourceFilters}
        destOptions={destOptions}
        destFilters={destFilters}
        setDestFilters={setDestFilters}
        destOffset={destOffset}
        setDestOffset={setDestOffset}
        totalDestItems={totalDestItems}
        sourceDestOptions={sourceDestOptions}
        sourceDestFilters={sourceDestFilters}
        setSourceDestFilters={setSourceDestFilters}
        severityOptions={severityOptions}
        severityFilters={severityFilters}
        setSeverityFilters={setSeverityFilters}
        setSelectedDetectionId={setSelectedDetectionId}
        earliest_start_timestamp={earliest_start_timestamp}
        latest_start_timestamp={latest_start_timestamp}
      />
      <RelatedDetectionsAlerts
        loading={loading}
        error={error}
        data={data}
        detection={detection}
        chartDetections={chartDetections}
        suricataRuleText={suricataRuleText}
        selectedDetection={selectedDetection}
        getDetectionsLoading={getDetectionsLoading}
        setSelectedDetectionId={setSelectedDetectionId}
        getAppConfigurationsData={getAppConfigurationsData}
      />
    </Box>
  );
};
