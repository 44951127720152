import { Stack } from '@chakra-ui/react';
import { Input } from '@gamma/form-fields';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormProps } from '../types';

export interface ILoginForm {
  username: string;
  password: string;
}

export interface LoginFormProps extends FormProps<ILoginForm> {
  usernameType?: 'username' | 'email';
  dataTestId?: string;
}

export const LoginForm = ({
  usernameType = 'username',
  onSubmit,
  autoFocusField,
  usernameContent = {
    placeholder: usernameType === 'username' ? 'Username' : 'Email',
    label: 'Username',
  },
  passwordContent = { placeholder: 'Password', label: 'Password' },
  dataTestId = 'gamma-login-form',
}: LoginFormProps) => {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<ILoginForm>({
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (autoFocusField) {
      setFocus(autoFocusField);
    }
  }, [autoFocusField, setFocus]);

  return (
    <Stack
      as="form"
      spacing={4}
      id="loginForm"
      onSubmit={handleSubmit(...onSubmit)}
      noValidate
    >
      <Input
        {...usernameContent}
        autoComplete={usernameType}
        type={usernameType}
        {...register('username', { required: 'Username is required' })}
        error={errors.username?.message}
        isRequired
        data-testid={`${dataTestId}-username`}
        isLabelHidden
        size="md"
      />
      <Input
        {...passwordContent}
        type="password"
        autoComplete="current-password"
        {...register('password', { required: 'Password is required' })}
        error={errors.password?.message}
        data-testid={`${dataTestId}-password`}
        isRequired
        isLabelHidden
        size="md"
      />
    </Stack>
  );
};
