import {
  Code,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import { ComponentProps, ComponentPropsWithoutRef } from 'react';
import ReactMarkdown from 'react-markdown';

function createHeadingComponent<Level extends 1 | 2 | 3 | 4 | 5 | 6>(
  level: Level,
): Exclude<
  ComponentProps<typeof ReactMarkdown>['components'],
  undefined
>[`h${Level}`] {
  return ({ node: _node, level: _level, ...props }) => (
    <Heading as={`h${level}`} textStyle={`h${level}`} {...props} />
  );
}

export const MarkdownRenderer = ({
  components,
  className,
  'data-testid': dataTestId,
  ...restProps
}: ComponentPropsWithoutRef<typeof ReactMarkdown> & {
  'data-testid'?: string;
}) => {
  return (
    <div data-testid={dataTestId} className={className}>
      <ReactMarkdown
        components={{
          h1: createHeadingComponent(1),
          h2: createHeadingComponent(2),
          h3: createHeadingComponent(3),
          h4: createHeadingComponent(4),
          h5: createHeadingComponent(5),
          h6: createHeadingComponent(6),
          p: ({ node: _node, ...props }) => <Text mb={2} {...props} />,
          a: ({ node: _node, ...props }) => <Link {...props} />,
          img: ({ node: _node, ...props }) => <Image {...props} />,
          table: ({ node: _node, ...props }) => (
            <Table {...props} bg="gray.900" my={4} />
          ),
          thead: ({ node: _node, ...props }) => <Thead {...props} />,
          tbody: ({ node: _node, ...props }) => <Tbody {...props} />,
          tr: ({ node: _node, ...props }) => <Tr {...props} />,
          th: ({ node: _node, ...props }) => <Th {...props} />,
          td: ({ node: _node, ...props }) => <Td {...props} />,
          ol: ({ node: _node, ordered: _ordered, ...props }) => (
            <OrderedList {...props} start={1} />
          ),
          ul: ({ node: _node, ordered: _ordered, ...props }) => (
            <UnorderedList {...props} />
          ),
          li: ({ node: _node, ordered: _ordered, ...props }) => (
            <ListItem {...props} />
          ),
          code: ({ node: _node, ...props }) => (
            <Code
              {...props}
              bg="layer.0"
              color="text.primary"
              px={2}
              borderRadius="2px"
            />
          ),
          ...components,
        }}
        {...restProps}
      />
    </div>
  );
};
