import { createIcon } from '@chakra-ui/react';

export const MsDefenderLogoSmall = createIcon({
  displayName: 'MsDefenderLogo',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12.605 4.114c-.065-.001-.086.01-.095.034v7.505l7.403-.027c.074-.089.094-.762.085-3.07l-.009-2.355-.19-.006c-2.214-.074-3.421-.388-4.762-1.241-.447-.285-.538-.332-.868-.469a5.364 5.364 0 0 0-1.564-.37Zm-1.22.007c-.056 0-.24.02-.409.05-.763.13-1.3.344-1.958.77-.69.448-1.145.664-1.855.875-.687.204-1.84.355-2.863.379l-.292.006-.006 2.37c-.006 1.7.003 2.471.029 2.726l.035.356h7.421V4.127l-.102-.006Zm8.327 8.524c0 .071-7.196.02-7.196.02l.02 8.335c.038 0 .152-.062.5-.266.064-.039.403-.261.754-.495 1.197-.803 2.056-1.511 2.927-2.412 1.008-1.046 1.68-1.961 2.259-3.078.374-.726.736-1.722.736-2.033v-.071Zm-8.225.015-7.187.015c-.023.041.03.314.117.601.304.98.894 2.119 1.571 3.028.775 1.034 1.83 2.098 3.06 3.087.777.623 2.223 1.58 2.39 1.58l.05-8.311Z"
      fill="#F4F4F4"
    />
  ),
  defaultProps: {
    height: '24px',
    width: '24px',
  },
});
