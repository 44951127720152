import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import {
  Detection,
  DetectionAlertSummary,
  IGetDetectionTimelineSummary,
} from '@gamma/investigator/queries';
import { FlushPanelContent } from '@gamma/layout';
import React, { useContext, useEffect } from 'react';

import { SlideDrawerContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  ErrorMessage,
  GraphQLReqStatus,
  LoadingSpinner,
} from '@gamma/progress';
import { createPortal } from 'react-dom';
import { DetectionAlerts, RelatedDetectionRow } from './Components';

const { correlationChart } = i18n.pages.detections;
const { headings, useFilters, limitReaced } = correlationChart;

interface RelatedDetectionsAlertsProps {
  loading: boolean;
  error?: ErrorMessage;
  data?: IGetDetectionTimelineSummary;
  detection: Detection;
  suricataRuleText: string;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  chartDetections: {
    detectionSourceDetections?: DetectionAlertSummary[];
    currentDetectionDetections?: DetectionAlertSummary[];
    detectionDestinationDetections?: DetectionAlertSummary[];
    detectionSourceDestinationDetections?: DetectionAlertSummary[];
  };
  getDetectionsLoading: boolean;
  getAppConfigurationsData: string | null;
}

export const LimitReachedText = () => (
  <VStack
    p={2}
    spacing={1}
    alignItems="center"
    borderTop="solid 1px"
    borderTopColor="border.1"
  >
    <Text>{limitReaced}</Text>
    <Text color="text.secondary">{useFilters}</Text>
  </VStack>
);

export const RelatedDetectionsAlerts = React.memo(
  ({
    loading,
    error,
    data,
    detection,
    chartDetections,
    suricataRuleText,
    selectedDetection,
    getDetectionsLoading,
    setSelectedDetectionId,
    getAppConfigurationsData,
  }: RelatedDetectionsAlertsProps) => {
    const {
      portalType,
      setPortalType,
      slideDrawerTitle,
      setIsSlideDrawerOpen,
    } = useContext(SlideDrawerContext);

    const {
      detectionSourceDetections,
      currentDetectionDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    } = chartDetections;

    const isCurrentDetectionOpen =
      !!chartDetections?.currentDetectionDetections?.find(
        (detection) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionSourceDestinationOpen =
      !!chartDetections?.detectionSourceDestinationDetections?.find(
        (detection) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionDestinationOpen =
      !!chartDetections?.detectionDestinationDetections?.find(
        (detection) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionSourceOpen =
      !!chartDetections?.detectionSourceDetections?.find(
        (detection) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const defaultIndex = isCurrentDetectionOpen
      ? 0
      : isDetectionSourceDestinationOpen
        ? 1
        : isDetectionSourceOpen
          ? 2
          : isDetectionDestinationOpen
            ? 3
            : undefined;

    const groupings = [
      ...(currentDetectionDetections && currentDetectionDetections?.length > 0
        ? [0]
        : []),
      ...(detectionSourceDestinationDetections &&
      detectionSourceDestinationDetections?.length > 0
        ? [1]
        : []),
      ...(detectionSourceDetections && detectionSourceDetections?.length > 0
        ? [2]
        : []),
      ...(detectionDestinationDetections &&
      detectionDestinationDetections?.length > 0
        ? [3]
        : []),
    ];

    const index =
      defaultIndex !== undefined ? groupings.indexOf(defaultIndex) : undefined;

    useEffect(() => {
      if (
        detection.detection_id === selectedDetection?.detection_id &&
        portalType !== 'notes'
      ) {
        setPortalType?.(undefined);
        setIsSlideDrawerOpen?.(false);
      }
    }, [
      detection.detection_id,
      selectedDetection?.detection_id,
      setIsSlideDrawerOpen,
      setPortalType,
    ]);

    return loading || error ? (
      <GraphQLReqStatus error={error} loading={loading} />
    ) : (
      <FlushPanelContent mt={0}>
        <HStack
          spacing={0}
          minH="750px"
          alignItems="start"
          borderTop="solid 1px"
          borderTopColor="border.1"
        >
          <Flex w="280px" borderRight="solid 1px" borderRightColor="border.1">
            <VStack w="100%">
              <Accordion w="100%" key={index} defaultIndex={index}>
                {currentDetectionDetections &&
                  currentDetectionDetections?.length > 0 && (
                    <AccordionItem bgColor="transparent">
                      <AccordionButton w="100%" justifyContent="space-between">
                        <Text as="span">{headings.currentDetection}</Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <Box maxH={'770px'} overflow="scroll">
                          {currentDetectionDetections?.map(
                            (detection, index) => (
                              <RelatedDetectionRow
                                key={index}
                                item={detection}
                                selectedDetection={selectedDetection}
                                setSelectedDetectionId={setSelectedDetectionId}
                              />
                            ),
                          )}
                          {data?.getCurrentDetectionTimelineSummary &&
                            data?.getCurrentDetectionTimelineSummary
                              ?.row_count > 5 && <LimitReachedText />}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                {detectionSourceDestinationDetections &&
                  detectionSourceDestinationDetections?.length > 0 && (
                    <AccordionItem bgColor="transparent">
                      <AccordionButton w="100%" justifyContent="space-between">
                        <Text as="span">{headings.sourceDest}</Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <Box maxH={'770px'} overflow="scroll">
                          {detectionSourceDestinationDetections?.map(
                            (detection, index) => (
                              <RelatedDetectionRow
                                key={index}
                                item={detection}
                                selectedDetection={selectedDetection}
                                setSelectedDetectionId={setSelectedDetectionId}
                              />
                            ),
                          )}
                          {data?.getDetectionSourceDestinationTimelineSummary &&
                            data?.getDetectionSourceDestinationTimelineSummary
                              ?.row_count > 5 && <LimitReachedText />}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                {detectionSourceDetections &&
                  detectionSourceDetections?.length > 0 && (
                    <AccordionItem bgColor="transparent">
                      <AccordionButton w="100%" justifyContent="space-between">
                        <Text as="span">{headings.source}</Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <Box maxH={'770px'} overflow="scroll">
                          {detectionSourceDetections?.map(
                            (detection, index) => (
                              <RelatedDetectionRow
                                key={index}
                                item={detection}
                                selectedDetection={selectedDetection}
                                setSelectedDetectionId={setSelectedDetectionId}
                              />
                            ),
                          )}
                          {data?.getDetectionSourceTimelineSummary &&
                            data?.getDetectionSourceTimelineSummary?.row_count >
                              5 && <LimitReachedText />}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                {detectionDestinationDetections &&
                  detectionDestinationDetections?.length > 0 && (
                    <AccordionItem bgColor="transparent">
                      <AccordionButton w="100%" justifyContent="space-between">
                        <Text as="span">{headings.destination}</Text>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <Box maxH={'770px'} overflow="scroll">
                          {detectionDestinationDetections?.map(
                            (detection, index) => (
                              <RelatedDetectionRow
                                key={index}
                                item={detection}
                                selectedDetection={selectedDetection}
                                setSelectedDetectionId={setSelectedDetectionId}
                              />
                            ),
                          )}
                          {data?.getDetectionDestinationTimelineSummary &&
                            data?.getDetectionDestinationTimelineSummary
                              ?.row_count > 5 && <LimitReachedText />}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  )}
                {detectionSourceDestinationDetections &&
                  detectionSourceDestinationDetections?.length === 0 && (
                    <AccordionItem bgColor="transparent">
                      <Box px={4} py={2} color="text.secondary">
                        {headings.sourceDest} (N/A)
                      </Box>
                    </AccordionItem>
                  )}
                {detectionSourceDetections &&
                  detectionSourceDetections?.length === 0 && (
                    <AccordionItem bgColor="transparent">
                      <Box px={4} py={2} color="text.secondary">
                        {headings.source} (N/A)
                      </Box>
                    </AccordionItem>
                  )}
                {detectionDestinationDetections &&
                  detectionDestinationDetections?.length === 0 && (
                    <AccordionItem bgColor="transparent">
                      <Box px={4} py={2} color="text.secondary">
                        {headings.destination} (N/A)
                      </Box>
                    </AccordionItem>
                  )}
              </Accordion>
            </VStack>
          </Flex>
          <Flex flexGrow={1}>
            <VStack p={4} w="full" spacing={4} alignItems="start">
              <HStack w="full" justifyContent="space-between">
                <HStack>
                  {selectedDetection?.rank?.severity !== null &&
                    selectedDetection?.rank?.severity !== undefined && (
                      <SeverityScore
                        score={selectedDetection.rank.severity}
                        isCustom={selectedDetection?.rank?.is_custom_severity}
                      />
                    )}
                  <Text textStyle="h5">
                    {selectedDetection?.alert_info?.alert_name}
                  </Text>
                </HStack>
                <Button
                  size="xs"
                  variant="solid"
                  colorScheme="blue"
                  onClick={() => {
                    setPortalType?.('detection');
                    setIsSlideDrawerOpen?.(true);
                  }}
                  isDisabled={
                    getDetectionsLoading ||
                    detection.detection_id === selectedDetection?.detection_id
                  }
                >
                  {correlationChart.previewDetection}
                </Button>
              </HStack>
              {getDetectionsLoading && (
                <Center pt={4} width="100%">
                  <LoadingSpinner />
                </Center>
              )}
              {selectedDetection && (
                <Box w="100%">
                  <DetectionAlerts
                    detectionData={selectedDetection}
                    suricataRuleText={suricataRuleText}
                    getAppConfigurationsData={getAppConfigurationsData}
                  />
                </Box>
              )}
            </VStack>
          </Flex>
        </HStack>
        {portalType === 'detection' &&
          slideDrawerTitle &&
          createPortal(
            selectedDetection ? (
              <Box __css={{ wordWrap: 'anywhere' }}>
                {`${selectedDetection?.alert_info?.alert_name} | ${selectedDetection?.alert_entity?.entity_name}`}
              </Box>
            ) : (
              <Center paddingTop={2} paddingLeft={6}>
                <Spinner size="xs" />
              </Center>
            ),
            slideDrawerTitle,
          )}
      </FlushPanelContent>
    );
  },
);
