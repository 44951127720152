import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { IGetAlertMetadata } from './types';

export const GET_ALERT_METADATA_YARA_DESCRIBE_RULE = gql`
  query getAlertMetadataYara($items: [GetAlertMetadataInput]!) {
    getAlertMetadata(items: $items) {
      metadata {
        id
        author
        date
        title
        match_meta
      }
    }
  }
`;

export const useGetAlertMetadataYaraRuleDescription = (
  options: QueryHookOptions<IGetAlertMetadata, OperationVariables>,
) =>
  useQuery<IGetAlertMetadata>(GET_ALERT_METADATA_YARA_DESCRIBE_RULE, options);
