import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';

import { AuthContext, UserPrivileges } from '@gamma/investigator/context';
import {
  AvailabilityTag,
  IntegrationsConfigInfo,
} from './IntegrationsConfigCard';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IntegrationsConstants } from './IntegrationsConstants';
import { useContext } from 'react';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { OutletContextType } from './IntegrationsDetailPage';

const { integrations } = i18n.pages;
const {
  learnMore,
  additionalIntegrations,
  exploreIntegrations,
  fleetIntegrationsHeader,
  fleetIntegrationsTagline,
  investigatorIntegrationsHeader,
  investigatorIntegrationsTagline,
  corelightUpdateIntegrationsHeader,
  corelightUpdateIntegrationsTagline,
  partnerIntegrationsHeader,
  partnerIntegrationsTagline,
} = integrations;

export interface AppConfigProps {
  getAppConfigurationsLoading: boolean;
  getAppConfigurationsData?: string | null;
  tenantPrivileges: UserPrivileges;
}
const enabledIntegrations = Object.keys(IntegrationsConstants).filter(
  (integration) => IntegrationsConstants[integration].available,
);

const investIntegrations = enabledIntegrations.filter(
  (integration) => IntegrationsConstants[integration].type === 'INVEST',
);

const partnerIntegrations = enabledIntegrations.filter(
  (integration) => IntegrationsConstants[integration].type === 'PARTNER',
);
const fleetIntegrations = enabledIntegrations.filter(
  (integration) => IntegrationsConstants[integration].type === 'FLEET',
);
const corelightIntegrations = enabledIntegrations.filter(
  (integration) => IntegrationsConstants[integration].type === 'CORELIGHT',
);

export const IntegrationsConfig = ({
  getAppConfigurationsData,
  getAppConfigurationsLoading,
}: AppConfigProps) => {
  const { userLicense } = useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};
  const navigate = useNavigate();

  const { userPrivileges, configsLoading } =
    useOutletContext<OutletContextType>();

  const featureFlags = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags
    : {};

  return configsLoading ? (
    <GraphQLReqStatus loading={configsLoading}></GraphQLReqStatus>
  ) : (
    <VStack spacing={4} alignItems="start" maxWidth="960px" marginX="auto">
      <Panel>
        <HStack
          h="200px"
          backgroundSize="535px"
          backgroundPosition="right"
          backgroundRepeat="no-repeat"
          backgroundImage="/assets/images/ig-evidence-based-security.png"
        >
          <VStack ml={6} maxW="550px" alignItems="start">
            <Text textStyle="h4">{additionalIntegrations}</Text>
            <Text mb={2} textStyle="body-md">
              {exploreIntegrations}
            </Text>
            <Button
              as={RouterLink}
              target="_blank"
              variant="solid"
              colorScheme="blue"
              to={`/docs/settings/service-now-settings.html`}
            >
              {learnMore}
            </Button>
          </VStack>
        </HStack>
      </Panel>

      {investIntegrations.length > 0 && (
        <>
          <Text textStyle="h5">{investigatorIntegrationsHeader}</Text>
          <Text>{investigatorIntegrationsTagline}</Text>
          <HStack spacing={4} flexWrap="wrap" justify="flex-start">
            {investIntegrations.map((integration) => {
              const {
                description,
                title,
                pathName,
                tags,
                featureFlag,
                privilegeName,
                fallbackText,
              } = IntegrationsConstants[integration];
              let showTile = true;
              if (featureFlag) {
                showTile = Boolean(featureFlags[featureFlag]);
              }
              return showTile ? (
                <IntegrationsConfigInfo
                  availabilityTag={
                    <AvailabilityTag
                      isEnabled={
                        userPrivileges?.[tenant][privilegeName] ?? false
                      }
                      fallbackText={fallbackText}
                    />
                  }
                  cardView
                  description={description}
                  title={title}
                  onClick={() => {
                    navigate(`../${integration}/configuration`);
                  }}
                  pathName={pathName}
                  tags={tags}
                  isLoading={configsLoading}
                  key={pathName}
                />
              ) : null;
            })}
          </HStack>
        </>
      )}
      {fleetIntegrations.length > 0 && (
        <>
          <Text textStyle="h5">{fleetIntegrationsHeader}</Text>
          <Text>{fleetIntegrationsTagline}</Text>
          <HStack spacing={4} flexWrap="wrap" justify="flex-start">
            {fleetIntegrations.map((integration) => {
              const {
                description,
                title,
                pathName,
                tags,
                featureFlag,
                privilegeName,
                fallbackText,
              } = IntegrationsConstants[integration];
              let showTile = true;
              if (featureFlag) {
                showTile = Boolean(featureFlags[featureFlag]);
              }
              return showTile ? (
                <IntegrationsConfigInfo
                  availabilityTag={
                    <AvailabilityTag
                      isEnabled={
                        userPrivileges?.[tenant][privilegeName] ?? false
                      }
                      fallbackText={fallbackText}
                    />
                  }
                  cardView
                  description={description}
                  title={title}
                  onClick={() => {
                    navigate(`../${integration}/configuration`);
                  }}
                  pathName={pathName}
                  tags={tags}
                  isLoading={getAppConfigurationsLoading}
                  key={pathName}
                />
              ) : null;
            })}
          </HStack>
        </>
      )}
      {corelightIntegrations.length > 0 && (
        <>
          <Text textStyle="h5">{corelightUpdateIntegrationsHeader}</Text>
          <Text>{corelightUpdateIntegrationsTagline}</Text>
          <HStack spacing={4} flexWrap="wrap" justify="flex-start">
            {corelightIntegrations.map((integration) => {
              const {
                fallbackText,
                description,
                title,
                pathName,
                tags,
                featureFlag,
                privilegeName,
              } = IntegrationsConstants[integration];
              let showTile = true;
              if (featureFlag) {
                showTile = Boolean(featureFlags[featureFlag]);
              }
              return showTile ? (
                <IntegrationsConfigInfo
                  availabilityTag={
                    <AvailabilityTag
                      isEnabled={
                        userPrivileges?.[tenant][privilegeName] ?? false
                      }
                      fallbackText={fallbackText}
                    />
                  }
                  cardView
                  description={description}
                  title={title}
                  onClick={() => {
                    navigate(`../${integration}/configuration`);
                  }}
                  pathName={pathName}
                  tags={tags}
                  isLoading={getAppConfigurationsLoading}
                  key={pathName}
                />
              ) : null;
            })}
          </HStack>
        </>
      )}
      {partnerIntegrations.length > 0 && (
        <>
          <Text textStyle="h5">{partnerIntegrationsHeader}</Text>
          <Text>{partnerIntegrationsTagline}</Text>
          <HStack spacing={4} flexWrap="wrap" justify="flex-start">
            {partnerIntegrations.map((integration) => {
              const {
                description,
                title,
                pathName,
                tags,
                featureFlag,
                privilegeName,
                fallbackText,
              } = IntegrationsConstants[integration];
              let showTile = true;
              if (featureFlag) {
                showTile = Boolean(featureFlags[featureFlag]);
              }
              return showTile ? (
                <IntegrationsConfigInfo
                  availabilityTag={
                    <AvailabilityTag
                      isEnabled={
                        userPrivileges?.[tenant][privilegeName] ?? false
                      }
                      fallbackText={fallbackText}
                    />
                  }
                  cardView
                  description={description}
                  title={title}
                  onClick={() => {
                    navigate(`../${integration}/configuration`);
                  }}
                  pathName={pathName}
                  tags={tags}
                  key={pathName}
                  isLoading={getAppConfigurationsLoading}
                />
              ) : null;
            })}
          </HStack>
        </>
      )}
    </VStack>
  );
};
