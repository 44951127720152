import {
  Box,
  Button,
  HStack,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { OrgTenantsContext } from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionSummary,
  useGetTopDetectionEntitiesPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { GraphQLReqStatus } from '@gamma/progress';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { EntityDetailCard } from '../../EntityDetailCard';

const AlertCategoriesDetectionsTablePopperContent = ({
  first_seen,
  last_seen,
  alert_type,
  alert_name,
  severity,
  entity_count,
  tenant_detection,
}: DetectionSummary) => {
  const { orgTenantsQueryParam } = useContext(OrgTenantsContext);

  const navigate = useNavigate();

  const { start, end } = useDateRangeQueryString();

  const { loading, error, data } = useGetTopDetectionEntitiesPaginated({
    notifyOnNetworkStatusChange: true,
    skip: !start || !end || !orgTenantsQueryParam?.length,
    variables: {
      start,
      end,
      offset: 0,
      size: 5,
      sort: [
        {
          sort_by: 'last_seen',
          sort_dir: 'desc',
        },
      ],
      must_conds: [
        `{"terms": {"tenant": ${JSON.stringify(orgTenantsQueryParam)}}}`,
        '{"term": {"detection_status": "open"}}',
        `{"term":{"detection_keys.tenant_detection":"${tenant_detection}"}}`,
      ],
    },
  });

  if (loading) {
    return <GraphQLReqStatus loading={loading} />;
  }

  return (
    <>
      <PopoverHeader
        fontWeight="normal"
        data-testid="alert-detail-card-alert-rollup-score"
      >
        <HStack>
          <SeverityScore score={severity} />
          <VStack spacing={0} alignItems="start">
            <Text
              data-testid="top-entity-table-popper-entity-name"
              textStyle="body-md"
            >
              {alert_name}
            </Text>
            <Text textStyle="body-sm" data-testid="alert-type">
              {`${i18n.popper.type}: ${stringifyAlertType(alert_type)}`}
            </Text>
          </VStack>
        </HStack>
      </PopoverHeader>
      <PopoverBody>
        <Box textStyle="body-sm">
          {i18n.popper.found}{' '}
          <strong data-testid="entity-count">
            {entity_count}{' '}
            {entity_count === 1
              ? i18n.popper.detection
              : i18n.popper.detections}
          </strong>{' '}
          {i18n.popper.onTheFollowingUnique}{' '}
          <strong>{i18n.popper.entities}:</strong>
        </Box>
        <Stack
          maxHeight="300px"
          overflowY="scroll"
          overflowX="hidden"
          spacing={2}
          mt={2}
        >
          {data?.getTopDetectionEntitiesPaginated?.summaries?.map(
            (entity: DetectionSummary, index: number) => (
              <EntityDetailCard key={index} entitySummary={entity} />
            ),
          )}
          {entity_count !== undefined && entity_count > 5 && (
            <Text>
              {i18n.formatString(i18n.popper.xOfXItems, 5, entity_count)}
            </Text>
          )}
        </Stack>
      </PopoverBody>
      <PopoverFooter border={0}>
        <Button
          width="100%"
          variant="solid"
          colorScheme="blue"
          onClick={() =>
            navigate({
              pathname: ROUTES.detections,
              search: `start=${start}&end=${end}&alert_category=${alert_name}`,
            })
          }
        >
          {entity_count === 1
            ? i18n.buttons.viewDetection
            : i18n.buttons.viewDetections}
        </Button>
      </PopoverFooter>
    </>
  );
};

export const AlertCategoriesDetectionsTablePopper =
  (placement: PlacementWithLogical = 'left-start') =>
  ({
    children,
    ...rest
  }: (DetectionSummary | Partial<Record<keyof DetectionSummary, any>>) & {
    children: ReactNode;
  }) => {
    return (
      <Popover isLazy trigger="hover" openDelay={300} placement={placement}>
        <PopoverTrigger>
          <Box role="button" data-testid="top-alerts-table-popover-trigger">
            {children}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="500px">
            <AlertCategoriesDetectionsTablePopperContent
              {...(rest as DetectionSummary)}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    );
  };
