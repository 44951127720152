import { Flex, Text, VStack } from '@chakra-ui/react';
import { PageHeader } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  useGetEDRConfiguration,
  useGetEDRConfigurationPublic,
  useQueryEscalationConfigurations,
  useQueryEscalationConfigurationsPublic,
} from '@gamma/investigator/queries';
import { Board, Column } from '@gamma/layout';
import { Alert } from '@gamma/overlay';
import { NavTab, NavTabs } from '@gamma/tabs';
import { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';

const { system, backToIntegrations } = i18n.navigation;
const { analystContactAdmin } = i18n.pages.integrations;

export const Integrations = ({
  getAppConfigurationsLoading,
  getAppConfigurationsData,
}: any) => {
  const tabs: NavTab[] = [
    {
      title: system.integrations,
      to: ROUTES.systemSettingsIntegrationsConfig,
      isDisabled: false,
    },
    {
      title: system.alertExports,
      to: ROUTES.systemSettingsIntegrationsAlertExports,
      isDisabled: false,
    },
  ];

  const { integration } = useParams();

  const { userRole, setUserPrivileges, userPrivileges, userLicense } =
    useContext(AuthContext);
  const { tenant = '' } = userLicense ?? {};

  const getEDRConfigQuery =
    userRole === 'admin'
      ? useGetEDRConfiguration
      : useGetEDRConfigurationPublic;

  const serviceNowConfigurationQuery =
    userRole === 'admin'
      ? useQueryEscalationConfigurations
      : useQueryEscalationConfigurationsPublic;

  const { loading: CrowdstrikeEDRConfigLoading } = getEDRConfigQuery({
    variables: {
      provider_name: 'crowdstrike',
    },
    onCompleted: (data) => {
      if (data?.getEDRConfiguration) {
        const newUserPrivileges = { ...userPrivileges };
        newUserPrivileges[tenant].crowdstrike =
          data?.getEDRConfiguration?.enabled;
        setUserPrivileges(newUserPrivileges);
      }
    },
  });

  const { loading: paloAltoEDRConfigLoading } = getEDRConfigQuery({
    variables: {
      provider_name: 'palo_alto_networks',
    },
    onCompleted: ({ getEDRConfiguration }) => {
      const newUserPrivileges = { ...userPrivileges };
      newUserPrivileges[tenant]['palo-alto-networks'] =
        getEDRConfiguration?.enabled;
      setUserPrivileges(newUserPrivileges);
    },
  });

  const { loading: MSDefenderEDRConfigLoading } = getEDRConfigQuery({
    variables: {
      provider_name: 'microsoft_defender',
    },
    onCompleted: ({ getEDRConfiguration }) => {
      const newUserPrivileges = { ...userPrivileges };
      newUserPrivileges[tenant]['microsoft-defender'] =
        getEDRConfiguration?.enabled;
      setUserPrivileges(newUserPrivileges);
    },
  });

  const { loading: serviceNowLoading } = serviceNowConfigurationQuery({
    onCompleted: (data) => {
      if (data) {
        const newUserPrivileges = { ...userPrivileges };
        newUserPrivileges[tenant]['service-now'] =
          data?.queryEscalationConfigurations?.escalation_configurations?.[0].enabled;
        setUserPrivileges(newUserPrivileges);
      }
    },
  });

  const featureFlags = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags
    : {};

  const configsLoading =
    MSDefenderEDRConfigLoading ||
    CrowdstrikeEDRConfigLoading ||
    paloAltoEDRConfigLoading ||
    serviceNowLoading ||
    getAppConfigurationsLoading;

  return (
    <Board>
      <Column>
        {integration ? (
          <PageHeader
            showDateSelection={false}
            prevPage={ROUTES.systemSettingsIntegrationsConfig}
            breadcrumbs={[
              {
                label: (
                  <Flex alignItems="center" justifyContent="center">
                    <Text>{backToIntegrations}</Text>
                  </Flex>
                ),
                link: ROUTES.systemSettingsIntegrationsConfig,
              },
            ]}
          />
        ) : (
          <>
            <PageHeader showDateSelection={false}>
              {system.integrations}
            </PageHeader>
            <VStack>
              {userRole !== 'admin' && (
                <Alert status="warning">{analystContactAdmin}</Alert>
              )}
              <NavTabs
                tabs={tabs}
                isFitted={false}
                variant="line"
                basePath="/"
              />
            </VStack>
          </>
        )}
      </Column>
      <Column
        padding={0}
        flexGrow={1}
        display="flex"
        flexBasis="auto"
        flexDirection="column"
      >
        <Outlet
          context={{
            userPrivileges,
            configsLoading,
            featureFlags,
          }}
        />
      </Column>
    </Board>
  );
};
