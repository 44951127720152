import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react';
import { usePrevious } from '@gamma/hooks';
import { ROUTES } from '@gamma/investigator/constants';
import {
  DetectionAlertItem,
  EntityInformationContext,
} from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { DetectionSummary } from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import validator from 'validator';
import { SkeletonLoader } from '../ChatGPT';
import { SeverityScore } from '../SeverityScore';
import {
  EntityInformationActions,
  EntityPopoverButton,
  LogscaleModalSection,
} from './Components';
import { useActiveEntityStates, useEntityInformationQueries } from './hooks';

const { controls } = i18n.pages.detections;

interface EntityInformationTriggerProps {
  isStandalone?: boolean;
  conditionallySuppress?: boolean;
  detectionSummary: DetectionSummary;
}

export const EntityInformationTrigger = memo(
  ({
    isStandalone,
    detectionSummary,
    conditionallySuppress,
  }: EntityInformationTriggerProps) => {
    const {
      entity_id,
      entity_name,
      entity_type,
      entity_category,
      tenant_entity,
      tenant_info,
      last_seen,
    } = detectionSummary || {};

    const { tenant_id } = tenant_info || {};

    const { start, end } = useDateRangeQueryString();

    const { malicious, published, internal, external } =
      i18n.pages.entityDetails;

    const {
      onSuppressOpen,
      onEntityModalOpen,
      activeEntity,
      setActiveEntity,
      hoveredElement,
      setHoveredElement,
      isEntityModalOpen,
    } = useContext(EntityInformationContext);

    const stackRef = useRef<HTMLDivElement>(null);

    const isHoveredEntity = useMemo(
      () =>
        hoveredElement &&
        entity_id === activeEntity?.entityId &&
        hoveredElement.parentElement?.parentElement === stackRef.current,
      [activeEntity?.entityId, entity_id, hoveredElement],
    );

    const {
      useEntityInformationQueriesLoading,
      simpleSearchQueryMetadata,
      simpleSearchQueryOriginator,
      simpleSearchQueryResponder,
    } = useEntityInformationQueries({
      entityId: entity_id,
      tenantId: tenant_id,
      entityName: entity_name,
      hoveredElement,
      isHoveredEntity,
    });

    useActiveEntityStates();

    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>();
    const { entityName, entityCategory, detectionDataList, logscaleRespData } =
      activeEntity || {};

    const detectionCount = useMemo(
      () =>
        activeEntity?.detectionData?.total_items
          ? activeEntity?.detectionData?.total_items
          : 0,
      [activeEntity?.detectionData?.total_items],
    );

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        showSuppressEntity: conditionallySuppress
          ? detectionCount === 1 && entityCategory === 'source'
            ? true
            : false
          : entityCategory === 'source'
            ? true
            : false,
      }));
    }, [
      conditionallySuppress,
      detectionCount,
      entityCategory,
      setActiveEntity,
    ]);

    const prevActiveEntity = usePrevious(activeEntity);

    const handleEntityMouseEnter = useCallback(
      async (event: React.MouseEvent<HTMLDivElement>) => {
        setContextMenuOpen(false);
        if (entity_id !== prevActiveEntity?.entityId) {
          setActiveEntity?.(undefined);
        }
        setHoveredElement?.(event.target as HTMLDivElement);
        await debounce(() => {
          setActiveEntity?.((prevState) => ({
            ...prevState,
            end,
            start,
            entityId: entity_id,
            entityName: entity_name,
            entityType: entity_type,
            entityCategory: entity_category,
            lastSeen: last_seen,
            tenantInfo: tenant_info,
            tenantEntity: tenant_entity,
          }));
        }, 300)();
      },
      [
        entity_id,
        prevActiveEntity?.entityId,
        setHoveredElement,
        setActiveEntity,
        end,
        start,
        entity_name,
        entity_type,
        entity_category,
        last_seen,
        tenant_info,
        tenant_entity,
      ],
    );

    const handleEntityMouseLeave = useCallback(() => {
      setHoveredElement?.(undefined);
    }, [setHoveredElement]);

    const handleEntityClick = () => {
      onEntityModalOpen?.();
      setContextMenuOpen?.(false);
    };

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        isLoading: useEntityInformationQueriesLoading,
      }));
    }, [setActiveEntity, useEntityInformationQueriesLoading]);

    useEffect(() => {
      if (
        ((hoveredElement && isHoveredEntity) ||
          (isEntityModalOpen && activeEntity?.entityId === entity_id)) &&
        activeEntity?.timeoutId
      ) {
        simpleSearchQueryMetadata();
        simpleSearchQueryOriginator();
        simpleSearchQueryResponder();
      }
    }, [
      entity_id,
      hoveredElement,
      isHoveredEntity,
      isEntityModalOpen,
      activeEntity?.entityId,
      activeEntity?.timeoutId,
      simpleSearchQueryMetadata,
      simpleSearchQueryOriginator,
      simpleSearchQueryResponder,
    ]);

    const detectionsPivotParams = useMemo(
      () => `start=${start}&end=${end}&source_name=${entityName}`,
      [end, entityName, start],
    );

    const {
      'ioc[0].published_date': iocPublishedDate,
      'ioc[0].malicious_confidence': iocMaliciousConfidence,
      local_resp,
    } = logscaleRespData || {};

    if (!validator.isIP(entity_name)) {
      return (
        <Text px={2} py={1}>
          {entity_name}
        </Text>
      );
    }

    return (
      <VStack
        ref={stackRef}
        onMouseEnter={handleEntityMouseEnter}
        onMouseLeave={handleEntityMouseLeave}
      >
        <Popover
          isLazy
          offset={[0, 0]}
          placement="bottom-start"
          isOpen={isHoveredEntity}
        >
          <EntityPopoverButton
            entityName={entity_name}
            isStandalone={isStandalone}
            entityCategory={entity_category}
            detectionCount={detectionCount}
            contextMenuOpen={contextMenuOpen}
            isHoveredEntity={isHoveredEntity}
            handleEntityClick={handleEntityClick}
            setContextMenuOpen={setContextMenuOpen}
          />
          {!contextMenuOpen && (
            <Portal>
              <PopoverContent border="1px solid" borderColor="layer.3">
                {isHoveredEntity &&
                (detectionDataList === undefined ||
                  activeEntity?.isLoading ||
                  activeEntity?.isSimpleSearchLoading) ? (
                  <Box w="400px">
                    <PopoverHeader>
                      <Heading textStyle="body-md-bold">
                        <Text>{entity_name}</Text>
                        <SkeletonLoader rowCount={1} />
                      </Heading>
                    </PopoverHeader>
                    <PopoverBody>
                      <SkeletonLoader rowCount={5} />
                    </PopoverBody>
                  </Box>
                ) : (
                  <Box w="400px">
                    <PopoverHeader>
                      <Heading textStyle="body-md-bold">{entity_name}</Heading>
                      <HStack>
                        {/* TODO: reimplement when data is available
                        {local_resp === 'false' ? (
                          <Text
                            bg="blue.800"
                            color="blue.200"
                            borderRadius="md"
                            paddingX={1}
                          >{`${external}`}</Text>
                        ) : (
                          <Text
                            bg="blue.800"
                            color="blue.200"
                            borderRadius="md"
                            paddingX={1}
                          >{`${internal}`}</Text>
                        )}
                        */}
                        {iocMaliciousConfidence && (
                          <>
                            <Text
                              bg="red.800"
                              color="red.200"
                              borderRadius="md"
                            >
                              {`${malicious}`}
                            </Text>
                            <Text
                              bg="red.800"
                              color="red.200"
                              borderRadius="md"
                              textTransform="capitalize"
                            >
                              {`${iocMaliciousConfidence}`}
                            </Text>
                          </>
                        )}
                        {iocPublishedDate && (
                          <Text
                            bg="blue.800"
                            color="blue.200"
                            borderRadius="md"
                          >{`${published} ${moment(Number(iocPublishedDate)).format('MMMM D, YYYY')}`}</Text>
                        )}
                      </HStack>
                    </PopoverHeader>
                    <PopoverBody>
                      <VStack alignItems="start">
                        <LogscaleModalSection
                          displayCard={true}
                          showHeader={false}
                          activeEntity={activeEntity}
                        />
                        <Text>Detections</Text>
                        <VStack alignItems="start" divider={<Divider />}>
                          {detectionDataList &&
                            detectionDataList?.length > 0 &&
                            detectionDataList?.map(
                              (alert: DetectionAlertItem) => (
                                <HStack w="376px" key={alert.alert_name}>
                                  <SeverityScore
                                    score={alert?.severity}
                                    isCustom={alert?.is_custom_severity}
                                  />
                                  <Button
                                    w="340px"
                                    variant="link"
                                    as={RouterLink}
                                    justifyContent="start"
                                    to={{
                                      pathname: ROUTES.detections,
                                      search: `${detectionsPivotParams}&alert_category=${alert?.alert_name}`,
                                    }}
                                  >
                                    <Text
                                      as="span"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      textOverflow="ellipsis"
                                    >
                                      {alert?.alert_name}
                                    </Text>
                                  </Button>
                                </HStack>
                              ),
                            )}
                          {detectionCount > 3 && (
                            <Button
                              variant="link"
                              as={RouterLink}
                              to={{
                                pathname: ROUTES.detections,
                                search: detectionsPivotParams,
                              }}
                            >
                              {controls.viewAll}
                            </Button>
                          )}
                        </VStack>
                      </VStack>
                    </PopoverBody>
                    <PopoverFooter border={0} alignItems="end">
                      <EntityInformationActions
                        end={end}
                        start={start}
                        entityName={activeEntity?.entityName}
                        entityCategory={activeEntity?.entityCategory}
                        onSuppressEntity={onSuppressOpen}
                        entityIsSuppressed={activeEntity?.entityIsSuppressed}
                      />
                    </PopoverFooter>
                  </Box>
                )}
              </PopoverContent>
            </Portal>
          )}
        </Popover>
      </VStack>
    );
  },
);
