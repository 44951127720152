import {
  Accordion,
  AccordionItem,
  Box,
  Flex,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '@gamma/icons';
import { useQueryWithRetry } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  useCreateQueryJob,
  usePollQueryJob,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import { IPollQueryJob } from 'libs/investigator/queries/src/lib/humio/types';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LogscaleDetailsModal } from '../LogscaleDetailsModal';
import { ConnHistorySummary } from './ConnHistorySummary';
const { alerts, detection } = i18n.pages.detections;

const {
  connection,
  dns,
  http,
  ssl,
  file,
  smb,
  intel,
  notice,
  suricata,
  yara,
  anomaly,
  seeMore,
} = i18n.pages.detections.logscaleDetails;

interface LogscaleDetailsProps {
  alertsUid: string;
  alertsName: string;
  alertsTimestamp: string;
  alertsTenant?: string;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
  logScaleQuery?: string;
}

interface connectionDetailsParsedData {
  history: string;
  orig_bytes: string;
  local_orig: string;
  local_resp: string;
  resp_bytes: string;
  service: string;
  _write_ts: string;
  'id.resp_p': string;
  'spcap.url': string;
}

interface httpDetailsParsedData {
  method: string;
  uri: string;
  user_agent: string;
  post_body: string;
}

interface sslDetailsParsedData {
  subject: string;
  '@rawstring': string;
  server_name: string;
  issuer: string;
  validation_status: string;
}

interface fileDetailsParsedData {
  mime_type: string;
  'rx_hosts[0]': string;
  seen_bytes: string;
  extracted?: string;
  sha1?: string;
}

interface dnsDetailsParsedData {
  '@rawstring': string;
  answers: string[];
  query: string;
  qtype_name: string;
  rcode_name: string;
}

interface smbDetailsParsedData {
  path: string;
}

interface intelDetailsParsedData {
  'seen.indicator': string;
  'seen.indicator_type': string;
  'seen.where': string;
}

interface noticeDetailsParsedData {
  msg: string;
  sub: string;
  note: string;
}

interface suricataDetailsParsedData {
  'alert.category': string;
}

interface yaraDetailsParsedData {
  'alert.signature': string;
}

interface anomalyDetailsParsedData {
  'alert.signature': string;
}

interface MultiEventsSelectProps {
  currentPage: number;
  pageLength: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const NoDataPanel = ({ title }: { title: string }) => {
  return (
    <AccordionItem bgColor="transparent">
      <Box px={4} py={2} color="text.secondary" fontSize={16}>
        {title} (N/A)
      </Box>
    </AccordionItem>
  );
};

const MultiEventsSelect = ({
  currentPage,
  pageLength,
  setCurrentPage,
}: MultiEventsSelectProps) => {
  const handleNext = () => {
    if (currentPage < pageLength - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    pageLength > 1 && (
      <Flex justify="space-between" align="center" mb={4}>
        <IconButton
          icon={<ChevronLeftIcon />}
          variant="ghost"
          color="gray"
          onClick={handlePrevious}
          isDisabled={currentPage === 0}
          aria-label="Previous"
        />
        <Text fontSize="lg">
          {currentPage + 1}/{pageLength}
        </Text>
        <IconButton
          icon={<ChevronRightIcon />}
          variant="ghost"
          color="gray"
          onClick={handleNext}
          isDisabled={currentPage === pageLength - 1}
          aria-label="Next"
        />
      </Flex>
    )
  );
};

export const LogscaleDetails = ({
  alertsUid,
  alertsName,
  alertsTimestamp,
  alertsTenant,
  setDownloadUrl,
  logScaleQuery,
}: LogscaleDetailsProps) => {
  const [connectionDetailsParsedData, setConnectionDetailsParsedData] =
    useState<connectionDetailsParsedData>();
  const [httpDetailsParsedData, setHttpDetailsParsedData] = useState<
    httpDetailsParsedData[]
  >([]);
  const [sslDetailsParsedData, setSslDetailsParsedData] =
    useState<sslDetailsParsedData>();
  const [fileDetailsParsedData, setFileDetailsParsedData] = useState<
    fileDetailsParsedData[]
  >([]);
  const [dnsDetailsParsedData, setDnsDetailsParsedData] = useState<
    dnsDetailsParsedData[]
  >([]);
  const [smbDetailsParsedData, setSmbDetailsParsedData] =
    useState<smbDetailsParsedData>();
  const [intelDetailsParsedData, setIntelDetailsParsedData] = useState<
    intelDetailsParsedData[]
  >([]);
  const [noticeDetailsParsedData, setNoticeDetailsParsedData] = useState<
    noticeDetailsParsedData[]
  >([]);
  const [suricataDetailsParsedData, setSuricataDetailsParsedData] = useState<
    suricataDetailsParsedData[]
  >([]);
  const [yaraDetailsParsedData, setYaraDetailsParsedData] = useState<
    yaraDetailsParsedData[]
  >([]);
  const [anomalyDetailsParsedData, setAnomalyDetailsParsedData] = useState<
    anomalyDetailsParsedData[]
  >([]);
  const [moreDetailsParsedData, setMoreDetailsParsedData] = useState<any>();
  const [logDetailsParsedData, setLogDetailsParsedData] = useState<any[]>([]);

  const [logscaleDetailTitle, setLogscaleDetailTitle] = useState<string>();
  const [sslCertChainFPS, setSslCertChainFPS] = useState<string>('-');
  const [dnsCurrentPage, setDnsCurrentPage] = useState<number>(0);
  const [httpCurrentPage, setHttpCurrentPage] = useState<number>(0);
  const [filesCurrentPage, setFilesCurrentPage] = useState<number>(0);
  const [intelCurrentPage, setIntelCurrentPage] = useState<number>(0);
  const [noticeCurrentPage, setNoticeCurrentPage] = useState<number>(0);
  const [suricataCurrentPage, setSuricataCurrentPage] = useState<number>(0);
  const [yaraCurrentPage, setYaraCurrentPage] = useState<number>(0);
  const [anomalyCurrentPage, setAnomalyCurrentPage] = useState<number>(0);
  const [logDetailsQueryJobId, setLogDetailsQueryJobId] = useState<string>();

  const {
    isOpen: showMoreConnectionIsOpen,
    onOpen: showMoreConnectionOnOpen,
    onClose: showMoreConnectionOnClose,
  } = useDisclosure();

  const startTime = moment(alertsTimestamp).subtract(20, 'minutes').unix();
  const endTime = moment(alertsTimestamp).add(20, 'minutes').unix();

  const [
    logDetailsCreateQueryJob,
    { loading: logDetailsCreateQueryJobLoading },
  ] = useCreateQueryJob({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryInputJob: {
        queryStringWithoutSortSize: alertsUid
          ? `uid="${alertsUid}"`
          : logScaleQuery,
        start: startTime.toString(),
        end: endTime.toString(),
        tenantsForQuery: [alertsTenant],
      },
      size: 400,
      sort: [
        {
          sort_by: '_write_ts',
          sort_dir: 'asc',
        },
      ],
    },
    onCompleted: (data) => {
      if (data?.createQueryJob?.query_job_id) {
        setLogDetailsQueryJobId(data.createQueryJob.query_job_id);
      }
    },
  });

  const { loading: logDetailsLoading } = useQueryWithRetry<IPollQueryJob>({
    queryHook: usePollQueryJob,
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryJobId: logDetailsQueryJobId,
    },
    skip: !logDetailsQueryJobId,
    onCompleted(data) {
      const logsData = data?.pollQueryJob?.events?.map((item) =>
        JSON.parse(item),
      );
      setLogDetailsParsedData(logsData);
    },
    onError() {
      setLogDetailsParsedData([]);
    },
  });

  const handleSearchQuery = (pathName: string, currentIndex: number) => {
    switch (pathName) {
      case 'conn': {
        setMoreDetailsParsedData(connectionDetailsParsedData);
        break;
      }
      case 'dns': {
        setMoreDetailsParsedData(dnsDetailsParsedData[currentIndex]);
        break;
      }
      case 'http': {
        setMoreDetailsParsedData(httpDetailsParsedData[currentIndex]);
        break;
      }
      case 'files': {
        setMoreDetailsParsedData(fileDetailsParsedData[currentIndex]);
        break;
      }
      case 'ssl': {
        setMoreDetailsParsedData(sslDetailsParsedData);
        break;
      }
      case 'smb_mapping': {
        setMoreDetailsParsedData(smbDetailsParsedData);
        break;
      }
      case 'intel': {
        setMoreDetailsParsedData(intelDetailsParsedData[currentIndex]);
        break;
      }
      case 'notice': {
        setMoreDetailsParsedData(noticeDetailsParsedData[currentIndex]);
        break;
      }
      case 'suricata_corelight': {
        setMoreDetailsParsedData(suricataDetailsParsedData[currentIndex]);
        break;
      }
      case 'yara_corelight': {
        setMoreDetailsParsedData(yaraDetailsParsedData[currentIndex]);
        break;
      }
      case 'anomaly': {
        setMoreDetailsParsedData(anomalyDetailsParsedData[currentIndex]);
        break;
      }
    }
  };

  useEffect(() => {
    if (alertsUid || logScaleQuery) {
      logDetailsCreateQueryJob();
    }
  }, [alertsUid, alertsTimestamp, localStorage.getItem('session_id_token')]);

  useEffect(() => {
    if (logDetailsParsedData.length > 0) {
      let connDetails: connectionDetailsParsedData;
      let dnsDetails: dnsDetailsParsedData[] = [];
      let httpDetails: httpDetailsParsedData[] = [];
      let filesDetails: fileDetailsParsedData[] = [];
      let sslDetails: sslDetailsParsedData;
      let smbDetails: smbDetailsParsedData;
      let intelDetails: intelDetailsParsedData[] = [];
      let noticeDetails: noticeDetailsParsedData[] = [];
      let suricataDetails: suricataDetailsParsedData[] = [];
      let yaraDetails: yaraDetailsParsedData[] = [];
      let anomalyDetails: anomalyDetailsParsedData[] = [];

      logDetailsParsedData.map((logDetail) => {
        switch (logDetail?.['#path']) {
          case 'conn': {
            connDetails = logDetail as connectionDetailsParsedData;
            setDownloadUrl &&
              setDownloadUrl(
                connDetails?.['spcap.url'] ? connDetails?.['spcap.url'] : '',
              );
            setConnectionDetailsParsedData(connDetails);
            break;
          }
          case 'dns': {
            const dnsDetail = logDetail as dnsDetailsParsedData;
            const rawString = JSON.parse(dnsDetail['@rawstring']);
            const answers = rawString?.answers as string[];
            dnsDetail.answers = answers;
            dnsDetails.push(dnsDetail);
            break;
          }
          case 'http': {
            httpDetails.push(logDetail as httpDetailsParsedData);
            break;
          }
          case 'files': {
            filesDetails.push(logDetail as fileDetailsParsedData);
            break;
          }
          case 'ssl': {
            sslDetails = logDetail as sslDetailsParsedData;
            if (sslDetails['@rawstring']) {
              const rawString = JSON.parse(sslDetails['@rawstring']);
              const certChainFps = rawString?.cert_chain_fps as string[];
              setSslCertChainFPS(JSON.stringify(certChainFps));
            }
            setSslDetailsParsedData(sslDetails);
            break;
          }
          case 'smb_mapping': {
            smbDetails = logDetail as smbDetailsParsedData;
            setSmbDetailsParsedData(smbDetails);
            break;
          }
          case 'intel': {
            intelDetails.push(logDetail as intelDetailsParsedData);
            break;
          }
          case 'notice': {
            if (logDetail?.note != alertsName) {
              noticeDetails.push(logDetail as noticeDetailsParsedData);
            }
            break;
          }
          case 'suricata_corelight': {
            if (logDetail?.['alert.signature'] != alertsName) {
              suricataDetails.push(logDetail as suricataDetailsParsedData);
            }
            break;
          }
          case 'yara_corelight': {
            if (logDetail?.['alert.signature'] != alertsName) {
              yaraDetails.push(logDetail as yaraDetailsParsedData);
            }
            break;
          }
          case 'anomaly': {
            if (logDetail?.['alert.signature'] != alertsName) {
              anomalyDetails.push(logDetail as anomalyDetailsParsedData);
            }
            break;
          }
        }
      });

      setDnsDetailsParsedData(dnsDetails);
      setHttpDetailsParsedData(httpDetails);
      setFileDetailsParsedData(filesDetails);
      setIntelDetailsParsedData(intelDetails);
      setNoticeDetailsParsedData(noticeDetails);
      setSuricataDetailsParsedData(suricataDetails);
      setYaraDetailsParsedData(yaraDetails);
      setAnomalyDetailsParsedData(anomalyDetails);
    }
  }, [logDetailsParsedData]);

  return (
    <VStack w="100%" alignItems="start">
      <Accordion w="100%">
        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            !connectionDetailsParsedData && (
              <NoDataPanel title={connection.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            connectionDetailsParsedData) && (
            <AccordionPanel title={connection.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <DetailsGrid>
                  <DetailsGridRow
                    title={connection.history}
                    data-testid="detections-history"
                  >
                    {connectionDetailsParsedData?.history ? (
                      <ConnHistorySummary
                        history={connectionDetailsParsedData.history}
                      />
                    ) : (
                      <Text overflowWrap="anywhere">-</Text>
                    )}
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.protocol}
                    data-testid="detections-protocol"
                  >
                    <Text overflowWrap="anywhere">
                      {connectionDetailsParsedData?.service
                        ? connectionDetailsParsedData.service
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.port}
                    data-testid="detections-port"
                  >
                    <Text overflowWrap="anywhere">
                      {connectionDetailsParsedData?.['id.resp_p']
                        ? connectionDetailsParsedData['id.resp_p']
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.bytesSent}
                    data-testid="detections-bytes-sent"
                  >
                    <Text overflowWrap="anywhere">
                      {connectionDetailsParsedData?.orig_bytes
                        ? connectionDetailsParsedData.orig_bytes
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.bytesReturned}
                    data-testid="detections-bytes-returned"
                  >
                    <Text overflowWrap="anywhere">
                      {connectionDetailsParsedData?.resp_bytes
                        ? connectionDetailsParsedData.resp_bytes
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.localOriginator}
                    data-testid="detections-local-originator"
                  >
                    <Text overflowWrap="anywhere">
                      {connectionDetailsParsedData?.local_orig
                        ? connectionDetailsParsedData.local_orig
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={connection.localResponder}
                    data-testid="detections-local-responder"
                  >
                    <Text>
                      {connectionDetailsParsedData?.local_resp
                        ? connectionDetailsParsedData.local_resp
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('conn', 0);
                      setLogscaleDetailTitle(connection.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </DetailsGrid>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            dnsDetailsParsedData.length == 0 && (
              <NoDataPanel title={dns.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            dnsDetailsParsedData.length > 0) && (
            <AccordionPanel title={dns.title}>
              {logDetailsLoading || logDetailsCreateQueryJobLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsLoading || logDetailsCreateQueryJobLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={dnsCurrentPage}
                    pageLength={dnsDetailsParsedData.length}
                    setCurrentPage={setDnsCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={dns.answers}
                      data-testid="dns-answers"
                    >
                      <Text overflowWrap="anywhere">
                        {dnsDetailsParsedData[dnsCurrentPage]?.answers
                          ? dnsDetailsParsedData[dnsCurrentPage].answers
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow title={dns.query} data-testid="dns-query">
                      <Text overflowWrap="anywhere">
                        {dnsDetailsParsedData[dnsCurrentPage]?.query
                          ? dnsDetailsParsedData[dnsCurrentPage].query
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={dns.qtypeName}
                      data-testid="dns-qtype-name"
                    >
                      <Text overflowWrap="anywhere">
                        {dnsDetailsParsedData[dnsCurrentPage]?.qtype_name
                          ? dnsDetailsParsedData[dnsCurrentPage].qtype_name
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={dns.rcodeName}
                      data-testid="dns-rcode-name"
                    >
                      <Text overflowWrap="anywhere">
                        {dnsDetailsParsedData[dnsCurrentPage]?.rcode_name
                          ? dnsDetailsParsedData[dnsCurrentPage].rcode_name
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <Link
                      color="text.link"
                      data-testid="see-more-button"
                      onClick={() => {
                        handleSearchQuery('dns', dnsCurrentPage);
                        setLogscaleDetailTitle(dns.title);
                        showMoreConnectionOnOpen();
                      }}
                    >
                      {seeMore}
                    </Link>
                  </DetailsGrid>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            httpDetailsParsedData.length == 0 && (
              <NoDataPanel title={http.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            httpDetailsParsedData.length > 0) && (
            <AccordionPanel title={http.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={httpCurrentPage}
                    pageLength={httpDetailsParsedData.length}
                    setCurrentPage={setHttpCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={http.uri}
                      data-testid="detections-uri"
                    >
                      <Text overflowWrap="anywhere">
                        {httpDetailsParsedData[httpCurrentPage]?.uri
                          ? httpDetailsParsedData[httpCurrentPage]?.uri
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={http.method}
                      data-testid="detections-method"
                    >
                      <Text overflowWrap="anywhere">
                        {httpDetailsParsedData[httpCurrentPage]?.method
                          ? httpDetailsParsedData[httpCurrentPage]?.method
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={http.postBody}
                      data-testid="detections-post-body"
                    >
                      <Text overflowWrap="anywhere">
                        {httpDetailsParsedData[httpCurrentPage]?.post_body
                          ? httpDetailsParsedData[httpCurrentPage]?.post_body
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={http.userAgent}
                      data-testid="detections-user-agent"
                    >
                      <Text overflowWrap="anywhere">
                        {httpDetailsParsedData[httpCurrentPage]?.user_agent
                          ? httpDetailsParsedData[httpCurrentPage]?.user_agent
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <Link
                      color="text.link"
                      data-testid="see-more-button"
                      onClick={() => {
                        handleSearchQuery('http', httpCurrentPage);
                        setLogscaleDetailTitle(http.title);
                        showMoreConnectionOnOpen();
                      }}
                    >
                      {seeMore}
                    </Link>
                  </DetailsGrid>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            !sslDetailsParsedData && <NoDataPanel title={ssl.title} />}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            sslDetailsParsedData) && (
            <AccordionPanel title={ssl.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <DetailsGrid>
                  <DetailsGridRow
                    title="Subject"
                    data-testid="detections-subject"
                  >
                    <Text overflowWrap="anywhere">
                      {sslDetailsParsedData?.subject
                        ? sslDetailsParsedData?.subject
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={ssl.certChainFps}
                    data-testid="detections-cert-chain-fps"
                  >
                    <Text overflowWrap="anywhere">{sslCertChainFPS}</Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={ssl.serverName}
                    data-testid="detections-server-name"
                  >
                    <Text overflowWrap="anywhere">
                      {sslDetailsParsedData?.server_name
                        ? sslDetailsParsedData?.server_name
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={ssl.issuer}
                    data-testid="detections-issuer"
                  >
                    <Text overflowWrap="anywhere">
                      {sslDetailsParsedData?.issuer
                        ? sslDetailsParsedData?.issuer
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <DetailsGridRow
                    title={ssl.validationStatus}
                    data-testid="detections-validation-status"
                  >
                    <Text overflowWrap="anywhere">
                      {sslDetailsParsedData?.validation_status
                        ? sslDetailsParsedData?.validation_status
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('ssl', 0);
                      setLogscaleDetailTitle(ssl.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </DetailsGrid>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            fileDetailsParsedData.length == 0 && (
              <NoDataPanel title={file.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            fileDetailsParsedData.length > 0) && (
            <AccordionPanel title={file.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={filesCurrentPage}
                    pageLength={fileDetailsParsedData.length}
                    setCurrentPage={setFilesCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={file.mimeType}
                      data-testid="detections-mime-type"
                    >
                      <Text overflowWrap="anywhere">
                        {fileDetailsParsedData[filesCurrentPage]?.mime_type
                          ? fileDetailsParsedData[filesCurrentPage]?.mime_type
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={file.rxHosts}
                      data-testid="detections-rx-hosts"
                    >
                      <Text overflowWrap="anywhere">
                        {fileDetailsParsedData[filesCurrentPage]?.[
                          'rx_hosts[0]'
                        ]
                          ? fileDetailsParsedData[filesCurrentPage]?.[
                              'rx_hosts[0]'
                            ]
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={file.seenBytes}
                      data-testid="detections-seen-bytes"
                    >
                      <Text overflowWrap="anywhere">
                        {fileDetailsParsedData[filesCurrentPage]?.seen_bytes
                          ? fileDetailsParsedData[filesCurrentPage]?.seen_bytes
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title="Filename"
                      data-testid="detections-filename"
                    >
                      <Text overflowWrap="anywhere">
                        {fileDetailsParsedData[filesCurrentPage]?.extracted
                          ? fileDetailsParsedData[filesCurrentPage]?.extracted
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title="SHA1"
                      data-testid="detections-filename"
                    >
                      <Text overflowWrap="anywhere">
                        {fileDetailsParsedData[filesCurrentPage]?.sha1
                          ? fileDetailsParsedData[filesCurrentPage]?.sha1
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <Link
                      color="text.link"
                      data-testid="see-more-button"
                      onClick={() => {
                        handleSearchQuery('files', filesCurrentPage);
                        setLogscaleDetailTitle(file.title);
                        showMoreConnectionOnOpen();
                      }}
                    >
                      {seeMore}
                    </Link>
                  </DetailsGrid>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            smbDetailsParsedData) && (
            <AccordionPanel title={smb.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <DetailsGrid>
                  <DetailsGridRow title={smb.path} data-testid="smb-path">
                    <Text overflowWrap="anywhere">
                      {smbDetailsParsedData?.path
                        ? smbDetailsParsedData.path
                        : '-'}
                    </Text>
                  </DetailsGridRow>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('smb_mapping', 0);
                      setLogscaleDetailTitle(smb.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </DetailsGrid>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            intelDetailsParsedData.length > 0) && (
            <AccordionPanel title={intel.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={intelCurrentPage}
                    pageLength={intelDetailsParsedData.length}
                    setCurrentPage={setIntelCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={intel.indicator}
                      data-testid="intel-indicator"
                    >
                      <Text overflowWrap="anywhere">
                        {intelDetailsParsedData[intelCurrentPage]?.[
                          'seen.indicator'
                        ]
                          ? intelDetailsParsedData[intelCurrentPage][
                              'seen.indicator'
                            ]
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={intel.indicatorType}
                      data-testid="intel-indicator-type"
                    >
                      <Text overflowWrap="anywhere">
                        {intelDetailsParsedData[intelCurrentPage]?.[
                          'seen.indicator_type'
                        ]
                          ? intelDetailsParsedData[intelCurrentPage][
                              'seen.indicator_type'
                            ]
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={intel.where}
                      data-testid="intel-where"
                    >
                      <Text overflowWrap="anywhere">
                        {intelDetailsParsedData[intelCurrentPage]?.[
                          'seen.where'
                        ]
                          ? intelDetailsParsedData[intelCurrentPage][
                              'seen.where'
                            ]
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <Link
                      color="text.link"
                      data-testid="see-more-button"
                      onClick={() => {
                        handleSearchQuery('intel', intelCurrentPage);
                        setLogscaleDetailTitle(intel.title);
                        showMoreConnectionOnOpen();
                      }}
                    >
                      {seeMore}
                    </Link>
                  </DetailsGrid>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            noticeDetailsParsedData.length > 0) && (
            <AccordionPanel title={notice.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={noticeCurrentPage}
                    pageLength={noticeDetailsParsedData.length}
                    setCurrentPage={setNoticeCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow title={notice.msg} data-testid="notice-msg">
                      <Text overflowWrap="anywhere">
                        {noticeDetailsParsedData[noticeCurrentPage]?.msg
                          ? noticeDetailsParsedData[noticeCurrentPage].msg
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow title={notice.sub} data-testid="notice-sub">
                      <Text overflowWrap="anywhere">
                        {noticeDetailsParsedData[noticeCurrentPage]?.sub
                          ? noticeDetailsParsedData[noticeCurrentPage].sub
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <DetailsGridRow
                      title={notice.note}
                      data-testid="notice-note"
                    >
                      <Text overflowWrap="anywhere">
                        {noticeDetailsParsedData[noticeCurrentPage]?.note
                          ? noticeDetailsParsedData[noticeCurrentPage].note
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                    <Link
                      color="text.link"
                      data-testid="see-more-button"
                      onClick={() => {
                        handleSearchQuery('notice', noticeCurrentPage);
                        setLogscaleDetailTitle(notice.title);
                        showMoreConnectionOnOpen();
                      }}
                    >
                      {seeMore}
                    </Link>
                  </DetailsGrid>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        {/* Yara Alerts Panel */}
        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            yaraDetailsParsedData.length === 0 && (
              <NoDataPanel title={yara.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            yaraDetailsParsedData.length > 0) && (
            <AccordionPanel title={yara.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={yaraCurrentPage}
                    pageLength={yaraDetailsParsedData.length}
                    setCurrentPage={setYaraCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={'Alert Signature'}
                      data-testid="yara-alert-signature"
                    >
                      <Text overflowWrap="anywhere">
                        {yaraDetailsParsedData[yaraCurrentPage]?.[
                          'alert.signature'
                        ] || '-'}
                      </Text>
                    </DetailsGridRow>
                  </DetailsGrid>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('yara_corelight', yaraCurrentPage);
                      setLogscaleDetailTitle(yara.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        {/* Anomaly Alerts Panel */}
        <Box w="100%">
          {!logDetailsCreateQueryJobLoading &&
            !logDetailsLoading &&
            anomalyDetailsParsedData.length === 0 && (
              <NoDataPanel title={anomaly.title} />
            )}
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            anomalyDetailsParsedData.length > 0) && (
            <AccordionPanel title={anomaly.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={anomalyCurrentPage}
                    pageLength={anomalyDetailsParsedData.length}
                    setCurrentPage={setAnomalyCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={'Alert Signature'}
                      data-testid="anomaly-alert-signature"
                    >
                      <Text overflowWrap="anywhere">
                        {anomalyDetailsParsedData[anomalyCurrentPage]?.[
                          'alert.signature'
                        ] || '-'}
                      </Text>
                    </DetailsGridRow>
                  </DetailsGrid>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('anomaly', anomalyCurrentPage);
                      setLogscaleDetailTitle(anomaly.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>

        <Box w="100%">
          {(logDetailsCreateQueryJobLoading ||
            logDetailsLoading ||
            suricataDetailsParsedData.length > 0) && (
            <AccordionPanel title={suricata.title}>
              {logDetailsCreateQueryJobLoading || logDetailsLoading ? (
                <GraphQLReqStatus
                  loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
                />
              ) : (
                <>
                  <MultiEventsSelect
                    currentPage={suricataCurrentPage}
                    pageLength={suricataDetailsParsedData.length}
                    setCurrentPage={setSuricataCurrentPage}
                  />
                  <DetailsGrid>
                    <DetailsGridRow
                      title={suricata.category}
                      data-testid="suricata-category"
                    >
                      <Text overflowWrap="anywhere">
                        {suricataDetailsParsedData[suricataCurrentPage]?.[
                          'alert.category'
                        ]
                          ? suricataDetailsParsedData[suricataCurrentPage][
                              'alert.category'
                            ]
                          : '-'}
                      </Text>
                    </DetailsGridRow>
                  </DetailsGrid>
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery(
                        'suricata_corelight',
                        suricataCurrentPage,
                      );
                      setLogscaleDetailTitle(suricata.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                </>
              )}
            </AccordionPanel>
          )}
        </Box>
      </Accordion>

      <LogscaleDetailsModal
        alertsName={alertsName}
        alertsTimestamp={alertsTimestamp}
        isModalOpen={showMoreConnectionIsOpen}
        onModalClose={showMoreConnectionOnClose}
        data={moreDetailsParsedData}
        title={logscaleDetailTitle || ''}
      />
    </VStack>
  );
};
