import { Stack } from '@chakra-ui/react';
import { FieldValidationListPopover, Input } from '@gamma/form-fields';
import { formatOTPCode } from '@gamma/util';
import { ChangeEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormProps } from '../types';

export interface IPasswordRules {
  minLength: boolean;
  hasNumber: boolean;
  hasSpecialCharacter: boolean;
  hasUppercase: boolean;
  hasLowercase: boolean;
}

export interface IPasswordResetForm {
  newPassword: string;
  confirmPassword: string;
  otpCode?: string;
  currentPassword?: string;
}

export interface PasswordResetFormProps extends FormProps<IPasswordResetForm> {
  hasOTP?: boolean;
  hasCurrentPassword?: boolean;
  isLabelHidden?: boolean;
  hasValidationPopover?: boolean;
  onPasswordValidation?: (rules: IPasswordRules) => void;
}

export const PasswordResetForm = ({
  newPasswordContent = {
    placeholder: 'New Password',
    label: 'New Password',
  },
  confirmPasswordContent = {
    placeholder: 'Confirm Password',
    label: 'Confirm Password',
  },
  otpCodeContent = {
    placeholder: 'XXX XXX',
    label: 'Enter OTP',
    error: '',
  },
  currentPasswordContent = {
    placeholder: 'Current Password',
    label: 'Current Password',
  },
  onSubmit: [onSubmitSuccess, onSubmitError],
  hasOTP,
  hasCurrentPassword,
  isLabelHidden = true,
  hasValidationPopover = true,
  autoFocusField,
  onPasswordValidation,
}: PasswordResetFormProps) => {
  const {
    handleSubmit,
    register,
    getValues,
    trigger,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm<IPasswordResetForm>();

  useEffect(() => {
    if (autoFocusField) {
      setFocus(autoFocusField);
    }
  }, [autoFocusField, setFocus]);

  const [rulesMatch, setRulesMatch] = useState<IPasswordRules>(
    {} as IPasswordRules,
  );

  const handleOTPInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue('otpCode', formatOTPCode(value));
  };

  const checkPasswordValidation = (password: string) => {
    const validationRules = {
      minLength: password.length >= 8,
      hasNumber: RegExp(/[0-9]/g).test(password),
      hasSpecialCharacter: RegExp(/[^\w\s]/g).test(password),
      hasUppercase: RegExp(/[A-Z]/g).test(password),
      hasLowercase: RegExp(/[a-z]/g).test(password),
    };
    onPasswordValidation?.(validationRules);
    setRulesMatch(validationRules);
  };

  const handleSubmitSuccess: SubmitHandler<IPasswordResetForm> = (values) => {
    const { otpCode, ...rest } = values;
    onSubmitSuccess({ otpCode: otpCode?.replace(' ', ''), ...rest });
  };

  const newPasswordInput = (
    <Input
      {...newPasswordContent}
      type="password"
      autoComplete="new-password"
      {...register('newPassword', {
        required: 'Field Is Required',
        minLength: { value: 8, message: 'At least 8 characters long' },
        validate: {
          hasNumber: (value: string) =>
            RegExp(/[0-9]/g).test(value) || 'At least one digit',
          hasSpecialCharacter: (value: string) =>
            RegExp(/[^\w\s]/g).test(value) || 'At least one special character',
          hasUppercase: (value: string) =>
            RegExp(/[A-Z]/g).test(value) || 'At least one upper case character',
          hasLowercase: (value: string) =>
            RegExp(/[a-z]/g).test(value) || 'At least one lower case character',
        },
        onChange: ({ target }) => checkPasswordValidation(target.value),
      })}
      isRequired
      isLabelHidden={isLabelHidden}
    />
  );

  return (
    <Stack
      as="form"
      id="passwordResetForm"
      spacing={4}
      onSubmit={handleSubmit(handleSubmitSuccess, onSubmitError)}
    >
      {hasCurrentPassword && (
        <Input
          {...currentPasswordContent}
          type="password"
          autoComplete="current-password"
          {...register('currentPassword', { required: 'Field is required' })}
          isLabelHidden={isLabelHidden}
          isRequired
        />
      )}
      {hasOTP && (
        <Input
          {...otpCodeContent}
          autoComplete="one-time-code"
          {...register('otpCode', {
            required: 'Field is required',
            onChange: handleOTPInputChange,
          })}
          isLabelHidden={isLabelHidden}
          isRequired
        />
      )}
      {hasValidationPopover ? (
        <FieldValidationListPopover
          title="Password Requirements:"
          rules={[
            {
              isValid: rulesMatch.minLength,
              message: 'At least 8 characters long',
            },
            { isValid: rulesMatch.hasNumber, message: 'At least one digit' },
            {
              isValid: rulesMatch.hasSpecialCharacter,
              message: 'At least one special character',
            },
            {
              isValid: rulesMatch.hasUppercase,
              message: 'At least one upper case character',
            },
            {
              isValid: rulesMatch.hasLowercase,
              message: 'At least one lower case character',
            },
          ]}
        >
          {newPasswordInput}
        </FieldValidationListPopover>
      ) : (
        newPasswordInput
      )}
      <Input
        {...confirmPasswordContent}
        type="password"
        autoComplete="new-password"
        {...register('confirmPassword', {
          validate: {
            matchesPassword: (value: string) => {
              const password = getValues('newPassword');
              return value === password || 'Passwords do not match';
            },
          },
          onChange: () => trigger('confirmPassword'),
        })}
        error={errors['confirmPassword']?.message}
        isRequired
        isLabelHidden={isLabelHidden}
      />
    </Stack>
  );
};
