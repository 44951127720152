import { createContext } from 'react';
import { ColumnInstance } from 'react-table';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type DataTableContextValue<DataType extends {}> = {
  totalLength: [number, (length: number) => void];
  curPageLength: [number, (length: number) => void];
  filteredLength: [number, (length: number) => void];
  columns: [
    ColumnInstance<DataType>[],
    (columns: ColumnInstance<DataType>[]) => void,
  ];
};

const noop = () => {
  return;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
const defaultDataTableContextValue: DataTableContextValue<{}> = {
  totalLength: [0, noop],
  curPageLength: [0, noop],
  filteredLength: [0, noop],
  columns: [[], noop],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DataTableContext = createContext<DataTableContextValue<any>>(
  defaultDataTableContextValue,
);
